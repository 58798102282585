import { appointments_Actions } from "../constants/Appointments";
import { appointments_Status } from "../constants/Appointments";
const getAppointments_initialStoreState = {
  appointments_get_status: appointments_Status.getAllAppointmentsByPractice.NEW,
  appointments: [],
  paginationTotalCount: null,
};

const appointmentsByPractice_Get_Reducer = function (
  state = getAppointments_initialStoreState,
  action
) {
  console.log(action.type);

  switch (action.type) {
    case appointments_Actions.getAllAppointmentsByPractice.GET:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        appointments_get_status:
          appointments_Status.getAllAppointmentsByPractice.LOADING,
        appointments: action.payload,
      };
    case appointments_Actions.getAllAppointmentsByPractice.SUCCESS:
      console.log("I am from Reducer get..", action.payload);
      return {
        ...state,
        appointments_get_status:
          appointments_Status.getAllAppointmentsByPractice.SUCCESS,
        appointments: action.payload.appointmentList,
        paginationTotalCount: action.payload.PaginationData.totalDocs,
      };
    case appointments_Actions.getAllAppointmentsByPractice.FAILURE:
      console.log("I am from Reduce Failure..");
      return {
        ...state,
        appointments_get_status:
          appointments_Status.getAllAppointmentsByPractice.FAILURE,
        error: action.payload.error,
      };
    case appointments_Actions.updateAppointmentStatus.SUCCESS:
      console.log("appointmnetGetStatus......:", action.payload);
      return {
        ...state,
        appointments: action.payload,
      };
    // case appointments_Actions.updateDoctorStatus.SUCCESS:
    //   console.log("appointmnetGetStatusForDoctors......:", action.payload);
    //   return { ...state, appointments: action.payload };
    default:
      console.log("default is firing");

      return { ...state };
  }
};
export default appointmentsByPractice_Get_Reducer;
