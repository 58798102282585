import {
  callRequest_Actions,
  covidRequest_Actions,
} from "../constants/Appointments";
import {
  callRequest_Status,
  covidRequest_Status,
} from "../constants/Appointments";
const callRequest_initialStoreState = {
  callRequest_create_status: callRequest_Status.createCallRequest.NEW,
  callRequest: {},
};

export const callRequest_Create_Reducer = function (
  state = callRequest_initialStoreState,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case callRequest_Actions.createCallRequest.NEW:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        callRequest_create_status: callRequest_Status.createCallRequest.NEW,
      };
    case callRequest_Actions.createCallRequest.POST:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        callRequest_create_status: callRequest_Status.createCallRequest.LOADING,
      };
    case callRequest_Actions.createCallRequest.SUCCESS:
      console.log("I am from Reducer get..", action.payload);
      return {
        ...state,
        callRequest_create_status: callRequest_Status.createCallRequest.SUCCESS,
        callRequest: action.payload.CallRequest,
      };
    case callRequest_Actions.createCallRequest.FAILURE:
      console.log("I am from Reduce Failure..");
      return {
        ...state,
        callRequest_create_status: callRequest_Status.createCallRequest.FAILURE,
        error: action.payload.error,
      };

    default:
      console.log("default is firing");

      return { ...state };
  }
};

const getcallRequest_initialStoreState = {
  callRequest_get_status: callRequest_Status.getCallRequest.NEW,
  callRequest: [],
  TotalCount: null,
};

export const callRequest_GET_Reducer = function (
  state = getcallRequest_initialStoreState,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case callRequest_Actions.getCallRequest.GET:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        callRequest_get_status: callRequest_Status.getCallRequest.LOADING,
      };
    case callRequest_Actions.getCallRequest.SUCCESS:
      console.log("I am from Reducer get..", action.payload);
      return {
        ...state,
        callRequest_get_status: callRequest_Status.getCallRequest.SUCCESS,
        callRequest: action.payload.CallRequestList,
        // TotalCount: action.payload.PaginationData.totalDocs,
      };
    case callRequest_Actions.getCallRequest.UPDATE:
      console.log(
        "I am from Reducer get..action.payload.CallRequestList",
        action.payload.CallRequestList
      );
      return {
        ...state,
        callRequest_get_status: callRequest_Status.getCallRequest.SUCCESS,
        callRequest: action.payload.CallRequestList,
        // TotalCount: action.payload.PaginationData.totalDocs,
      };
    case callRequest_Actions.getCallRequest.FAILURE:
      console.log("I am from Reduce Failure..");
      return {
        ...state,
        callRequest_get_status: callRequest_Status.getCallRequest.FAILURE,
        error: action.payload.error,
      };

    default:
      console.log("default is firing");

      return { ...state };
  }
};

const getcovidRequest_initialStoreState = {
  covidRequest_get_status: covidRequest_Status.getCovidRequest.NEW,
  covidRequest: {},
  Loading: false,
};

export const covidRequest_GET_Reducer = function (
  state = getcovidRequest_initialStoreState,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case covidRequest_Actions.createCovidRequest.GET:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        covidRequest_get_status: covidRequest_Status.getCovidRequest.LOADING,
      };
    case "LOADING":
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        Loading: action.payload,
      };
    case covidRequest_Actions.createCovidRequest.SUCCESS:
      console.log("I am from Reducer get..", action.payload);
      return {
        ...state,
        covidRequest_get_status: covidRequest_Status.getCovidRequest.SUCCESS,
        covidRequest: action.payload,
        Loading: false,
      };
    case covidRequest_Actions.createCovidRequest.UPDATE:
      console.log(
        "I am from Reducer get..action.payload.CallRequestList",
        action.payload
      );
      return {
        ...state,
        covidRequest_get_status: covidRequest_Status.getCovidRequest.SUCCESS,
        covidRequest: action.payload.CallRequestList,
        Loading: false,
      };
    case covidRequest_Status.getCovidRequest.NEW:
      return {
        covidRequest_get_status: covidRequest_Status.getCovidRequest.NEW,
      };
    case covidRequest_Actions.createCovidRequest.FAILURE:
      console.log("I am from Reduce Failure..");
      return {
        ...state,
        covidRequest_get_status: covidRequest_Status.getCovidRequest.FAILURE,
        error: action.payload.error,
        Loading: false,
      };

    default:
      console.log("default is firing");

      return { ...state };
  }
};
