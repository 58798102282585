import React, { Component } from "react";
import "../../css/components/_Navigation_new.scss";
import { Link } from "react-router-dom";
export class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practice: {},
    };
  }
  componentWillReceiveProps(nextProps) {}
  render() {
    console.log(
      "dsathis.props.",
      this.props.practice,
      this.props.PracticeAccount,
      this.props.practiceorPracticeaccount
    );
    return (
      <nav role="navigation" className="navigation-new">
        <div style={{ position: "static" }} className="nav-div1-new">
          <div className="nav-div2-new">
            <button
              className="nav-p1-new"
              onClick={() =>
                window.open(this.props.practice.WebURLLink, "_blank")
              }
              // href={this.props.practice.WebURLLink}
              // target="_blank"
              style={{ textDecoration: "none" }}
            >
              Home
            </button>

            <button
              className="nav-p1-new nav-p1-new2"
              style={
                this.props.screen == 1
                  ? {
                      color: "#00234b",
                      borderBottom: "3px solid #143062",
                      textDecoration: "none",
                      fontWeight: "600",
                    }
                  : { textDecoration: "none" }
              }
              href=""
              onClick={() => this.props.switchScreenHandler(1)}
            >
              Request an Appointment
            </button>
            <button
              className="nav-p1-new nav-last-p-new"
              style={
                this.props.screen == 3
                  ? {
                      color: "#00234b",
                      borderBottom: "3px solid #143062",
                      textDecoration: "none",
                      fontWeight: "600",
                    }
                  : { textDecoration: "none" }
              }
              onClick={() => this.props.switchScreenHandler(3)}
            >
              Request Call
            </button>
          </div>
          <p
            role="presentation"
            aria-label="Phone Number"
            className="nav-p2-new"
          >
            <i className="material-icons nav-icon1-new">phone</i>

            {this.props?.practiceorPracticeaccount == undefined
              ? this.props?.practice?.phoneNumber
              : this.props?.PracticeAccount?.locationPhone}

            {/* {this.props.practice?.locationPhone
              ? this.props.practice?.locationPhone
                ? this.props.practice?.locationPhone
                : this.props.practice?.phoneNumber
              : this.props.practice?.twilioContacts.length !== 0
              ? this.props.practice?.twilioContacts[0]
              : ""} */}
            {/* {this.props.practice.locationPhone
              ? this.props.practice.locationPhone
              : this.props.practice.phoneNumber} */}
          </p>
        </div>
      </nav>
    );
  }
}

export default Navigation;
