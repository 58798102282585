import React, { Component } from "react";
import image from "../images/syra.png";
//import { Rating } from "@material-ui/lab";
import { Rating } from "@mui/lab";
import {
  CircularProgress,
  Button,
  Checkbox,
  FormGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import Loader from "../../PracticeSetup/common/Loader";
import "../../../css/components/_SeenBy_new.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
class Reason extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doesPatientHasPreference: false,
      doctorID: this.props.doctorID,
      doctors: [],
      checkedIndex: this.props.doctorIndex,
      getDoctor: true,
      dostorStatus: "NEW",
      event: {},
    };
    this.handlePatientPreference = this.handlePatientPreference.bind(this);
    this.handleDoctorChange = this.handleDoctorChange.bind(this);
  }
  handlePatientPreference() {
    this.setState(
      {
        doesPatientHasPreference: !this.state.doesPatientHasPreference,
      },
      () => {
        this.props.getAllDoctors(this.props.practice._id);

        // if (!this.state.doesPatientHasPreference)
        this.props.doctorPreferenceCheckHandler(
          this.state.doesPatientHasPreference
        );
        {
          this.props.seleectFirstProvider();
        }
      }
    );
  }
  handleDoctorChange(event) {
    this.props.doctorDetailPopupHandler(event.target.id);
  }

  componentWillMount() { }
  componentWillReceiveProps(nextProps) {
    if (nextProps.doctors_get_status === "LOADING") {
      this.state.dostorStatus = "LOADING";
      this.state.getDoctor = true;
    }
    if (nextProps.doctors_get_status === "FAILURE") {
      this.state.dostorStatus = "FAILURE";
    }
    if (nextProps.doctors_get_status === "SUCCESS" && this.state.getDoctor) {
      this.state.getDoctor = false;
      if (nextProps.doctors.length > 0) {
        this.state.dostorStatus = "SUCCESS";
        if (this.state.doesPatientHasPreference == false) {
          this.props.seleectFirstProvider();
        } else {
          // this.props.selectedDoctor(0);
          this.props.onPatientPreferenceChange(
            !this.state.doesPatientHasPreference
          );
        }
      } else {
        this.state.dostorStatus = "NOT_AVAILABLE";
      }
    }
  }

  data = [
    {
      id: "5c93863c1f38e1122c7d632b",
      src: image,
      name: "Syra Hanif, MD",
      post: "Primary Care",
    },
  ];
  render() {
    console.log("thispropsTeleVisitDrListData", this.props.reasons,this.state.dostorStatus);
    return (
      <section className="seen-new">

        <div className="s-radio-btns-new">
          {/* <FormControl component="fieldset">
            <FormLabel component="legend"> */}
              <h2
                style={{
                  fontFamily: "Lato",
                  color: "#00234b",
                  marginTop: 36,
                  marginBottom: 20,
                }}
              >
                Choose Provider<span style={{ color: "red" }}>*</span>
              </h2>
            {/* </FormLabel> */}
            {/* <FormGroup name="v2" row>
                <FormControlLabel
                  onChange={this.handlePatientPreference}
                  control={<Radio />}
                  name="v2"
                  label="First Available"
                  checked={!this.state.doesPatientHasPreference}
                />
                <FormControlLabel
                  name="v2"
                  // style={{ marginLeft: 24 }}
                  checked={this.state.doesPatientHasPreference}
                  onChange={this.handlePatientPreference}
                  control={<Radio />}
                  label="I have a preference"
                />
              </FormGroup> */}
          {/* </FormControl> */}
          {/* <label className="s-label1-new">
              First Available
              <input
                type="radio"
                name="seenby"
                className="s-inp1-new"
                checked={!this.state.doesPatientHasPreference}
                onChange={this.handlePatientPreference}
              />
              <span className="s-sp1-new">
                <i className="material-icons s-icon1-new">brightness_1</i>
              </span>
            </label> */}

          {/* <label className="s-label2-new" style={{ color: "black" }}>
              I have a preference
              <input
                type="radio"
                name="seenby"
                className="s-inp2-new"
                checked={this.state.doesPatientHasPreference}
                onChange={this.handlePatientPreference}
              />
              <span className="s-sp2-new">
                <i className="material-icons s-icon2-new">brightness_1</i>
              </span>
            </label> */}
        </div>
        {/* ................. Start employee section ............. */}

        <section
          className="s-employee-new"
          style={{
            // display: this.state.doesPatientHasPreference ? "" : "none",
          }}
        >
          {this.props.DrLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ color: "#143062" }} />
            </div>
          ) : this.state.dostorStatus === "FAILURE" ? (
            <p>Failed to get doctors</p>
          ) : this.state.dostorStatus === "NOT_AVAILABLE" ? (
            <p>Doctor not available</p>
          ) : this.props.TeleVisitDrListData.length > 0 ? (
            this.props.TeleVisitDrListData.map((doctor, index) => {
              console.log("TeleVisitDrListData111", doctor);
              console.log(this.props.doctorIndex, index);
              console.log("====================================");
              return (
                <div
                  // type="submit"
                  // onClick={() => this.handleDoctorChange(doctor)}
                  className="s-block-new1"
                  key={index}
                  style={
                    index == this.props.doctorIndex
                      ? {
                        border: "4px solid #0074e8",
                        boxShadow: "4px 4px 8px rgba(0,0,0,0.1)",
                      }
                      : { border: "" }
                  }
                >
                  {/* {index == this.props.doctorIndex ? (
                      <i className="material-icons s-check-icon">check_box</i>
                    ) : null} */}
                  <div className="s-info-new">
                    <div className="s-input-container-new">
                      <label className="sem-label1-new">
                        <div className="s-image-container-new">
                          <img
                            src={doctor.doctorImage == "" ? "https://zebradoctor-patient-public-bucket-development.s3.us-west-2.amazonaws.com/logoo.png" : doctor.doctorImage}
                            className="s-img-new"
                            alt=""
                          />
                        </div>
                        <div
                          aria-label={
                            doctor.doctorName.length >= 20
                              ? doctor.doctorName.substring(0, 20)
                              : doctor.doctorName.substring(0, 20)
                          }
                          className="DoctorName"
                        >
                          {doctor.doctorName.length >= 20
                            ? doctor.doctorName.substring(0, 20)
                            : doctor.doctorName.substring(0, 20)}
                        </div>
                        <div className="sem-p1-newPArent">
                          <p
                            aria-label={
                              doctor.doctorSpecialization.length >= 54
                                ? doctor.doctorSpecialization.substring(0, 54) +
                                "..."
                                : doctor.doctorSpecialization
                            }
                            className="sem-p1-new"
                          >
                            {doctor.doctorSpecialization.length >= 15
                              ? doctor.doctorSpecialization.substring(0, 15) +
                              "..."
                              : doctor.doctorSpecialization}
                          </p>
                        </div>
                        {/* <div className="sem-rate-new">
                            <Rating value={5} readOnly size="small" />
                          </div> */}

                        {/* <p className="sem-p2-new"> {doctor.doctorDescription}</p> */}
                        {/* <input
                            style={{ outline: "none" }}
                            type="radio"
                            name="employee"
                            id={index}
                            className="sem-inp1-new"
                            value={doctor._id}
                            onChange={() => this.props.selectedDoctor(index)}
                            checked={
                              index == this.props.doctorIndex ? true : false
                            }
                          /> */}
                      </label>
                    </div>
                  </div>
                  {index == this.props.doctorIndex ? (
                    <Checkbox
                      onClick={() => this.props.selectedDoctor(null)}
                      // style={{ marginTop: -20 }}
                      inputProps={{
                        "aria-label": `${doctor.doctorName.length >= 20
                          ? doctor.doctorName.substring(0, 20)
                          : doctor.doctorName.substring(0, 20)
                          } Selected`,
                      }}
                      className="sem-p2-new mar"
                      defaultChecked
                    />
                  ) : (
                    <Button
                      aria-label={`Select ${doctor.doctorName.length >= 20
                        ? doctor.doctorName.substring(0, 20)
                        : doctor.doctorName.substring(0, 20)
                        } ${doctor.doctorSpecialization.length >= 54
                          ? doctor.doctorSpecialization.substring(0, 54) + "..."
                          : doctor.doctorSpecialization
                        }`}
                      style={{
                        fontFamily: "Lato",
                        backgroundColor: "#0074e8",
                      }}
                      value={doctor._id}
                      variant="contained"
                      disableElevation
                      id={index}
                      onClick={() => this.props.selectedDoctor(index)}
                      className="sem-p2-new mar"
                    >
                      Select
                    </Button>
                  )}
                  <br />
                  <Button
                    aria-label={`VIEW DETAILS ${doctor.doctorName.length >= 20
                      ? doctor.doctorName.substring(0, 20)
                      : doctor.doctorName.substring(0, 20)
                      } ${doctor.doctorSpecialization.length >= 54
                        ? doctor.doctorSpecialization.substring(0, 54) + "..."
                        : doctor.doctorSpecialization
                      }`}
                    value={doctor._id}
                    variant="outlined"
                    id={index}
                    style={{
                      color: "#0074e8",
                      fontFamily: "Lato",
                    }}
                    onClick={this.handleDoctorChange}
                    className="sem-p2-new"
                  >
                    {" "}
                    VIEW DETAILS
                  </Button>
                </div>
              );
            })
          ) : (

            <p>{ this.props.reasons===undefined||this.props.reasons=="" ?"No reason is selected":"Doctor not available "}</p>


          )}
        </section>


      </section>
    );
  }
}

export default Reason;
