import { appointments_Actions } from "../constants/Appointments";
import { appointments_Status } from "../constants/Appointments";
const getDoctors_initialStoreState = {
  all_doctors_get_status: appointments_Status.getAllDoctorsByPracticeID.NEW,
  allDoctors: [],
};

const allDoctorsByPractice_Get_Reducer = function (
  state = getDoctors_initialStoreState,
  action
) {
  console.log(action.type);

  switch (action.type) {
    case appointments_Actions.getDoctorsByPracticeID.GET:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        all_doctors_get_status:
          appointments_Status.getAllDoctorsByPracticeID.LOADING,
        allDoctors: action.payload,
      };
    case appointments_Actions.getDoctorsByPracticeID.SUCCESS:
      console.log("I am from Reducer get..", action.payload);
      return {
        ...state,
        all_doctors_get_status:
          appointments_Status.getAllDoctorsByPracticeID.SUCCESS,
        allDoctors: action.payload.doctorsList,
      };
    case appointments_Actions.getDoctorsByPracticeID.FAILURE:
      console.log("I am from Reduce Failure..");
      return {
        ...state,
        all_doctors_get_status:
          appointments_Status.getAllDoctorsByPracticeID.FAILURE,
        error: action.payload.error,
      };
    // case appointments_Actions.updateDoctorStatus.SUCCESS:
    //   console.log("appointmnetGetStatus......:", action.payload);
    //   return { ...state, allDoctors: action.payload };
    default:
      console.log("default is firing");

      return { ...state };
  }
};
export default allDoctorsByPractice_Get_Reducer;
