import React, { Component } from "react";
import "../../../css/components/_Reason_new.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, FormLabel, FormControl, FormGroup } from "@mui/material";
class TeleVisit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTeleVisit: false,
      TelevisitCheckbox: ["Office Visit", "Televisit"],
      televisitTab: false,
      Tabvisit: "",
    };
    this.handleTeleVisitChange = this.handleTeleVisitChange.bind(this);
  }

  handleTeleVisitChange(event) {
    this.setState({ isTeleVisit: event });
    this.props.onTeleVisitChange(event);
  }
  ClickedTelevisit = (v) => {
    this.setState(
      {
        Tabvisit: v,
      },
      () => {
        this.props.onTeleVisitChange(this.state.Tabvisit);
      }
    );
  };
  componentDidUpdate(prevprops) {
    if (prevprops.TelevistCheck !== this.props.TelevistCheck)
      if (this.props.TelevistCheck == true) {
        this.setState({
          Tabvisit: "",
        });
      }
  }
  render() {
    return (
      <section className="reason-new">
        <section className="r-sec1-new">
          <FormControl row component="fieldset">
            <FormLabel component="legend">
              <h2
                style={{ fontFamily: "Lato", color: "#00234b", marginTop: 36 }}
                // className="Heading"
              >
                Choose Type of Visit<span style={{color:"red"}}> *</span>
              </h2>
            </FormLabel>
            <div className="s-radio-btns-newM">
              <FormGroup name="0" row>
                {this.state.TelevisitCheckbox.map((v, i) => {
                  return (
                    <>
                      <FormControlLabel
                        value={v}
                        htmlFor={v}
                        // style={{ marginLeft: i == 1 ? 24 : 0 }}
                        onChange={() => this.ClickedTelevisit(v)}
                        control={<Radio id={v} />}
                        label={v}
                        checked={this.state.Tabvisit === v}
                      />

                      {/* // <div
                //   key={i}
                //   onClick={() => this.ClickedTelevisit(v)}
                //   style={{ display: "flex", alignItems: "center" }}
                // > */}
                      {/* 
                  <div
                    style={{
                      border:
                        this.state.Tabvisit === v
                          ? "1px solid #1489FF"
                          : "1px solid #000000",
                      borderRadius: "100%",
                      padding: "2px",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor:
                          this.state.Tabvisit === v ? "#1489FF" : "",
                        borderRadius: "100%",
                      }}
                    ></div>
                  </div>

                  <span
                    style={{
                      marginLeft: 10,
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      fontWeight: "normal",
                      flexWrap: "nowrap",
                      width: 100,
                    }}
                  >
                    {v}
                  </span> */}
                      {/* // </di/> */}
                    </>
                  );
                })}
              </FormGroup>
            </div>
          </FormControl>
        </section>
      </section>
    );
  }
}

export default TeleVisit;
