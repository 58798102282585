export const practiceInsurances_Actions= {
	getAllInsurances:
	{
		GET : "PRACTICE_INSURANCES_GET",
		FAILURE: "PRACTICE_INSURANCES_FAILURE",
		SUCCESS:"PRACTICE_INSURANCES_SUCCESS"
		},
		updatePracticeInsurance:
	{
		POST: 				"PRACTICEINSURANCE_UPDATE_POST",
		SUCCESS: 			"PRACTICEINSURANCE_UPDATE_SUCCESS",
		FAILURE: "PRACTICEINSURANCE_UPDATE_FAILURE",
		NEW:"PRACTICEINSURANCE_UPDATE_NEW"
	}
}


export const practiceInsurances_Status ={
	getAllInsurances:
	{
		NEW: "NEW",
		SUCCESS:"SUCCESS",
		FAILURE:"FAILURE",
		LOADING:"LOADING"
		},
		updatePracticeInsurance:
		{
			NEW:"NEW",
		UPDATED:"UPDATED",
		FAILED:"FAILURE",
		LOADING:"LOADING"
		},
	
}
