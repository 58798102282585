import Axios from "axios";
import moment from "moment";
import queryString from "query-string";
import React, { Component, lazy } from "react";
import Helmet from "react-helmet/es/Helmet";
// import PersonalInformation from "./PersonalInformation";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  appointments_Actions,
  appointments_Status,
} from "../../../constants/Appointments";
import { ROOT_URL } from "../../../constants/config";
import { practice_Status } from "../../../constants/Practices";
// import GoogleTagManager from "../../googleTagManager/GoogleTagManager";
// import GoogleAnalyticsManager from "../../googleAnalyticsManager/GoogleAnalyticsManager";
// import Loader from "../../PracticeSetup/common/Loader";
// import Review from "./Review";
// import TeleVisit from "./TeleVisit";
import ReCAPTCHA from "react-google-recaptcha";

import "../../../css/components/_Appointment_new.scss";
import { AppointmetnsServer } from "../../../servers/AppointmentsServer";
import { PracticeServer } from "../../../servers/PracticeServer";
import DoctorDetails from "../BookAnAppointment/DoctorDetials";
// import Success from "./Success";
import errorImage from "../images/Error.png";
import successImage from "../images/Succes1.png";

// import Attachments from "./Attachments";
import CheckBoxesFotTest from "./CheckBoxesForTest";
import EligibleCategory from "./EligibleCategory";
import Reason from "./Reason";
import SeenBy from "./SeenBy";
import BookAppointment from "./BookAppointment";
// import Insurance from "./Insurance";
import SelectLocation from "./SelectLocation";
import TeleVisit from "./TeleVisit";
import PersonalInformation from "./PersonalInformation";
import Success from "./Success";
import Loader from "../../PracticeSetup/common/Loader";
import { CircularProgress, Button, Checkbox, FormControlLabel } from "@mui/material";

//const SelectLocation = lazy(() => import("./SelectLocation"));
//const EligibleCategory = lazy(() => import("./EligibleCategory"));
//const Reason = lazy(() => import("./Reason"));
//const TeleVisit = lazy(() => import("./TeleVisit"));
//const SeenBy = lazy(() => import("./SeenBy"));
//const BookAppointment = lazy(() => import("./BookAppointment"));
//const PersonalInformation = lazy(() => import("./PersonalInformation"));
//const Insurance = lazy(() => import("./Insurance"));
//const Attachments = lazy(() => import("./Attachments"));
//const Success = lazy(() => import("./Success"));
//const Loader = lazy(() => import("../../PracticeSetup/common/Loader"));
//const CheckBoxesFotTest = lazy(() => import("./CheckBoxesForTest"));
const Insurance = lazy(() => import("./Insurance"));
const Attachments = lazy(() => import("./Attachments"));
const GoogleTagManager = lazy(() =>
  import("../../googleTagManager/GoogleTagManager")
);

const GoogleAnalyticsManager = lazy(() =>
  import("../../googleAnalyticsManager/GoogleAnalyticsManager")
);
const mapStateToProps = (state) => {
  return {
    insurances_get_status: state.insurances_Get_Reducer.insurances_get_status,
    insurances: state.insurances_Get_Reducer.insurances,
    post_appointment_status:
      state.post_appointments_Reducer.post_appointments_status,
    appointment: state.post_appointments_Reducer.appointment,
    doctors_get_status: state.doctorsByPractice_Get_Reducer.doctors_get_status,
    doctors: state.doctorsByPractice_Get_Reducer.doctors,
    practice_get_status: state.practiceByName_Get_Reducer.practice_get_status,
    practice: state.practiceByName_Get_Reducer.practice,
    appointmentTimeSlots: state.doctorAvailabilty_Reducer.time,
    doctor_available_status:
      state.doctorAvailabilty_Reducer.doctor_available_status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    submitAppointment: (appointmentObject, captchvalue) => {
      dispatch(
        AppointmetnsServer.submitAppointment(appointmentObject, captchvalue)
      );
    },
    handleAnotherAppointment: () => {
      dispatch({ type: appointments_Actions.submitAppointment.NEW });
    },
    getAllDoctorsByPracticeID: (practiceID) => {
      dispatch(AppointmetnsServer.getAllDoctorsByPracticeID(practiceID));
    },
    getPracticeByName: (practiceName) => {
      dispatch(PracticeServer.getPracticeByName(practiceName));
    },
    checkAvailabilty: (date, doctorID) => {
      dispatch(AppointmetnsServer.DoctorAvailability(date, doctorID));
    },
  };
};
// 6LfTe0QgAAAAADHkp4KlHNT107pNWO0rJ8EyB_mg
const TEST_SITE_KEY = "6LfTe0QgAAAAADHkp4KlHNT107pNWO0rJ8EyB_mg";

export class Appointment extends Component {
  constructor(props) {
    super(props);
    // console.log("geves");s
    this.state = {
      isNewPatient: false,
      doesPatientHasPreference: false,
      doctorPreferenceCheck: false,
      doctorID: null,
      doctorIndex: null,
      billingAddress: "",
      appointmentDate: null,
      selectedSlots: [],
      appointmentTime: "Appointment Time *",
      firstName: this.props.match?.params?.Fname
        ? this.props.match?.params?.Fname
        : "",
      // lastName: this.props.match?.params?.LName
      //   ? this.props.match?.params?.LName == "Type"
      //     ? ""
      //     : this.props.match?.params?.LName
      //   : this.props.match?.params?.LName == "Type"
      //   ? ""
      //   : this.props.match?.params?.LName,
      lastName: this.props.match?.params?.LName
        ? this.props.match?.params?.LName
        : "",
      duplicateAddressFlag: false,
      patientDOB: this.props.match?.params?.DOB
        ? this.props.match?.params?.DOB
        : null,
      patientEmail: this.props.match?.params?.Email
        ? this.props.match?.params?.Email
        : "",
      patientContact: "",
      doesPatientHasInsurance: false,
      patientInsuranceName: "",
      patientIsurnaceMemberID: "",
      TelevistCheck: false,
      HealthInsuranceID: null,
      insurances: [],
      screenStatus: "NEW",
      validEmailFlag: true,
      validNumFlag: false,
      appointmentTimeSlots: [],
      selfpaycheck: true,
      doctors: [],
      doctorDetailPopup: false,
      teleVisit: false,
      ReviewsData: [],
      ReviewLoader: true,
      teleVisit2: "",
      TeleVisitDrListData: [],
      DoctorlistEmpty: null,
      InsuranceBypracticeid: [],
      InsuranceShowImg: [],
      InsuranceFile: [],
      DrivingLicShowImg: [],
      CovidPCrRapidte30minChange: false,
      SameDayRT_PCR: false,
      DrivingLicFile: [],
      Success: false,
      Pcr: false,
      CovidRapidTest: false,
      other: true,
      AntiBody: false,
      CovidVaccinne: false,
      reasonForVisit: "", //********************** */
      reasonForVisits: "",
      ReasonsList: [],
      PhoneNumFlag: "United States",
      isCovidRapidTest: null,
      isAntiBodyTest: null,
      isCovidPCRTest: null,
      DrLoading: false,
      allDoctors: [],
      Patientage: 19,
      Gender: "",
      GenderInformation: "",
      EligibleCategory: "",
      practiceLocationID: this.props.PracticeAccount?._id
        ? this.props.PracticeAccount?._id
        : "",
      locationAddress: this.props.PracticeAccount?.locationAddress
        ? this.props.PracticeAccount?.locationAddress
        : "",
      DateError: "",
      captch: true,
      captchValue: "",
      appointmentType: {},
      doctorData: [],
      receiveMessageFlag: true,
      receiveEmailFlag: true,
    };
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handleIsNewPatientChange = this.handleIsNewPatientChange.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleOnReasonForVisitChange =
      this.handleOnReasonForVisitChange.bind(this);
    this.handleLocationAddressChange =
      this.handleLocationAddressChange.bind(this);
    this.handlePatientPreferenceChange =
      this.handlePatientPreferenceChange.bind(this);
    this.handleDoctorChange = this.handleDoctorChange.bind(this);
    this.handleAppointmentDateChange =
      this.handleAppointmentDateChange.bind(this);
    this.handleAppointmentTimeChange =
      this.handleAppointmentTimeChange.bind(this);
    this.handlePatientContactChange =
      this.handlePatientContactChange.bind(this);
    this.handlePatientDOBChange = this.handlePatientDOBChange.bind(this);
    this.handlePatientEmailChange = this.handlePatientEmailChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePatientHasInsuranceChange =
      this.handlePatientHasInsuranceChange.bind(this);
    this.handlePatientInusranceIDChange =
      this.handlePatientInusranceIDChange.bind(this);
    this.handlePatientInsuranceMemberIDChange =
      this.handlePatientInsuranceMemberIDChange.bind(this);
    this.handleAppointmentSubmission =
      this.handleAppointmentSubmission.bind(this);
    this.handleAnotherAppointment = this.handleAnotherAppointment.bind(this);
    this.handleGetAllDoctorsByPracticeID =
      this.handleGetAllDoctorsByPracticeID.bind(this);
    this.onTeleVisitChange = this.onTeleVisitChange.bind(this);
  }
  handleIsNewPatientChange(isNewPatient) {
    this.setState({ isNewPatient: isNewPatient }, () => {
      if (this.state.isNewPatient || this.state.Patientage <= 18) {
        this.setState({
          DrivingLicFile: [{}, {}],
          DrivingLicShowImg: [],
          InsuranceShowImg: [],
          InsuranceFile: [],
        });
      } else {
        this.setState({
          DrivingLicFile: [],
        });
      }
    });
  }
  handleOnReasonForVisitChange(ReasonForVisit) {
    console.log("ReasonForVisitData", ReasonForVisit,);
    this.setState(
      { reasonForVisit: ReasonForVisit == "" ? "" : ReasonForVisit, reasonForVisits: "" },
      () => {
        this.setState({
          appointmentDate: null,
          appointmentTime: "Appointment Time *",
          TelevistCheck: true,
          TeleVisitDrListData: [],
          doctorID: null,
        });

      }
    );
  }
  handlePatientPreferenceChange(doesPatientHasPreference) {
    console.log("pandeseri", doesPatientHasPreference);

    this.setState({ doesPatientHasPreference: doesPatientHasPreference });
  }
  handleDoctorChange(event) {
    this.setState({
      doctorID: event.target.value,
      appointmentDate: null,
      selectedSlots: [],
      appointmentTime: "Appointment Time *",
      doctorIndex: event.target.id,
      appointmentTimeSlots: [],
    });
  }
  handleScriptLoad() {
    // Declare Options For Autocomplete
    var options = {
      // types: ['(cities)'],
    }; // To disable any eslint 'google not defined' errors

    // Initialize Google Autocomplete
    /*global google*/ this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      options
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  }

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;

    if (address) {
      // Set State
      this.setState({
        billingAddress: addressObject.formatted_address,
      });
    }
  }
  handleLocationAddressChange(event) {
    if (event.target.value) {
      this.setState(
        {
          duplicateAddressFlag: false,
          billingAddress: event.target.value,
        },
        () => { }
      );
    } else {
      this.setState({
        duplicateAddressFlag: false,
        billingAddress: "",
      });
    }
  }
  doctorDetailPopupHandler = (index) => {
    if (index)
      this.setState({
        doctorDetailPopup: !this.state.doctorDetailPopup,
        selectedDoctorIndex: index,
      });
    else
      this.setState({
        doctorDetailPopup: !this.state.doctorDetailPopup,
      });
  };
  handleAppointmentDateChange(date) {
    this.setState(
      { appointmentDate: date, appointmentTime: "Appointment Time *" },
      () => {
        if (this.state.doctorID) {
          // this.props.checkAvailabilty(
          //   this.state.appointmentDate,
          //   this.state.doctorID
          // );
        }
      }
    );
  }
  handleAppointmentTimeChange(time) {
    console.log("onAppointmentTimeChange1", time);
    this.setState({ appointmentTime: time });
  }
  handlePatientContactChange(contact, isNumberValid, e, Flag) {
    this.setState({
      patientContact: contact,
      validNumFlag: isNumberValid,
      PhoneNumFlag: Flag,
    });
  }
  handleFirstNameChange(fName) {
    this.setState({ firstName: fName });
  }
  handleLastNameChange(lName) {
    this.setState({ lastName: lName });
  }
  handlePatientDOBChange(dob) {
    this.setState({ patientDOB: dob });
  }
  handlePatientDOBErrorChange = (dob) => {
    console.log("__ODB", dob);
    this.setState({ DateError: dob == "null" ? "" : dob });
  };
  handlePatientEmailChange(email, isEmailValid) {
    this.setState({ patientEmail: email, validEmailFlag: isEmailValid });
  }
  handlePatientInusranceIDChange(insuranceID) {
    this.setState({
      HealthInsuranceID: insuranceID === "" ? null : insuranceID,
    });
  }
  handlePatientInsuranceMemberIDChange(memberID) {
    this.setState({ patientIsurnaceMemberID: memberID });
  }
  handlePatientHasInsuranceChange(hasInsurance) {
    this.setState({ doesPatientHasInsurance: hasInsurance });
  }
  handleAppointmentSubmission(e) {
    console.log(" this.state.doctorData.reasonid", this.state.doctorData);
    const appointmentTime = {
      start: JSON.parse(this.state.appointmentTime).start,
      end: JSON.parse(this.state.appointmentTime).end
    }
    // console.log("gettingFormDatahere11", JSON.parse(this.state.appointmentTime), reasonVisitobject);
    e.preventDefault();
    if (this.state.captch) {
      alert("Please verify captcha");
    } else {
      let newDate = this.state.appointmentDate.toString();
      let vcheck = newDate.substring(0, 17);
      let date = moment(this.state.appointmentDate).format("YYYY-MM-DD");

      let Form = new FormData();
      Form.append("patientFirstName", this.state.firstName);
      Form.append(
        "patientLastName",
        this.state.lastName == undefined || this.state.lastName == "undefined"
          ? "--"
          : this.state.lastName
      );
      Form.append("patientDob", moment(this.state.patientDOB).format("MM/DD/YYYY"));
      Form.append("patientEmail", this.state.patientEmail.toLowerCase());
      Form.append("patientContact", this.state.patientContact);
      Form.append("isNewPatient", this.state.isNewPatient);
      Form.append("Gender", this.state.Gender);
      Form.append("GenderInformation", this.state.GenderInformation);
      Form.append(
        "reasonForVisit",
        Object.keys(this.state.reasonForVisits).length !== 0
          ? this.state.reasonForVisits.reason
          : this.state.reasonForVisit.reason
      );
      Form.append("appointmentTypeId", this.state.doctorData.EHRProviderId == '' ? JSON.parse(this.state.appointmentTime).appointmentTypeID : null)
      // Form.append("appointmentTypeId", this.state.reasonForVisit.reasonid == null ? JSON.parse(this.state.appointmentTime).appointmentTypeID : null)
      Form.append("athenaAppointmentTypeId", this.state.doctorData.EHRProviderId == '' ? null : JSON.parse(this.state.appointmentTime).appointmentTypeID)
      Form.append("appointmentTimeSlot", JSON.stringify(appointmentTime))
      Form.append("timezone", JSON.parse(this.state.appointmentTime).timezone ? JSON.parse(this.state.appointmentTime).timezone : this.state.doctorData.EHRDepartmentTimeZoneName)
      // Form.append(
      //   "reasonForVisit",
      //   this.state.reasonForVisits !== ""
      //     ? this.state.reasonForVisits
      //     : this.state.reasonForVisit
      // );
      Form.append("seenByDoctor", this.state.doctorID);
      Form.append("BillingAddress", this.state.billingAddress);
      // Form.append("appointmentDate", JSON.parse(JSON.stringify(appointmentTime.start)));
      Form.append("appointmentDate", `${date}T00:00:00.000Z`);
      Form.append("appointmentTime", JSON.parse(JSON.stringify(appointmentTime.start)));
      Form.append("isHealthInsuranceUsed", this.state.doesPatientHasInsurance);
      Form.append("departmentid", this.state.doctorData.EHRDepartmentId);
      Form.append("reasonid", this.state.doctorData.reasonforvisit);
      // Form.append("reasonid", this.state.doctorData.EHRProviderId == ''?null:Object.keys(this.state.reasonForVisits).length !== 0
      //   ? this.state.reasonForVisits.reasonid
      //   : this.state.reasonForVisit.reasonid);
      Form.append("EHRSynced", this.state.doctorData.EHRProviderId == '' ? false : true);
      // Form.append("EHRSynced", this.state.reasonForVisit.reasonid == null ? false : true);
      Form.append("practiceid", this.props.PracticeAccount.practiceID?.PracticeEHRID);
      Form.append("appointmentid", JSON.parse(this.state.appointmentTime).AppointmentId);
      Form.append("enablePatientSMS", this.state.receiveMessageFlag);
      Form.append("enablePatientEmail", this.state.receiveEmailFlag)

      Form.append(
        "HealthInsuranceMemberID",
        this.state.doesPatientHasInsurance
          ? this.state.patientIsurnaceMemberID
          : null
      );
      Form.append(
        "HealthInsuranceID",
        this.state.doesPatientHasInsurance == "undefined"
          ? null
          : this.state.doesPatientHasInsurance
            ? this.state.HealthInsuranceID
            : null
      );
      Form.append("practiceID", this.props.practice._id);
      Form.append("practiceLocationID", this.state.practiceLocationID);
      Form.append("teleVisit", this.state.teleVisit);
      Form.append("CategoryEligibility", this.state.EligibleCategory);
      Form.append(
        "utmSource",
        queryString.parse(this.props.location.search).utm_source
      );
      Form.append(
        "utmMedium",
        queryString.parse(this.props.location.search).utm_medium
      );
      Form.append("captcha", this.state.captchValue);

      for (let i = 0; i < this.state.InsuranceFile.length; i++) {
        Form.append("insuranceAttachments", this.state.InsuranceFile[i]);
      }
      for (let i = 0; i < this.state.DrivingLicFile.length; i++) {
        Form.append("drivingLicenseAttachments", this.state.DrivingLicFile[i]);
      }
      var appointment = {
        patientFirstName: this.state.firstName,
        patientLastName: this.state.lastName,
        patientDob: this.state.patientDOB,
        patientEmail: this.state.patientEmail.toLowerCase(),
        patientContact: this.state.patientContact,
        isNewPatient: this.state.isNewPatient,
        reasonForVisit: this.state.appointmentType,
        // reasonForVisit:
        //   this.state.reasonForVisits !== ""
        //     ? this.state.reasonForVisits
        //     : this.state.reasonForVisit,

        // === "Reason"
        //   ? null
        //   : this.state.reasonForVisit,
        seenByDoctor: this.state.doctorID,
        // seenByDoctor: this.state.doctorID==""? this.props.doctors[0]._id:this.state.doctorID,
        BillingAddress: this.state.billingAddress,
        // appointmentDate: this.state.appointmentDate,
        appointmentDate: `${date}T00:00:00.000Z`,
        // appointmentDate: moment.utc(newDate, "YYYY-MM-DD")._i,
        appointmentTime: this.state.appointmentTime,
        isHealthInsuranceUsed: this.state.doesPatientHasInsurance,
        HealthInsuranceID: this.state.doesPatientHasInsurance
          ? this.state.HealthInsuranceID
          : null,
        HealthInsuranceMemberID: this.state.doesPatientHasInsurance
          ? this.state.patientIsurnaceMemberID
          : null,
        practiceID: this.props.practice._id,
        teleVisit: this.state.teleVisit,
      };

      var selectedOption = appointment.reasonForVisit;
      // console.log("selectedOption",selectedOption);
      if (
        this.props.practice.gtmCode !== null ||
        this.props.practice.gtmCode !== undefined
      ) {
        window.dataLayer.push({
          event: "selectionMade",
          selectedElement: selectedOption,
        });
      }
      this.clearState();
      console.log(Form, "gettingFormDatahere");
      this.props.submitAppointment(Form, this.state.captchValue);
    }
  }
  handelpracticeLocationID = (e, v) => {
    this.setState(
      {
        practiceLocationID: e,
        locationAddress: v,
      },
      () => {
        this.setState({
          doctors: [],
          TeleVisitDrListData: [],
          appointmentDate: null,
          TelevistCheck: true,
          appointmentTime: "Appointment Time *",
        });
      }
    );
  };
  clearState() {
    this.setState({
      // practiceLocationID: "",
      locationAddress: "",
      CovidVaccinne: false,
      isNewPatient: false,
      reasonForVisit: "Reason", //************** */
      doesPatientHasPreference: false,
      doctorID: null,
      billingAddress: "",
      appointmentDate: null,
      Patientage: 19,
      PhoneNumFlag: "United States",
      doctorIndex: null,
      appointmentTime: "Appointment Time *",
      receiveMessageFlag: true,
      receiveEmailFlag: true,
      firstName: this.props.match?.params?.Fname
        ? this.props.match?.params?.Fname
        : "",
      lastName: this.props.match?.params?.LName
        ? this.props.match?.params?.LName == "Type"
          ? ""
          : this.props.match?.params?.LName
        : this.props.match?.params?.LName == "Type"
          ? ""
          : this.props.match?.params?.LName,
      selectedDoctorIndex: null,
      patientDOB: this.props.match?.params?.DOB
        ? this.props.match?.params?.DOB
        : null,
      patientEmail: this.props.match?.params?.Email
        ? this.props.match?.params?.Email
        : "",
      patientContact: "",
      doesPatientHasInsurance: false,
      patientInsuranceName: "",
      patientIsurnaceMemberID: "",
      HealthInsuranceID: null,
      EligibleCategory: "",
      insurances: [],
      teleVisit: false,
      teleVisit2: "",
      doctorPreferenceCheck: false,
      InsuranceShowImg: [],
      InsuranceFile: [],
      DrivingLicShowImg: [],
      DrivingLicFile: [],
      Pcr: false,
      CovidRapidTest: false,
      other: true,
      AntiBody: false,
      CovidPCrRapidte30minChange: false,
      SameDayRT_PCR: false,
      reasonForVisit: "", //********************** */
      reasonForVisits: "",
      Gender: "",
      GenderInformation: "",
      TeleVisitDrListData: [],
      captchValue: "",
      captch: true,
      PopularReason_app: {}

    });
  }
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  handleAnotherAppointment() {
    this.props.handleAnotherAppointment();
    this.clearState()
  }
  handleGetAllDoctorsByPracticeID(practiceID) {
    this.props.getAllDoctorsByPracticeID(practiceID);
  }
  componentWillReceiveProps(nextProps) {
    console.log("nextProps.....", nextProps, practice_Status.getPracticeByName);
    if (
      nextProps.doctor_available_status ===
      "DOCTOR_AVAILABILTY_DOCTOR_AVAILABLE"
    )


      this.state.appointmentTimeSlots = nextProps.appointmentTimeSlots;

    var flag = true;

    if (
      nextProps.practice_get_status ===
      practice_Status.getPracticeByName.SUCCESS
    ) {
      this.state.screenStatus = "NEW";
    } else if (
      nextProps.practice_get_status ===
      practice_Status.getPracticeByName.FAILURE
    ) {
      flag = false;
      console.log("sssssssssaaaaaaa");
      this.state.screenStatus = "PRACTICE_NOT_FOUND";
    }

    if (
      nextProps.post_appointment_status ===
      appointments_Status.submitAppointment.SUCCESS
    ) {
      this.state.screenStatus = "APPOINTMENT_SCUCCESSFUL";
    } else if (
      nextProps.post_appointment_status ===
      appointments_Status.submitAppointment.FAILURE
    ) {
      this.state.screenStatus = "APPOINTMENT_FAILURE";
    }
    else if (
      nextProps.post_appointment_status ===
      appointments_Status.submitAppointment.LOADING
    ) {
      this.state.screenStatus = "APPOINTMENT_LOADING";
    } else if (flag) {
      this.state.screenStatus = "NEW";
    }
    else if (
      nextProps.post_appointment_status ==
      "FAILURE"
    ) {
      this.state.screenStatus = "APPOINTMENT_FAILURE";
    }

    if (nextProps.doctors.length > 0) {
      this.state.doctors = nextProps.doctors.filter((doctor) => {
        if (doctor.doctorStatus) {
          return doctor;
        }
      });
    }
  }
  doctorPreferenceCheckHandler = (doctorPreferenceCheck) => {
    this.setState({ doctorPreferenceCheck });
  };
  getAllinsuranceByPracitceId = (a) => {
    // alert(this.state.PracticeAccount.practiceID._id)
    Axios.post(
      `${ROOT_URL}/api/admin/insurances/getInsurancesForBookingAppointmentByPracticeID`,
      {
        locationID: this.props.PracticeAccount?._id,
        practiceID: this.state.PracticeAccount.practiceID._id
      }
    ).then((res) => {
      console.log("LOCATIONID", res);
      this.setState({
        InsuranceBypracticeid: res.data.insuranceList,
      });
    });
  };
  componentWillMount() {
    this.getAllinsuranceByPracitceId(this.props.practice._id);
    this.props.handleAnotherAppointment();
    console.log("practiceLocationID111", this.props.practiceLocationID);
  }

  componentDidMount() {
    const { where = false } = this.props;

    // const request = fetch(
    //   `${ROOT_URL}/api/public/client/getReviewsListByPracticeName/${
    //     where
    //       ? this.props.newPracticeName
    //       : this.props.match.params.practiceName
    //   }`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //     },
    //     mode: "cors",
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((Res) => {
    //     this.setState({
    //       ReviewsData: Res.ReviewsList,
    //       ReviewLoader: false,
    //     });
    //   });
  }
  checkAvailabilty = (time, date) => { };

  selectedDoctor = (index) => {
    console.log("____index", index, this.state.TeleVisitDrListData);
    if (index == null) {
      this.setState({
        appointmentDate: null,
        appointmentTime: "Appointment Time *",
        doctorID: null,
        doctorIndex: null,
        doctorData: {},
        appointmentTimeSlots: [],
      });
    } else {
      if (this.state.TeleVisitDrListData.length > 0) {
        this.setState({
          appointmentDate: null,
          appointmentTime: "Appointment Time *",
          doctorID: this.state.TeleVisitDrListData[index]._id,
          doctorData: this.state.TeleVisitDrListData[index],
          doctorIndex: index,
          appointmentTimeSlots: [],
        });
      }
    }
  };
  seleectFirstProvider = () => {
    if (this.state.doctors.length > 0) {
      this.setState({
        appointmentDate: null,
        appointmentTime: "Appointment Time *",
        doctorID: null,
        doctorIndex: null,
        appointmentTimeSlots: [],
      });
    } else {
      this.setState({
        appointmentDate: null,
        appointmentTime: "Appointment Time *",
        appointmentTimeSlots: [],
        doctorIndex: null,
      });
    }
  };

  onTeleVisitChange = (isTeleVisit) => {
    // if (this.state.reasonForVisits !== "") {
    //   console.log(" this.state.reasonForVisits", this.state.reasonForVisits.split(','));
    // }
    this.setState({
      DrLoading: true,
      TelevistCheck: false,
      doctorIndex: null,
      doctorID: null,
      appointmentDate: null,
      appointmentTime: "Appointment Time *",
    });
    if (isTeleVisit == "Televisit") {
      this.setState({ teleVisit: true }, () => {
        Axios.post(
          `${ROOT_URL}/api/AppointmentForm/GetdoctorsForGivenReasons`,
          {
            practiceLocationID: this.state.practiceLocationID,
            televisit: true,
            reasonforvisit: Object.keys(this.state.reasonForVisits).length !== 0
              ? [this.state.reasonForVisits.reason]
              : [this.state.reasonForVisit.reason],
            Athena: Object.keys(this.state.reasonForVisits).length !== 0 ? this.state.reasonForVisits.reasonid !== null ? true : false :
              this.state.reasonForVisit.reasonid !== null ? true : false
            // reasonforvisit: this.state.reasonForVisits !== ""
            //   ? this.state.reasonForVisits.split(',')
            //   // ? this.state.reasonForVisits
            //   : [this.state.reasonForVisit.reason],
            // // ReasonId: "",
            // Athena: this.state.reasonForVisits !== "" ? false : this.state.reasonForVisit.reasonid !== null ? true : false

          },
          // {
          //   headers: {
          //     // "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          //   },
          // }
        ).then((res) => {
          // console.log("res.data.MS_GetDoctorsStatus",res.data.MS_GetDoctorsStatus);
          if (res.data.MS_GetDoctorsStatus == "Success") {
            this.setState({
              DrLoading: false,
              TeleVisitDrListData: res.data.Doctors,
              doctorID: res.data.Doctors.length !== 0 ? res.data.Doctors[0]._id : null,
              doctorData: res.data.Doctors.length !== 0 ? res.data.Doctors[0] : [],
              doctorIndex: res.data.Doctors.length !== 0 ? 0 : null,
            });
          }
          if (res.data.MS_GetDoctorsStatus == "No Doctors Available.") {
            this.setState({
              DrLoading: false,
              TeleVisitDrListData: []
            })
          }
          // this.setState({
          //   DrLoading: false,
          //   TeleVisitDrListData:[]

          // })
        });

      });
    } else if (isTeleVisit == "Office Visit") {
      this.setState({ teleVisit: false }, () => {
        Axios.post(
          `${ROOT_URL}/api/AppointmentForm/GetdoctorsForGivenReasons`,
          {
            practiceLocationID: this.state.practiceLocationID,
            televisit: false,
            reasonforvisit: Object.keys(this.state.reasonForVisits).length !== 0
              ? [this.state.reasonForVisits.reason]
              : [this.state.reasonForVisit.reason],
            Athena: Object.keys(this.state.reasonForVisits).length !== 0 ? this.state.reasonForVisits.reasonid !== null ? true : false :
              this.state.reasonForVisit.reasonid !== null ? true : false

          },
          // {
          //   headers: {
          //     "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          //   },
          // }
        ).then((res) => {
          if (res.data.MS_GetDoctorsStatus == "Success") {
            this.setState({
              DrLoading: false,
              TeleVisitDrListData: res.data.Doctors,
              doctorID: res.data.Doctors.length !== 0 ? res.data.Doctors[0]._id : null,
              doctorData: res.data.Doctors.length !== 0 ? res.data.Doctors[0] : [],
              doctorIndex: res.data.Doctors.length !== 0 ? 0 : null,
            });
          }
          if (res.data.MS_GetDoctorsStatus == "No Doctors Available.") {
            // if(res.data.MS_GetDoctorsStatus=="No Doctors Available for the Selected Reason"){
            this.setState({
              DrLoading: false,
              TeleVisitDrListData: []
            })
          }
        });
      });
    }
    this.setState({ teleVisit2: isTeleVisit });
  };
  handelInsuranceimg = (Img, File) => {
    this.setState({
      InsuranceShowImg: Img,
      InsuranceFile: File,
    });
  };
  handelDrivingLic = (Img, File) => {
    console.log("dsfdsfdsfdsFile", File);
    this.setState(
      {
        DrivingLicShowImg: Img,
        DrivingLicFile: File,
      },
      () => {
        if (this.state.Patientage <= 18 || this.state.isNewPatient) {
          if (File[0] && File[1]) {
            this.setState({
              DrivingLicFile: File,
            });
          } else {
            if (File[0] || File[1]) {
              this.setState({
                DrivingLicFile: File,
              });
            } else {
              this.setState({
                DrivingLicFile: [{}, {}],
              });
            }
          }
        }
      }
    );
  };

  handelissueChanges = (v) => { };
  hadnelSuccess = (v) => {
    this.setState({
      Success: v,
    });
  };
  resetCallRequest = () => {
    this.props.handleAnotherAppointment();
    this.props.switchScreenHandler(1);
    this.hadnelSuccess(false);
  };

  handlePopularReasoning = (e, item) => {
    console.log("handlePopularReasoning", e, item);
    this.setState(
      {
        PopularReason_app: !this.state.PopularReason_app,
      },
      () => {
        this.setState({
          reasonForVisits: item,
          appointmentDate: null,
          appointmentTime: "Appointment Time *",
          doctorID: null,
          InsuranceShowImg: [],
          InsuranceFile: [],
          DrivingLicShowImg: [],
          DrivingLicFile:
            this.state.Patientage <= 18 || this.state.isNewPatient
              ? [{}, {}]
              : [],
          TelevistCheck: true,
          TeleVisitDrListData: [],
        })
      }
    )

  }

  checlkAge = (age) => {
    console.log(age);
    this.setState(
      {
        Patientage: age,
      },
      () => {
        if (this.state.Patientage <= 18 || this.state.isNewPatient) {
          this.setState({
            DrivingLicFile: [{}, {}],
          });
        } else {
          this.setState({
            DrivingLicFile: [],
            DrivingLicShowImg: [],
          });
        }
      }
    );
  };
  EligibleCategory = (t) => {
    console.log(t.target.value);
    this.setState({
      EligibleCategory: t.target.value,
    });
  };
  handelChangeGender = (v) => {
    this.setState({
      Gender: v,
    });
  };
  handelGetGender = (v) => {
    this.setState({
      Gender: v,
    });
  };
  handelGetGenderInformation = (v) => {
    this.setState({
      GenderInformation: v,
    });
  };
  handelcaptch = (v) => {
    console.log("ssssscaptchaaaaaa", v);
    if (v === null) {
      this.setState({
        captchValue: v,
        captch: true,
      });
    } else {
      this.setState({
        captchValue: v,
        captch: false,
      });
    }
  };

  handleReceiveMessagesFlag = (e) => {
    this.setState({
      receiveMessageFlag: e.target.checked
    })
  }

  handleEmailFlag = (e) => {
    this.setState({
      receiveEmailFlag: e.target.checked
    })
  }

  validateCheckboxes = () => {
    if (!this.state.receiveEmailFlag && !this.state.receiveMessageFlag) {
      return true
    } else {
      return false
    }
  }
  getScreen(status) {
    switch (status) {
      case "NEW":
        return (
          <form action="" className="app-form-new">
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            ></div>
            <h1
              style={{ fontFamily: "Lato", color: "#00234b" }}
              className="app-h1-new"
            >
              Request an Appointment
            </h1>
            {this.props.PracticeAccount?._id ? null : (
              <SelectLocation
                locationAddress={this.state.locationAddress}
                handelpracticeLocationID={this.handelpracticeLocationID}
                practice={this.props.practice}
              />
            )}
            <h2
              style={{
                padding: 0,
                marginTop: 40,
                fontFamily: "Lato",
                color: "#00234b",
              }}
              className="app-h1-new"
            >
              Select Reason for Visit
            </h2>
            {/* 5d1610b2302d3e68b24c878e */}
            {/* {this.props.practice._id == "5d1610b2302d3e68b24c878e" ? null : ( */}
            <CheckBoxesFotTest
              PopularReasons={this.props.PopularReasons}
              handlePopularReasoning={this.handlePopularReasoning}
              PopularReason_app={this.state.PopularReason_app}
              handelCovidPCrRapidte30minChange={
                this.handelCovidPCrRapidte30minChange
              }
              CovidPCrRapidte30minChange={
                this.state.CovidPCrRapidte30minChange
              }
              practiceLocationID={this.state.practiceLocationID}
              practice={this.props.practice}
              SameDayRT_PCR={this.state.SameDayRT_PCR}
              handelSameDayRT_PCR={this.handelSameDayRT_PCR}
              handelCovidVaccinnevalue={this.handelCovidVaccinnevalue}
              CovidVaccinne={this.state.CovidVaccinne}
              TestsLoading={this.props.TestsLoading}
              isCovidRapidTest={this.props.isCovidRapidTest}
              isAntiBodyTest={this.props.isAntiBodyTest}
              isCovidPCRTest={this.props.isCovidPCRTest}
              Pcr={this.state.Pcr}
              AntiBody={this.state.AntiBody}
              CovidRapidTest={this.state.CovidRapidTest}
              other={this.state.other}
              reasonForVisit={this.state.reasonForVisit}
              handelPCRTestValue={this.handelPCRTestValue}
              handelCovidRapidTestValue={this.handelCovidRapidTestValue}
              handelOtherValue={this.handelOtherValue}
              handelAntiBodyValue={this.handelAntiBodyValue}
            />
            {/* )}  */}
            <p className="app-p1-new">
              {!this.props.where && (
                <Helmet>
                  <meta charSet="utf-8" />
                  <script
                    defer
                    id="ze-snippet"
                    src={this.props.practice.chatbotURLLink}
                  ></script>
                </Helmet>
              )}
            </p>

            {Object.keys(this.state.reasonForVisits).length == 0 ? (
              <>
                <Reason
                  SameDayRT_PCR={this.state.SameDayRT_PCR}
                  CovidVaccinne={this.state.CovidVaccinne}
                  ReasonsList={this.props.ReasonsList}
                  Reasonforvisti={this.state.reasonForVisit}
                  reasonForVisits={this.state.reasonForVisits}
                  Pcr={this.state.Pcr}
                  CovidPCrRapidte30minChange={
                    this.state.CovidPCrRapidte30minChange
                  }
                  AntiBody={this.state.AntiBody}
                  CovidRapidTest={this.state.CovidRapidTest}
                  onNewPatientChange={this.handleIsNewPatientChange}
                  onReasonForVisitChange={this.handleOnReasonForVisitChange}
                />
              </>
            ) :
              <Reason
                SameDayRT_PCR={this.state.SameDayRT_PCR}
                CovidPCrRapidte30minChange={
                  this.state.CovidPCrRapidte30minChange
                }
                CovidVaccinne={this.state.CovidVaccinne}
                ReasonsList={this.props.ReasonsList}
                Reasonforvisti={this.state.reasonForVisit}
                reasonForVisits={this.state.reasonForVisits}
                Pcr={this.state.Pcr}
                AntiBody={this.state.AntiBody}
                CovidRapidTest={this.state.CovidRapidTest}
                onNewPatientChange={this.handleIsxNewPatientChange}
                onReasonForVisitChange={this.handleOnReasonForVisitChange}
              />
            }
            {this.state.CovidVaccinne ? (
              <EligibleCategory
                EligibleCategory={this.EligibleCategory}
                EligibleCategoryvalue={this.state.EligibleCategory}
              />
            ) : (
              ""
            )}
            {!this.state.CovidVaccinne ? (
              <TeleVisit
                TelevistCheck={this.state.TelevistCheck}
                onTeleVisitChange={this.onTeleVisitChange}
              />
            ) : (
              ""
            )}

            {!this.state.CovidVaccinne ? (
              <SeenBy
                PracticeAccount={this.props.PracticeAccount}
                DrLoading={this.state.DrLoading}
                TeleVisitDrListData={this.state.TeleVisitDrListData}
                doctorPreferenceCheck={this.state.doctorPreferenceCheck}
                onPatientPreferenceChange={
                  this.handlePatientPreferenceChange
                }
                doctorPreferenceCheckHandler={
                  this.doctorPreferenceCheckHandler
                }
                getAllDoctors={this.handleGetAllDoctorsByPracticeID}
                doctors={this.state.doctors}
                practice={this.props.practice}
                doctors_get_status={this.props.doctors_get_status}
                selectedDoctor={this.selectedDoctor}
                doctorID={this.state.doctorID}
                doctorIndex={this.state.doctorIndex}
                seleectFirstProvider={this.seleectFirstProvider}
                doctorDetailPopupHandler={this.doctorDetailPopupHandler}
                reasons={this.state.reasonForVisit.reason}
              />
            ) : (
              ""
            )}
            <BookAppointment
              reasonForVisits={this.state.reasonForVisit}
              reasonForVisitsArray={this.state.reasonForVisits}
              practiceLocationID={this.state.practiceLocationID}
              PracticeAccount={this.props.PracticeAccount}
              checlkAge={this.checlkAge}
              practice={this.props.practice}
              doesPatientHasPreference={this.state.doesPatientHasPreference}
              doctorIndex={this.state.doctorIndex}
              doctorPreferenceCheck={this.state.doctorPreferenceCheck}
              onAppointmentTimeChange={this.handleAppointmentTimeChange}
              onAppointmentDateChange={this.handleAppointmentDateChange}
              appointmentType={this.state.appointmentType}
              appointmentDate={this.state.appointmentDate}
              checkAvailabilty={this.checkAvailabilty}
              appointmentTime={this.state.appointmentTime}
              appointmentTimeSlots={this.state.appointmentTimeSlots}
              selectedSlots={this.state.selectedSlots}
              doctorID={this.state.doctorID}
              doctor_available_status={this.props.doctor_available_status}
              doctorData={this.state.doctorData}
            />
            <PersonalInformation
              handelGetGender={this.handelGetGender}
              handelGetGenderInformation={this.handelGetGenderInformation}
              GenderInformation={this.state.GenderInformation}
              GenderValue={this.state.Gender}
              handelChangeGender={this.handelChangeGender}
              checlkAge={this.checlkAge}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              patientDOB={this.state.patientDOB}
              patientEmail={this.state.patientEmail}
              patientContact={this.state.patientContact}
              onFirstNameChange={this.handleFirstNameChange}
              onLastNameChange={this.handleLastNameChange}
              onPatientDOBChange={this.handlePatientDOBChange}
              handlePatientDOBErrorChange={this.handlePatientDOBErrorChange}
              onPatientEmailChange={this.handlePatientEmailChange}
              onPatientContactChange={this.handlePatientContactChange}
              handleScriptLoad={this.handleScriptLoad}
              handleLocationAddressChange={this.handleLocationAddressChange}
              billingAddress={this.state.billingAddress}
              duplicateAddressFlag={this.duplicateAddressFlag}
              onNewPatientChange={this.handleIsNewPatientChange}
              isNewPatient={this.state.isNewPatient}
            />

            <Insurance
              SameDayRT_PCR={this.state.SameDayRT_PCR}
              CovidVaccinne={this.state.CovidVaccinne}
              isNewPatient={this.state.isNewPatient}
              patientIsurnaceMemberID={this.state.patientIsurnaceMemberID}
              OnPatientInusranceIDChange={
                this.handlePatientInusranceIDChange
              }
              onPatientInsuranceMemberIDChange={
                this.handlePatientInsuranceMemberIDChange
              }
              onPatientHasInsuranceChange={
                this.handlePatientHasInsuranceChange
              }
              insurances={this.state.InsuranceBypracticeid}
              handelInsuranceimg={this.handelInsuranceimg}
              InsuranceFile={this.state.InsuranceFile}
              InsuranceShowImg={this.state.InsuranceShowImg}
              Pcr={this.state.Pcr}
              CovidPCrRapidte30minChange={
                this.state.CovidPCrRapidte30minChange
              }
              AntiBody={this.state.AntiBody}
              CovidRapidTest={this.state.CovidRapidTest}
            />
            {this.state.isNewPatient ? null : (
              <Attachments
                PhoneNumFlag={this.state.PhoneNumFlag}
                patientDOB={this.state.patientDOB}
                handelissueChanges={this.handelissueChanges}
                Patientage={this.state.Patientage}
                DrivingLicShowImg={this.state.DrivingLicShowImg}
                DrivingLicFile={this.state.DrivingLicFile}
                handelDrivingLic={this.handelDrivingLic}
              />
            )}
            {/* </>
            // ) : (
            //   <>
            //     <Reason
            //       SameDayRT_PCR={this.state.SameDayRT_PCR}
            //       CovidPCrRapidte30minChange={
            //         this.state.CovidPCrRapidte30minChange
            //       }
            //       CovidVaccinne={this.state.CovidVaccinne}
            //       ReasonsList={this.props.ReasonsList}
            //       Reasonforvisti={this.state.reasonForVisit}
            //       reasonForVisits={this.state.reasonForVisits}
            //       Pcr={this.state.Pcr}
            //       AntiBody={this.state.AntiBody}
            //       CovidRapidTest={this.state.CovidRapidTest}
            //       onNewPatientChange={this.handleIsxNewPatientChange}
            //       onReasonForVisitChange={this.handleOnReasonForVisitChange}
            //     />
            //     {this.state.CovidVaccinne ? (
            //       <EligibleCategory
            //         EligibleCategoryvalue={this.state.EligibleCategory}
            //         EligibleCategory={this.EligibleCategory}
            //       />
            //     ) : (
            //       ""
            //     )}
            //     <BookAppointment
            //       reasonForVisits={this.state.reasonForVisits}
            //       practiceLocationID={this.state.practiceLocationID}
            //       PracticeAccount={this.props.PracticeAccount}
            //       practice={this.props.practice}
            //       doctorPreferenceCheck={this.state.doctorPreferenceCheck}
            //       doesPatientHasPreference={this.state.doesPatientHasPreference}
            //       doctorIndex={this.state.doctorIndex}
            //       onAppointmentTimeChange={this.handleAppointmentTimeChange}
            //       onAppointmentDateChange={this.handleAppointmentDateChange}
            //       appointmentDate={this.state.appointmentDate}
            //       checkAvailabilty={this.checkAvailabilty}
            //       appointmentTime={this.state.appointmentTime}
            //       appointmentTimeSlots={this.state.appointmentTimeSlots}
            //       selectedSlots={this.state.selectedSlots}
            //       doctorID={this.state.doctorID}
            //       doctor_available_status={this.props.doctor_available_status}
            //     />
            //     <PersonalInformation
            //       handelGetGender={this.handelGetGender}
            //       handelGetGenderInformation={this.handelGetGenderInformation}
            //       GenderInformation={this.state.GenderInformation}
            //       GenderValue={this.state.Gender}
            //       handelChangeGender={this.handelChangeGender}
            //       // GenderValue={this.state.Gender}
            //       checlkAge={this.checlkAge}
            //       firstName={this.state.firstName}
            //       lastName={this.state.lastName}
            //       patientDOB={this.state.patientDOB}
            //       patientEmail={this.state.patientEmail}
            //       patientContact={this.state.patientContact}
            //       onFirstNameChange={this.handleFirstNameChange}
            //       onLastNameChange={this.handleLastNameChange}
            //       onPatientDOBChange={this.handlePatientDOBChange}
            //       handlePatientDOBErrorChange={this.handlePatientDOBErrorChange}
            //       onPatientEmailChange={this.handlePatientEmailChange}
            //       onPatientContactChange={this.handlePatientContactChange}
            //       handleScriptLoad={this.handleScriptLoad}
            //       billingAddress={this.state.billingAddress}
            //       handleLocationAddressChange={this.handleLocationAddressChange}
            //       duplicateAddressFlag={this.duplicateAddressFlag}
            //       onNewPatientChange={this.handleIsNewPatientChange}
            //       isNewPatient={this.state.isNewPatient}
            //     />

            //     <Insurance
            //       SameDayRT_PCR={this.state.SameDayRT_PCR}
            //       CovidVaccinne={this.state.CovidVaccinne}
            //       isNewPatient={this.state.isNewPatient}
            //       patientIsurnaceMemberID={this.state.patientIsurnaceMemberID}
            //       OnPatientInusranceIDChange={
            //         this.handlePatientInusranceIDChange
            //       }
            //       onPatientInsuranceMemberIDChange={
            //         this.handlePatientInsuranceMemberIDChange
            //       }
            //       onPatientHasInsuranceChange={
            //         this.handlePatientHasInsuranceChange
            //       }
            //       insurances={this.state.InsuranceBypracticeid}
            //       handelInsuranceimg={this.handelInsuranceimg}
            //       CovidPCrRapidte30minChange={
            //         this.state.CovidPCrRapidte30minChange
            //       }
            //       InsuranceFile={this.state.InsuranceFile}
            //       InsuranceShowImg={this.state.InsuranceShowImg}
            //       Pcr={this.state.Pcr}
            //       AntiBody={this.state.AntiBody}
            //       CovidRapidTest={this.state.CovidRapidTest}
            //     />

            //     {this.state.isNewPatient ? null : (
            //       <Attachments
            //         PhoneNumFlag={this.state.PhoneNumFlag}
            //         patientDOB={this.state.patientDOB}
            //         handelissueChanges={this.handelissueChanges}
            //         Patientage={this.state.Patientage}
            //         DrivingLicShowImg={this.state.DrivingLicShowImg}
            //         DrivingLicFile={this.state.DrivingLicFile}
            //         handelDrivingLic={this.handelDrivingLic}
            //       />
            //     )}
            //   </>
            // )} */}

            {this.state.Pcr ||
              this.state.CovidRapidTest ||
              this.state.CovidPCrRapidte30minChange ||
              this.state.SameDayRT_PCR ||
              this.state.AntiBody ? (
              <>
                <h5
                  style={{ color: "#1489FF", fontSize: 14, marginTop: 80 }}
                  className="app-p2-new"
                >
                  Quick Details:
                </h5>

                <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - COVID-19 RT-PCR <u> 30</u> Min. Test:
                  </span>
                  $175. Turnaround time 30 Minutes.
                </p>

                <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - COVID-19 RT-PCR Standard Test:
                  </span>
                  With In-network insurance copayment, coinsurance or deductible
                  may apply. $250 without insurance. Turnaround time 48-72 hrs.
                </p>

                <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - COVID-19 Rapid Antigen Test:
                  </span>
                  With In-network insurance copayment, coinsurance or deductible
                  may apply, $100 without insurance. Turnaround 1 - 3 Hours.
                </p>

                <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - COVID-19 Antibody Test:
                  </span>
                  With In-network insurance copayment, coinsurance or deductible
                  may apply. $250 without insurance. Turnaround time 48-72 hrs.
                </p>

                <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - COVID-19 Vaccine:
                  </span>
                  Free
                </p>
                <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - Free Travel Certificates
                  </span>
                </p>

                {/* <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - COVID-19 RT-PCR Standard Test:
                  </span>
                  Free with listed insurances. Turnaround time 48-72 hrs.
                </p> */}

                {/* <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - COVID-19 Rapid Antigen Test:
                  </span>
                  Free with listed insurances. Turnaround 1- 3 Hours.
                </p> */}
                {/* <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>-
                    Antibody Test:
                  </span>
                  Free with listed insurances. Turnaround time 48-72 hrs.
                </p> */}
                {/* <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - Letter From The Doctor $25.
                  </span>
                </p> */}
                {/* <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    - * Free Travel Certificate with 30 Min. PCR Test
                  </span>
                </p> */}

                <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    {" "}
                    Disclaimer:
                  </span>
                  <i>
                    Upon making an appointment, the patient acknowledges that
                    copayment or coinsurance may apply for some tests and
                    insurances. If the insurance deductible is not met, we can
                    charge full price for the test: Rapid Antigen $100, 30 Min.
                    RT-PCR $175.
                  </i>
                </p>

                {/* <p className="app-p2-new">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    **Listed Insurances:
                  </span>
                  Empire BCBS New York, Medicare, United Healthcare, Aetna, 1199
                  Benefits, Cigna, Oxford, UMR, FidelisCare.
                </p> */}
              </>
            ) : null}

            <p style={{ fontSize: 18, color: "#00234b" }} className="app-p2-new">
              {/*  By submitting this form, I agree to receive text messages from */}
              {/* {/ Manhattan Medical Arts /} */}
              {/* <span> */}
              <FormControlLabel
                // value="top"
                sx={{ mr: 1 }}
                control={<Checkbox sx={{ paddingLeft: '0px' }} checked={this.state.receiveMessageFlag} onChange={this.handleReceiveMessagesFlag} />}
                label={this.props.practice.SendMessageLabel ? `${this.props.practice.SendMessageLabel}` : `By submitting this form, I agree to receive text messages from ${this.props.practice.practiceName}`}
                labelPlacement="end"
              />

            </p>
            <p style={{ fontSize: 18, color: "#00234b" }} className="app-p2-new">
              {/*  By submitting this form, I agree to receive text messages from */}
              {/* {/ Manhattan Medical Arts /} */}
              {/* <span> */}
              <FormControlLabel
                // value="top"
                sx={{ mr: 1 }}
                control={<Checkbox sx={{ paddingLeft: '0px' }} checked={this.state.receiveEmailFlag} onChange={this.handleEmailFlag} />}
                label={this.props.practice.SendEmailLabel ? `${this.props.practice.SendEmailLabel}` : `By submitting this form, I agree to receive emails from ${this.props.practice.practiceName}`}
                labelPlacement="end"
              />

            </p>
            {/* <p className="app-p2-new">
              <i>
                (Please note: We do RT-PCR testing at our site. NAAT is{" "}
                <b> not</b> classified as RT-PCR as mentioned by some
                businesses. Most airlines require an RT-PCR test.)
              </i>
            </p> */}
            <p className="app-p2-new">
              <ReCAPTCHA
                sitekey={TEST_SITE_KEY}
                onChange={(v) => this.handelcaptch(v)}
              />
            </p>
            <Button
              className="app-btn-new"
              style={{ cursor: "pointer" }}
              disabled={
                Object.keys(this.state.reasonForVisits).length === 0
                  ?
                  (( this.validateCheckboxes()||this.state.firstName == "" ||
                    this.state.lastName == "" ||
                    this.state.practiceLocationID == "" ||
                    this.state.DateError == "invalidDate" ||
                    this.state.DateError == "minDate" ||
                    this.state.DateError == "disableFuture" ||
                    this.state.teleVisit2 == "" ||
                    this.state.patientEmail == "" ||
                    this.state.Gender == "" ||
                    this.state.patientContact == "" ||
                    this.state.appointmentTime == "Appointment Time *" ||
                    this.state.reasonForVisit == "Reason" ||
                    this.state.reasonForVisit == "" ||
                    // this.state.validNumFlag == false ||
                    this.state.validEmailFlag == false ||
                    this.state.patientDOB == '' ||
                    this.state.patientDOB == null ||
                    this.state.billingAddress == "" ||
                    this.state.appointmentDate == "") == false && //||this.state.patientDOB == ""
                    this.state.doesPatientHasInsurance == false &&
                    this.state.DrivingLicFile[0] &&
                    this.state.DrivingLicFile[1] &&
                    this.state.doctorPreferenceCheck === false) ||
                    ((this.validateCheckboxes()||this.state.firstName == "" ||
                      this.state.practiceLocationID == "" ||
                      this.state.DateError == "invalidDate" ||
                      this.state.lastName == "" ||
                      this.state.patientEmail == "" ||
                      this.state.Gender == "" ||
                      this.state.patientContact == "" ||
                      this.state.appointmentTime == "Appointment Time *" ||
                      this.state.reasonForVisit == "Reason" ||
                      this.state.reasonForVisit == "" ||
                      this.state.patientDOB == '' ||
                      this.state.patientDOB == null ||
                      this.state.billingAddress == "" ||
                      // this.state.validNumFlag == false ||
                      this.state.validEmailFlag == false ||
                      this.state.appointmentDate == "") == //||
                      // this.state.billingAddress === ""||
                      false && //this.state.patientDOB == ""
                      this.state.DrivingLicFile[0] &&
                      this.state.DrivingLicFile[1] &&
                      this.state.doesPatientHasInsurance == true &&
                      this.state.doctorPreferenceCheck === false &&
                      this.state.HealthInsuranceID !== null &&
                      this.state.patientIsurnaceMemberID !== "" &&
                      this.state.DrivingLicFile[0] &&
                      this.state.DrivingLicFile[1] &&
                      this.state.patientIsurnaceMemberID.length < 17) ||
                    ((this.validateCheckboxes()||this.state.firstName == "" ||
                      this.state.practiceLocationID == "" ||
                      this.state.DateError == "invalidDate" ||
                      this.state.lastName == "" ||
                      this.state.patientEmail == "" ||
                      this.state.Gender == "" ||
                      this.state.patientContact == "" ||
                      this.state.appointmentTime == "Appointment Time *" ||
                      this.state.reasonForVisit == "Reason" ||
                      this.state.reasonForVisit == "" ||
                      this.state.patientDOB == '' ||
                      this.state.patientDOB == null ||
                      this.state.billingAddress == "" ||
                      // this.state.validNumFlag == false ||
                      this.state.validEmailFlag == false ||
                      this.state.appointmentDate == "") == //||
                      // this.state.billingAddress === ""||
                      false && //this.state.patientDOB == ""
                      this.state.DrivingLicFile[0] &&
                      this.state.DrivingLicFile[1] &&
                      this.state.doesPatientHasInsurance == false &&
                      this.state.doctorPreferenceCheck === true &&
                      this.state.TeleVisitDrListData.length !== 0 &&
                      this.state.doctorID !== null) ||
                    ((this.validateCheckboxes()||this.state.firstName == "" ||
                      this.state.practiceLocationID == "" ||
                      this.state.DateError == "invalidDate" ||
                      this.state.lastName == "" ||
                      this.state.patientEmail == "" ||
                      this.state.Gender == "" ||
                      this.state.patientContact == "" ||
                      this.state.appointmentTime == "Appointment Time *" ||
                      this.state.reasonForVisit == "Reason" ||
                      this.state.reasonForVisit == "" ||
                      this.state.patientDOB == '' ||
                      this.state.patientDOB == null ||
                      this.state.billingAddress == "" ||
                      // this.state.validNumFlag == false ||
                      this.state.validEmailFlag == false ||
                      this.state.appointmentDate == "") == //||
                      // this.state.billingAddress === ""||
                      false && //this.state.patientDOB == ""
                      this.state.doesPatientHasInsurance == true &&
                      this.state.doctorPreferenceCheck === true &&
                      this.state.HealthInsuranceID != null &&
                      this.state.TeleVisitDrListData.length != 0 &&
                      this.state.patientIsurnaceMemberID !== "" &&
                      this.state.doctorID !== null &&
                      this.state.DrivingLicFile[0] &&
                      this.state.DrivingLicFile[1] &&
                      this.state.patientIsurnaceMemberID.length < 17)
                    ? false
                    : true
                  : (
                    Object.keys(this.state.reasonForVisits).length !== 0
                      ? ((this.validateCheckboxes()||this.state.firstName == "" ||
                        this.state.practiceLocationID == "" ||
                        this.state.DateError == "invalidDate" ||
                        this.state.lastName == "" ||
                        this.state.patientEmail == "" ||
                        this.state.Gender == "" ||
                        this.state.patientContact == "" ||
                        this.state.appointmentTime ==
                        "Appointment Time *" ||
                        this.state.patientDOB == '' ||
                        this.state.patientDOB == null ||
                        this.state.billingAddress == "" ||
                        // this.state.validNumFlag == false ||
                        this.state.validEmailFlag == false ||
                        this.state.appointmentDate == "") == false && //||this.state.patientDOB == ""
                        this.state.doesPatientHasInsurance == false &&
                        this.state.DrivingLicFile[0] &&
                        this.state.DrivingLicFile[1]) ||
                      ((this.validateCheckboxes()||this.state.firstName == "" ||
                        this.state.practiceLocationID == "" ||
                        this.state.DateError == "invalidDate" ||
                        this.state.lastName == "" ||
                        this.state.patientEmail == "" ||
                        this.state.Gender == "" ||
                        this.state.patientContact == "" ||
                        this.state.appointmentTime ==
                        "Appointment Time *" ||
                        // this.state.validNumFlag == false ||
                        this.state.validEmailFlag == false ||
                        this.state.appointmentDate == "") == //||
                        // this.state.billingAddress === ""||
                        false && //this.state.patientDOB == ""
                        this.state.doesPatientHasInsurance == true &&
                        this.state.HealthInsuranceID != null &&
                        this.state.patientIsurnaceMemberID != "" &&
                        this.state.DrivingLicFile[0] &&
                        this.state.DrivingLicFile[1] &&
                        this.state.patientIsurnaceMemberID.length < 17) ||
                      ((this.validateCheckboxes()||this.state.firstName == "" ||
                        this.state.practiceLocationID == "" ||
                        this.state.DateError == "invalidDate" ||
                        this.state.lastName == "" ||
                        this.state.patientEmail == "" ||
                        this.state.Gender == "" ||
                        this.state.patientContact == "" ||
                        this.state.appointmentTime ==
                        "Appointment Time *" ||
                        this.state.patientDOB == '' ||
                        this.state.patientDOB == null ||
                        this.state.billingAddress == "" ||
                        // this.state.validNumFlag == false ||
                        this.state.validEmailFlag == false ||
                        this.state.appointmentDate == "") == //||
                        // this.state.billingAddress === ""||
                        false && //this.state.patientDOB == ""
                        this.state.doesPatientHasInsurance == false &&
                        this.state.DrivingLicFile[0] &&
                        this.state.DrivingLicFile[1]) ||
                      ((this.validateCheckboxes()||this.state.firstName == "" ||
                        this.state.practiceLocationID == "" ||
                        this.state.DateError == "invalidDate" ||
                        this.state.lastName == "" ||
                        this.state.patientEmail == "" ||
                        this.state.Gender == "" ||
                        this.state.patientContact == "" ||
                        this.state.appointmentTime ==
                        "Appointment Time *" ||
                        this.state.patientDOB == '' ||
                        this.state.patientDOB == null ||
                        this.state.billingAddress == "" ||
                        // this.state.validNumFlag == false ||
                        this.state.validEmailFlag == false ||
                        this.state.appointmentDate == "") == //||
                        // this.state.billingAddress === ""||
                        false && //this.state.patientDOB == ""
                        this.state.doesPatientHasInsurance == true &&
                        this.state.HealthInsuranceID != null &&
                        this.state.patientIsurnaceMemberID != "" &&
                        this.state.DrivingLicFile[0] &&
                        this.state.DrivingLicFile[1] &&
                        this.state.patientIsurnaceMemberID.length < 17)
                      : false
                  )
                    ? false
                    : true
              }
              type="button"
              onClick={this.handleAppointmentSubmission}
            >
              Request Appointment
            </Button>
            {this.props.where && (
              <button
                type="button"
                className="app-btn-new"
                onClick={() =>
                  this.props.appointmentFormHandler(true, false, null)
                }
                style={{ cursor: "pointer", marginLeft: "40px" }}
              >
                CANCEL
              </button>
            )}
            <p
              className="app-p3-new"
              style={{
                display: "none",
              }}
            >
              <i className="material-icons app-icon1">error_outline</i> Please
              fill out all the details to book an appointment. All fields are
              required
            </p>
          </form>
        );
      case "APPOINTMENT_LOADING":
        this.scrollToBottom();
        return (
          <div
            style={{
              height: "50vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#143062" }} />
          </div>
        );
      case "PRACTICE_NOT_FOUND":
        return <div>Page not found!</div>;
      case "APPOINTMENT_SCUCCESSFUL":
        this.scrollToBottom();
        return this.props.where ? (
          this.props.appointmentFormHandler(true, true, true)
        ) : (
          <>
            <Success
              color="black"
              img={successImage}
              status="To keep track of your appointment’s  
              progress and receive your reports later on,  
              download Zebra Doctor mobile app"
              status1="Appointment Requested Successfully!"
              btnText="Request Another Appointment"
              onClick={this.handleAnotherAppointment}
              requestAppointmentId={false}
            />
          </>
        );
      case "APPOINTMENT_FAILURE":
        return this.props.where ? (
          this.props.appointmentFormHandler(true, true, false)
        ) : (
          <Success
            requestAppointmentId={false}
            color="#E92829"
            img={errorImage}
            status="Ooh, Sorry!"
            status1="Something went wrong! Please try again."
            btnText="Try Again"
            onClick={this.handleAnotherAppointment}
          />
        );

      default:
    }
  }
  scrollToBottom = () => {
    window.scrollTo(0, 0);
  };
  componentDidUpdate(prevprops, prevstate) {
    if (prevstate.Success !== this.state.Success) {
      this.scrollToBottom();
    }
  }
  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  render() {
    console.log("staremanagement", this.validateCheckboxes());
    // console.log("docotorIDState", JSON.parse('{"start":"2022-08-03T09:40:00.000Z","end":"2022-08-03T09:50:00.000Z"}'
    // ));


    // console.log("allProps>>", this.props);
    // console.log("====================================");
    // console.log(
    //   "sdfsdfds",
    //   this.state,
    //   queryString.parse(this.props.location.search).utm_source
    // );
    // console.log("====================================");
    return (
      <div role="main">
        <div
          id="printpdf"
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            this.messagesEnd = el;
          }}
        ></div>
        <div className="appointment-new">
          {this.props.practice !== null ||
            !Object.keys(this.props.practice).length == 0 ? (
            this.props.practice.gtmCode !== null ||
              this.props.practice.gtmCode !== undefined ? (
              <GoogleTagManager
                gtmId={this.props.practice.gtmCode}
                scriptId="gtm-script-container"
              />
            ) : this.props.practice.GATrackingCode !== null ||
              this.props.practice.GATrackingCode !== "null" ||
              this.props.practice.GATrackingCode !== "" ? (
              <GoogleAnalyticsManager
                GATrackingCode={this.props.practice.GATrackingCode}
              />
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {
            this.state.Success ? (
              <Success
                color="black"
                img={successImage}
                status={"You shall be updated about this appointment."}
                status1={"Appointment Received"}
                btnText="Book Appointment"
                onClick={this.resetCallRequest}
              />
            ) : (
              this.getScreen(this.state.screenStatus)
            )
            // this.getScreen(this.props.post_appointment_status)
          }
          {/* {this.props.AddManual ? null : this.props.where ? null : (
            <>
              {this.state.ReviewsData[0]?.doctorReviews.length !== 0 &&
              this.state.ReviewsData.length !== 0 ? (
                <Review
                  ReviewsData={this.state.ReviewsData}
                  doctorIndex={this.state.doctorIndex}
                  doctors={this.state.doctors}
                />
              ) : this.state.ReviewLoader ? (
                <>
                  <Loader />
                </>
              ) : (
                ""
              )}
            </>
          )} */}
        </div>
        {this.state.doctorDetailPopup ? (
          <DoctorDetails
            doctorDetailPopupHandler={this.doctorDetailPopupHandler}
            selectedDoctorIndex={this.state.selectedDoctorIndex}
            selectDoctor={this.selectedDoctor}
            selectedDoctor={
              this.state.TeleVisitDrListData[this.state.selectedDoctorIndex]
            }
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Appointment)
);
