import Axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ROOT_URL } from "../../constants/config";
import Footer from "../NewAppointmentForm/Footer";
import Header from "../NewAppointmentForm/Header";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import SuccessModal from "../@common/SuccessModal/SuccessModal";
import "./ReSchedule_form.scss";
import {
  IconButton,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  CircularProgress,
  Box,
  Button,
  Grid,
  Radio,
} from "@mui/material";
import { NativeSelect, OutlinedInput } from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
const ReSchedule_Form = (props) => {
  const [PracticeAccount, setPracticeAccount] = useState({});
  const [Practicedata, setPracticedata] = useState({});
  const [PracticeIDS, setPracticeIDS] = useState({});
  const [ProviderID, setProviderID] = React.useState("");
  const [SelectDate, setSelectDate] = React.useState(null);
  const [Slots, setSlots] = React.useState([]);
  const [Time, setTime] = React.useState(null);
  const [PrevTime, setPrevTime] = React.useState("");
  const [ProvideLoading, setProvideLoading] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [TimeLoading, setTimeLoading] = React.useState(false);
  const [TimeError, setTimeError] = React.useState("");
  const [Doctors, setDoctors] = React.useState([]);
  const [reasonCheck, setReasonCheck] = useState("re-schedule");
  const [cancelSchedule_text, setCancelSchedule_text] = useState("");
  const [openSuccessModal, setopenSuccessModal] = useState(false);
  const [openSuccessModalCencalled, setopenSuccessModalCencalled] = useState(false);
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const handleReason_check = (e) => {
    console.log("checkedData", e.target.value);
    setReasonCheck(e.target.value);
    if (e.target.value == "re-schedule") {
      setCancelSchedule_text("");
    }
    setSelectDate(null);
    setTime(null);
    setTimeError("");
    setSlots([]);
  };
  const handle_cancelSchedule_text = (e) => {
    setCancelSchedule_text(e.target.value);
  };
  // const handleChangeProvider = (e) => {
  //   setProviderID(e.target.value);
  //   setSelectDate(null);
  //   setTime(null);
  //   setPrevTime("");
  //   setSlots([]);
  // };

  const handle_api_date = (date) => {
    let AptDate = moment(date).format("YYYY-MM-DD");
    console.log("AptDate",AptDate);
    setPrevTime("");
    setSelectDate(date);
    setTimeLoading(true);
    setTime("Loading");
    setSlots([])
    let Day = moment(date).day();
    // let Day = moment(date).format("dddd");

    let SlectedDate = AptDate;
    let CurrentDAte = moment().format("YYYY-MM-DD");
    if (ProviderID !== null) {
      let body = {
        doctorID: ProviderID,
        appointmentDay: Day,
        date: `${AptDate}T19:00:00.000Z`,
        AppointmentDate:AptDate,
        practiceLocationID:PracticeAccount._id,
        appointmentID:PracticeIDS.appointmentID
      };
      Axios.post(`${ROOT_URL}/api/client/DoctorAvialabilty/getByDay`, body)
        .then((res) => {
          console.log("doctorID_res", res);
          if (res.data.MS_GetTimeSlots == "success") {
            if (SlectedDate == CurrentDAte) {
              let updatedSlots = [];
              var now = moment();
              var FormattedDate = now.toDate();
              var ItalinaDate = JSON.stringify(new Date(FormattedDate));
              var TimeOnly = ItalinaDate.substr(12, 5);
              var predicate = (x) => {
                return x.substr(12, 5) > TimeOnly;
              };
              updatedSlots = res.data.data.filter(predicate);
              setSlots(updatedSlots);
            } else {
              setSlots(res.data.data);
            }
          } else {
            setSlots([]);
          }
          setTimeLoading(false);
          setTime(null);
        })
        .catch((e) => {
          setTimeLoading(false);
          setTime(null);
          setSlots([]);
        });
    } else {
      let Body = {
        practiceLocationID: PracticeIDS?.practiceDetails?._id,
        day: Day,
        date: `${AptDate}T19:00:00.000Z`,
      };
      console.log("doctorID_resWITHOUTBody", Body);
      Axios.post(
        `${ROOT_URL}/api/client/DoctorAvialabilty/FirstAvailableAppointmentAvailabilties`,
        Body
      )
        .then((resposne) => {
          console.log("doctorID_resWITHOUT", resposne);
          if (resposne.data.MS_doctorAvailabilityByDayStatus == "success") {
            if (SlectedDate == CurrentDAte) {
              let updatedSlots = [];
              var now = moment();
              var FormattedDate = now.toDate();
              var ItalinaDate = JSON.stringify(new Date(FormattedDate));
              var TimeOnly = ItalinaDate.substr(12, 5);
              var predicate = (x) => {
                return x.substr(12, 5) > TimeOnly;
              };
              updatedSlots = resposne.data.data.filter(predicate);
              setSlots(updatedSlots);
            } else {
              setSlots(resposne.data.data);
            }
          } else {
            setSlots([]);
          }
          setTimeLoading(false);
          setTime(null);
        })
        .catch((e) => {
          setTimeLoading(false);
          setTime(null);
          setSlots([]);
        });
    }
  };
  const handelTimeChange = (e) => {
    setTimeError("");
    console.log("dataSelected", e.target.value);
    let { value } = e.target;
    setTime(value);
    setPrevTime("");
  };
  const update_api_submitDetails = () => {
    setLoading(true);
    let AptDate = moment(SelectDate).format("YYYY-MM-DD");
    let body = {
      reSchedule: reasonCheck == "re-schedule" ? true : false,
      _id: PracticeIDS.appointmentID,
      appointmentDate: `${AptDate}T00:00:00.000Z`,
      appointmentTime: Time,
      Cancellation_Reason: cancelSchedule_text,
    };
    console.log("updateDEtaial", body);
    Axios.post(
      `${ROOT_URL}/api/client/appointments/rescheduleAppointment`,
      body
    ).then((res) => {
      console.log("updateDEtaialRES", res.data);
      setLoading(false);
      setSelectDate(null);
      setTime(null);
      if(reasonCheck == "re-schedule"){
        setopenSuccessModal(true);
      } else{
        setopenSuccessModalCencalled(true)
      }
    });
  };
  const addMonths = (date, months) => {
    var newDate = new Date();
    newDate = date;
    newDate.setMonth(date.getMonth() + months);
    return newDate;
  };
  React.useEffect(() => {
    setPrevTime("");
  }, [Doctors]);

  const history = useHistory();
  useEffect(() => {
    getpraticeData_byUrl();
  }, []);
  // useEffect(() => {
  //   AllenableDoctor();
  // }, [PracticeAccount]);

  const getpraticeData_byUrl = () => {
    const body = {
      aid: history.location.search.split("=")[1],
      componentName: "",
    };
    console.log("bodyURL", body);
    Axios.post(
      `${ROOT_URL}/api/practiceAdmin/getpracticebyshortID${history.location.search}`,
      body
    )
      .then((res) => {
        console.log("ResURL", res.data);
        if (
          res.data.MS_getpracticeInformationbypracLocID_Status !== "failure"
        ) {
          setPracticeIDS(res.data);
          setPracticeAccount(res.data.practiceDetails);
          setPracticedata(res.data.practiceDetails.practiceID);
          setProviderID(res.data.doctorID);
        } else {
        }
      })
      .catch((e) => {});
  };
  // const AllenableDoctor = () => {
  //   setProvideLoading(true);

  //   const body = {
  //     practiceLocationID: PracticeAccount._id,
  //   };
  //   Axios.post(
  //     `${ROOT_URL}/api/locationAdmin/getListofEnabledDoctors`,
  //     body
  //   ).then((res) => {
  //     setDoctors(res.data.doctorsList);
  //     setProvideLoading(false);
  //     console.log("====================================");
  //     console.log("32423", res.data);
  //     console.log("====================================");
  //   });
  // };
  const OpenSuccessModal = (e) => {
    // e.preventDefault();
    // e.target.reset();

    setopenSuccessModal(e);
  };
  const OpenSuccessModalCancelled = (e) => {
    // e.preventDefault();
    // e.target.reset();

    setopenSuccessModalCencalled(e);
  };
  console.log("PracticeAccount111", TimeError);
  return (
    <>
      <Header PracticeAccount={PracticeAccount} practice={Practicedata} />

      <div className="ReSchedule_Form_wrapper">
        <Grid container>
          <div className="ReSchedule_Form_header">
            <Grid item xs={12} md={4}>
              <FormControl style={{ width: "100%" }} fullWidth>
                <InputLabel id="demo-simple-selectProvider">
                  I would like to
                </InputLabel>
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-simple-selectProvider"
                  id="demo-simple-selectProvider"
                  value={reasonCheck}
                  label="Select Provider"
                  onChange={handleReason_check}
                >
                  <MenuItem value="re-schedule">Re-Schedule</MenuItem>

                  <MenuItem value="Cancel-Schedule">Cancel-Schedule</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </div>
          {reasonCheck == "re-schedule" ? (
            <>
              <div className="ReSchedule_FormBody">
                <Grid item xs={12} md={4}>
                  <div className="ReSchedule_FormBody_Datepicker">
                    <h3 className="ReSchedule_FormBody_Datepicker_heading">
                      Select Appointment Date and Time
                    </h3>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        minDate={new Date()}
                        maxDate={addMonths(new Date(), 3)}
                        style={{ width: "100%" }}
                        label="Appointment Date*"
                        inputFormat="MM/dd/yyyy"
                        value={SelectDate}
                        onError={(e) => setTimeError(e)}
                        onChange={handle_api_date}
                        renderInput={(params) => (
                          <TextField style={{ width: "100%" }} {...params} />
                        )}
                      />
                    </LocalizationProvider>
                    <p>{TimeError ? "Enter a valid date" : null}</p>
                  </div>
                  <div className="ReSchedule_FormBody_TimePicker">
                    {/* <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {TimeLoading ? "Loading" : " Appointment Time"}
                      </InputLabel> */}
                    <NativeSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      input={
                        <OutlinedInput
                          style={{ width: "100%" }}
                          aria-label="Select Time"
                          id="outlined-age-simple2"
                        />
                      }
                      inputProps={{
                        "aria-label": "Select Appointment Time",
                      }}
                      variant="outlined"
                      value={Time}
                      onChange={handelTimeChange}
                    >
                      {Slots.length == 0 ? (
                        !TimeLoading ? (
                          <option value=""> Appointment Time*</option>
                        ) : (
                          <option value=""> Loading..</option>
                        )
                      ) : (
                        ""
                      )}
                      {Slots.length == 0 ||
                      TimeError == "maxDate" ||
                      TimeError == "minDate" ||
                      TimeError == "invalidDate" ? (
                        <option value="">No time slot available</option>
                      ) : (
                        <>
                          {" "}
                          <option value="">Appointment Time*</option>
                          {Slots.map((v, i) => {
                            return (
                              <option value={v}>
                                {`${moment(JSON.parse(v)).format(
                                  "hh:mm A"
                                )}(${tz})`}
                              </option>
                            );
                          })}
                        </>
                      )}
                    </NativeSelect>
                    {/* </FormControl> */}
                  </div>
                </Grid>
              </div>
              <div className="ReSchedule_FormFooter">
                <Grid item xs={12} md={4}>
                  <Button
                    onClick={update_api_submitDetails}
                    variant="contained"
                    disabled={
                      SelectDate == null ||
                      Time == null ||
                      Time == "Loading" ||Time==""||
                      TimeError == "maxDate" ||
                      TimeError == "minDate"
                    }
                    className="ReSchedule_FormFooter_save_btn"
                  >
                    {Loading ? (
                      <CircularProgress
                        style={{ height: 20, width: 20, color: "white" }}
                      />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Grid>
              </div>
            </>
          ) : (
            <div className="schedule-cancelling-wrapper">
              <Grid item xs={12} md={4} direction="column">
                <div className="schedule-cancelling-grid">
                  <TextField
                    className="schedule-cancelling"
                    placeholder="Reason"
                    value={cancelSchedule_text}
                    onChange={handle_cancelSchedule_text}
                  />
                  <Button
                    onClick={update_api_submitDetails}
                    className="save-cancelSchedule"
                    variant="contained"
                    disabled={cancelSchedule_text == "" ? true : false}
                  >
                    {Loading ? (
                      <CircularProgress
                        style={{ height: 20, width: 20, color: "white" }}
                      />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </Grid>
            </div>
          )}
        </Grid>
      </div>
      <SuccessModal
        hadnelSuccessModal={OpenSuccessModal}
        Toggle={openSuccessModal}
        bodyText="Appointment rescheduled successfully"
      />
      <SuccessModal
        hadnelSuccessModal={OpenSuccessModalCancelled}
        Toggle={openSuccessModalCencalled}
        bodyText="Appointment cancelled successfully"
      />
      <Footer PracticeAccount={PracticeAccount} practice={Practicedata} />
    </>
  );
};

export default ReSchedule_Form;
