import {
  appointments_Actions,
  callRequest_Actions,
  covidRequest_Actions,
} from "../constants/Appointments";
import store from "../store/index";
import { ROOT_URL } from "../constants/config";
import Axios from "axios";
export const AppointmetnsServer = {
  getAllInsurances: getAllInsurances,
  submitAppointment: submitAppointment,
  Submitvitinaryform: Submitvitinaryform,
  getAllAppointments: getAllAppointments,
  getAllDoctorsByPracticeID: getAllDoctorsByPracticeID,
  getAllAppointmentsByPracticeID: getAllAppointmentsByPracticeID,
  //................................
  getDoctorsByPracticeID: getDoctorsByPracticeID,
  //................................
  getAllInsurancesSuperAdmin: getAllInsurancesSuperAdmin,
  UpdateAppointmentStatusByID: UpdateAppointmentStatusByID,
  UpdateAppointmentDoctor: UpdateAppointmentDoctor,
  DoctorAvailability: DoctorAvailability,
  createCallRequest: createCallRequest,
  getCallRequest: getCallRequest,
  UpdateCallRequest: UpdateCallRequest,
  getAllVitinaryDoctorsByPracticeID: getAllVitinaryDoctorsByPracticeID,
  CreateCovidTestAppointment: CreateCovidTestAppointment,
};

export function UpdateCallRequest(token, data) {
  data.setLoading(true);
  console.log("Appointment Status Data:", token);
  const Request = fetch(
    ROOT_URL + "/api/admin/callrequests/updateCallRequestsById",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.stringify(token),
      },
      mode: "cors",
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((responseJson, err) => {
      data.setLoading(false);
      console.log("APPOINTMENT UPDATE FAILED", responseJson);
      if (responseJson.getAllCallRequestbyPractice_Status === "failure") {
        data.snackBarStatus("Failed to update Call Back Status");
        return;
      } else if (
        responseJson.getAllCallRequestbyPractice_Status === "success"
      ) {
        data.setStatus(data.newStatus);
        data.snackBarStatus("Call Back Status Updated Successfully");
        store.dispatch({
          type: callRequest_Actions.getCallRequest.UPDATE,
          payload: responseJson,
        });

        return;
      }
    })
    .catch((error) => {
      console.log("dsfdssdf", error);

      data.setLoading(false);
      data.snackBarStatus("Failed to update Call Back Status");
    });
  return { type: callRequest_Actions.getCallRequest.GET, payload: "none" };
}

export function getCallRequest(token, page, limit, status) {
  console.log("Appointment Status Data:", token, page, limit, status);
  // const Request = fetch(
  //   ROOT_URL +
  //     "/api/admin/callrequests/getCallRequestsByPracticeId/" +
  //     token.practiceID,
  //   {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: JSON.stringify(token),
  //     },
  //     mode: "cors",
  //   }
  // )
  //   .then((response) => response.json())
  let Body = {
    practiceID: token.practiceID,
    page: page,
    limit: limit,
    statusList: status,
  };
  // {
  //   practiceID: token.practiceID,
  //   page: 0,
  //   limit: 20,
  // };

  Axios.post(
    ROOT_URL + "/api/admin/callrequests/getCallRequestsByPracticeId",
    Body,
    {
      headers: {
        Authorization: JSON.stringify(token),
      },
    }
  )
    .then((responseJson, err) => {
      console.log("APPOINTMENT UPDATE FAILED", responseJson.data);
      if (responseJson.data.getAllCallRequestbyPractice_Status === "failure") {
        store.dispatch({
          type: callRequest_Actions.getCallRequest.FAILURE,
          payload: { error: "Server response error please try again !" },
        });
        return;
      } else if (
        responseJson.data.getAllCallRequestbyPractice_Status === "success"
      ) {
        console.log("APPOINTMENT UPDATED SUCCESSFULLy", responseJson.data);

        store.dispatch({
          type: callRequest_Actions.getCallRequest.SUCCESS,
          payload: responseJson.data,
        });

        return;
      }
    })
    .catch((error) => {
      console.log("APPOINTMENT UPDATE FAILED", error);

      store.dispatch({
        type: callRequest_Actions.getCallRequest.FAILURE,
        payload: error,
      });
    });

  return { type: callRequest_Actions.getCallRequest.GET, payload: "none" };
}

export function createCallRequest(data) {
  console.log("Appointment Status Data:", data);
  const Request = fetch(
    ROOT_URL + "/api/admin/callrequests/createCallRequest",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((responseJson, err) => {
      if (responseJson.MS_CallRequestCreationStatus === "failure") {
        console.log("APPOINTMENT UPDATE FAILED", responseJson);

        store.dispatch({
          type: callRequest_Actions.createCallRequest.FAILURE,
          payload: { error: "Server response error please try again !" },
        });
        return;
      } else if (responseJson.MS_CallRequestCreationStatus === "success") {
        console.log("APPOINTMENT UPDATED SUCCESSFULLy", responseJson);

        store.dispatch({
          type: callRequest_Actions.createCallRequest.SUCCESS,
          payload: responseJson,
        });

        return;
      }
    })
    .catch((error) => {
      store.dispatch({
        type: callRequest_Actions.createCallRequest.FAILURE,
        payload: error,
      });
    });

  return { type: callRequest_Actions.createCallRequest.POST, payload: "none" };
}

export function CreateCovidTestAppointment(data) {
  console.log("Appointment Status Data:", data);
  // .then((response) => response.json())

  // const Request = fetch(
  //   ROOT_URL + "/api/admin/covid19Form/handleFormCreation",
  //   {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     mode: "cors",
  //     body: data,
  //   }
  // )

  store.dispatch({
    type: "LOADING",
    // type: callRequest_Actions.createCallRequest.FAILURE,
    payload: true,
  });
  Axios.post(ROOT_URL + "/api/admin/covid19Form/handleFormCreation", data)
    .then((responseJson, err) => {
      console.log("dsfsdfsd", responseJson);

      if (responseJson.data.MS_Covid19FormCreationStatus === "faliure") {
        console.log("APPOINTMENT UPDATE FAILED", responseJson.data);

        store.dispatch({
          type: covidRequest_Actions.createCovidRequest.FAILURE,
          // type: callRequest_Actions.createCallRequest.FAILURE,
          payload: { error: "Server response error please try again !" },
        });
        return;
      }
      if (responseJson.data.MS_Covid19FormCreationStatus === "success") {
        console.log("APPOINTMENT UPDATED SUCCESSFULLy", responseJson.data);

        store.dispatch({
          type: covidRequest_Actions.createCovidRequest.SUCCESS,
          payload: responseJson.data,
        });

        return;
      }
    })
    .catch((error) => {
      console.log(error);

      store.dispatch({
        type: covidRequest_Actions.createCovidRequest.FAILURE,
        payload: error,
      });
    });

  return {
    type: covidRequest_Actions.createCovidRequest.POST,
    payload: "none",
  };
}

export function UpdateAppointmentStatusByID(data) {
  data.setLoading(true);
  console.log("Appointment Status Data:", data);
  const Request = fetch(
    ROOT_URL + "/api/client/appointments/updateAppointmentStatus",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((responseJson, err) => {
      console.log("dsfdsfdsf>>", responseJson);
      data.setLoading(false);
      if (responseJson.AppointmentStatusUpdate === "failure") {
        console.log("APPOINTMENT UPDATE FAILED", responseJson);

        store.dispatch({
          type: appointments_Actions.updateAppointmentStatus.FAILURE,
          payload: { error: "Server response error please try again !" },
        });
        return;
      } else if (
        responseJson.MS_getAppointmentsByPracticeStatus === "success"
      ) {
        console.log("APPOINTMENT UPDATED SUCCESSFULLy", responseJson);
        data.setStatus(data.newStatus);
        console.log(
          "appointmnetGetStatusNo2.....:",
          data.newStatus,
          responseJson
        );
        store.dispatch({
          type: appointments_Actions.updateAppointmentStatus.SUCCESS,
          payload: responseJson.appointmentList,
        });

        return;
      }
    })
    .catch((error) => {
      console.log("APPOINTMENT UPDATE ERROR", error);
      data.setLoading(false);
      store.dispatch({
        type: appointments_Actions.updateAppointmentStatus.FAILURE,
        payload: error,
      });
    });

  return {
    type: appointments_Actions.updateAppointmentStatus.POST,
    payload: "none",
  };
}

//................................
export function UpdateAppointmentDoctor(data) {
  console.log("DOCTOR Status Data:", data);
  data.setLoading(true);
  const newData = {
    AppointmentID: data.AppointmentID,
    appointmentDate: data.appointmentDate,
    appointmentTime: data.appointmentTime,
    seenByDoctor: "",
    doctorID: data.doctorID,
  };
  const Request = fetch(
    ROOT_URL + "/api/client/appointments/updateDoctorForAppointment",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(newData),
    }
  )
    .then((response) => response.json())
    .then((responseJson, err) => {
      console.log("DOCTOR UPDATE DAta", responseJson);
      data.setLoading(false);
      if (responseJson.status === "error") {
        console.log("DOCTOR UPDATE FAILED", responseJson);
        data.setStatus("First available provider");
        store.dispatch({
          type: appointments_Actions.updateDoctorStatus.FAILURE,
          payload: { error: "Server response error please try again !" },
        });
        return;
      } else if (responseJson.status === "success") {
        console.log("DOCTOR UPDATED SUCCESSFULLY", responseJson);
        data.setStatus(data.newStatus);
        console.log(
          "appointmnetGetStatusNo2.....:",
          data.newStatus,
          responseJson
        );
        store.dispatch({
          type: appointments_Actions.updateDoctorStatus.SUCCESS,
          payload: responseJson.doctorList,
        });

        return;
      }
    })
    .catch((error) => {
      console.log("DOCTOR UPDATE ERROR", error);
      data.setLoading(false);
      data.setStatus("First available provider");
      store.dispatch({
        type: appointments_Actions.updateDoctorStatus.FAILURE,
        payload: error,
      });
    });

  return {
    type: appointments_Actions.updateDoctorStatus.POST,
    payload: "none",
  };
}
//................................

export function getAllInsurancesSuperAdmin() {
  const request = fetch(ROOT_URL + "/api/admin/insurances/getAllInsurances", {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    mode: "cors",
  }).then((response) => {
    console.log("********", response);
    response.json().then((data) => {
      console.log("data:......" + data.MS_getAllInsuranceStatus);
      if (data.MS_getAllInsuranceStatus === "failure") {
        store.dispatch({
          type: appointments_Actions.getAllInsurances.FAILURE,
          payload: data,
        });
        return;
      } else {
        store.dispatch({
          type: appointments_Actions.getAllInsurances.SUCCESS,
          payload: data,
        });
        return;
      }
    });
  });
  return { type: appointments_Actions.getAllInsurances.GET, payload: "none" };
}

export function getAllInsurances(practiceID, Token) {
  console.log("SDfdsfsdfds", practiceID, Token);
  let Body = {
    practiceID: practiceID,
  };

  const request = fetch(
    ROOT_URL + "/api/admin/insurances/getAllInsurancesByPracticeID",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: JSON.stringify(),
      },
      body: JSON.stringify(Body),
    }
  ).then((response) => {
    console.log("********Insurance", response);
    response.json().then((data) => {
      console.log("data:......Insurance" + data);
      if (data.MS_getAllInsuranceByPracticeStatus == "failure") {
        store.dispatch({
          type: appointments_Actions.getAllInsurances.FAILURE,
          payload: data,
        });
        return;
      } else {
        store.dispatch({
          type: appointments_Actions.getAllInsurances.SUCCESS,
          payload: data,
        });
        return;
      }
    });
  });
  return { type: appointments_Actions.getAllInsurances.GET, payload: "none" };
}

export function getAllAppointments() {
  const request = fetch(
    ROOT_URL + "/api/client/appointments/getAllAppointments",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      mode: "cors",
    }
  ).then((response) => {
    console.log("********", response);
    response.json().then((data) => {
      console.log("data:......" + data.MS_getAllAppointmentStatus);
      if (data.MS_getAllAppointmentStatus === "failure") {
        store.dispatch({
          type: appointments_Actions.getAllAppointments.FAILURE,
          payload: data,
        });
        return;
      } else {
        store.dispatch({
          type: appointments_Actions.getAllAppointments.SUCCESS,
          payload: data,
        });
        return;
      }
    });
  });
  return { type: appointments_Actions.getAllAppointments.GET, payload: [] };
}

export function submitAppointment(appointment, caotchvalue) {
  console.log("in server for adding appointment");
  console.log("Appointment DAta", appointment);

  // const getRequest = fetch(
  //   ROOT_URL + "/api/client/appointments/submitAppointment",
  //   {
  //     method: "Post",
  //     headers: { "Content-Type": "application/json" },
  //     mode: "cors",
  //     body: JSON.stringify(appointment),
  //   }
  // );

  Axios.post(
    ROOT_URL + "/api/client/appointments/submitAppointment",
    appointment,
    {
      headers: {
        Authorization: caotchvalue,
      },
    }
  ).then((data) => {
    console.log("dsfndsfkjdnsfjkndsjkfnds", data.data);

    if (data.data.MS_AppointmentCreationStatus === "failure") {
      store.dispatch({
        type: appointments_Actions.submitAppointment.FAILURE,
        payload: "Server response error please try again !",
      });
      return;
    } else if (data.data.MS_AppointmentCreationStatus === "success") {
      // console.log("APPOINTMENT CREATED SUCCESSFULLy",data)
      store.dispatch({
        type: appointments_Actions.submitAppointment.SUCCESS,
        payload: data.data,
      });

      return;
    }
  }).catch((e)=>{
    console.log("eeeeeeeeeErrror",e);
    store.dispatch({
      type: appointments_Actions.submitAppointment.FAILURE,
      payload: "Server response error please try again !",
    });
  });

  return { type: appointments_Actions.submitAppointment.POST, payload: "none" };
}

export function Submitvitinaryform(vitinaryAppoinmentData) {
  console.log(vitinaryAppoinmentData);

  const getRequest = fetch(
    ROOT_URL + "/api/client/appointments/submitAppointment",
    {
      method: "Post",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(vitinaryAppoinmentData),
    }
  ).then(function (response) {
    if (response.state >= 400) {
      throw new Error("Bad response from server");
    }
    return response.json().then((data) => {
      if (data.MS_AppointmentCreationStatus === "failure") {
        store.dispatch({
          type: appointments_Actions.submitAppointment.FAILURE,
          payload: "Server response error please try again !",
        });
        return;
      } else if (data.MS_AppointmentCreationStatus === "success") {
        // console.log("APPOINTMENT CREATED SUCCESSFULLy",data)
        store.dispatch({
          type: appointments_Actions.submitAppointment.SUCCESS,
          payload: data,
        });

        return;
      }
    });
  });
}

export function getAllDoctorsByPracticeID(practiceID) {
  console.log("getting all doctors by practiceID ", practiceID);
  const request = fetch(
    ROOT_URL + "/api/public/doctors/getDoctorsByPracticeID/" + practiceID,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      mode: "cors",
    }
  )
    .then((response) => {
      console.log("********", response);
      response.json().then((data) => {
        console.log("data:...... get Doctors", data);
        if (data.MS_getAllDoctorsbyPracticeStatus == "failure") {
          store.dispatch({
            type: appointments_Actions.getAllDoctorsByPractice.FAILURE,
            payload: data,
          });
          return;
        } else {
          store.dispatch({
            type: appointments_Actions.getAllDoctorsByPractice.SUCCESS,
            payload: data,
          });
          return;
        }
      });
    })
    .catch(function (error) {
      store.dispatch({
        type: appointments_Actions.getAllDoctorsByPractice.FAILURE,
        payload: { error: "Error! Failed to get doctors list" },
      });
      return;
    });
  return {
    type: appointments_Actions.getAllDoctorsByPractice.GET,
    payload: [],
  };
}

export function getAllVitinaryDoctorsByPracticeID(practiceID) {
  console.log("getting all doctors by practiceID ", practiceID);
  const request = fetch(
    ROOT_URL +
      "/api/public/doctors/getVeterinaryDoctorsbyPracticeID/" +
      practiceID,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      mode: "cors",
    }
  )
    .then((response) => {
      console.log("********", response);
      response.json().then((data) => {
        console.log("data:...... get Doctors", data);
        if (data.MS_getAllDoctorsbyPracticeStatus == "failure") {
          store.dispatch({
            type: appointments_Actions.getAllDoctorsByPractice.FAILURE,
            payload: data,
          });
          return;
        } else {
          store.dispatch({
            type: appointments_Actions.getAllDoctorsByPractice.SUCCESS,
            payload: data,
          });
          return;
        }
      });
    })
    .catch(function (error) {
      store.dispatch({
        type: appointments_Actions.getAllDoctorsByPractice.FAILURE,
        payload: { error: "Error! Failed to get doctors list" },
      });
      return;
    });
  return {
    type: appointments_Actions.getAllDoctorsByPractice.GET,
    payload: [],
  };
}

export function getAllAppointmentsByPracticeID(
  practiceID,
  token,
  page,
  limit,
  status
) {
  //store.dispatch({ type: appointments_Actions.getAllAppointmentsByPractice.GET, payload: [] });
  // const request = fetch(
  //   ROOT_URL +
  //     "/api/client/appointments/getAllAppointmentsByPractice/" +
  //     practiceID,
  //   {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  //       Authorization: JSON.stringify(token),
  //     },
  //     mode: "cors",
  //   }
  // )
  // .then((response) => response.json())
  // .then((response) => {
  //   console.log("********", response);
  //   const newRes = response.json()
  //   response.json()
  console.log("dsfdsfdsfds", practiceID, token, page, limit, status);
  Axios.post(
    ROOT_URL + "/api/client/appointments/getAllAppointmentsByPractice",
    {
      practiceID: practiceID,
      page: page,
      limit: limit,
      statusList: status,
    },
    {
      headers: {
        Authorization: JSON.stringify(token),
      },
    }
  )
    .then((data) => {
      console.log(
        "data:......>>" + data.data.MS_getAllAppointmentStatus,
        data.data
      );
      if (data.data.MS_getAllAppointmentStatus == "failure") {
        store.dispatch({
          type: appointments_Actions.getAllAppointmentsByPractice.FAILURE,
          payload: data.data,
        });
        return;
      } else {
        store.dispatch({
          type: appointments_Actions.getAllAppointmentsByPractice.SUCCESS,
          payload: data.data,
        });
        return;
      }
    })
    // })
    .catch((error) => {
      console.log("APPOINTMENT GET ERROR", error);

      store.dispatch({
        type: appointments_Actions.getAllAppointmentsByPractice.FAILURE,
        payload: error,
      });
    });
  return {
    type: appointments_Actions.getAllAppointmentsByPractice.GET,
    payload: [],
  };
}

//.................................
export function getDoctorsByPracticeID(practiceID, token) {
  //store.dispatch({ type: appointments_Actions.getAllAppointmentsByPractice.GET, payload: [] });
  const request = fetch(
    ROOT_URL + "/api/admin/getDoctorsListbyPracticeID/" + practiceID,
    // ROOT_URL + "/api/public/doctors/getDoctorsByPracticeID/" + practiceID,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: JSON.stringify(token),
      },
      mode: "cors",
    }
  )
    .then((response) => {
      console.log("DoctorResponse********", response);
      response.json().then((data) => {
        console.log(
          "DoctorResponse********data:......" + data.MS_getAllAppointmentStatus,
          data
        );
        if (data.MS_getAllAppointmentStatus == "failure") {
          store.dispatch({
            type: appointments_Actions.getDoctorsByPracticeID.FAILURE,
            payload: data,
          });
          return;
        } else {
          store.dispatch({
            type: appointments_Actions.getDoctorsByPracticeID.SUCCESS,
            payload: data,
          });
          return;
        }
      });
    })
    .catch((error) => {
      console.log("APPOINTMENT GET ERROR", error);

      store.dispatch({
        type: appointments_Actions.getDoctorsByPracticeID.FAILURE,
        payload: error,
      });
    });
  return {
    type: appointments_Actions.getDoctorsByPracticeID.GET,
    payload: [],
  };
}
//.................................

export function DoctorAvailability(data, doctorID) {
  console.log("here is doctor data", data, doctorID);
  let doctordata = {
    doctorID: doctorID,
    date: data,
  };
  const request = fetch(
    ROOT_URL + "/api/client/appointments/doctoravailabilty",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(doctordata),
    }
  ).then((response) => {
    console.log("********", response);
    response.json().then((data) => {
      console.log("data:......" + data);
      if (data.status == "failure") {
        store.dispatch({
          type: appointments_Actions.doctorAvailability.FAILURE,
          payload: data,
        });
        return;
      } else {
        store.dispatch({
          type: appointments_Actions.doctorAvailability.DOCTOR_AVAILABLE,
          payload: data,
        });
        return;
      }
    });
  });
  return { type: appointments_Actions.doctorAvailability.POST };
}
