import { CheckCircleOutline } from "@material-ui/icons";
// import { CheckCircleOutline } from '@mui/icons-material';
import React, { Component } from 'react';

import '../../../css/components/_Success_new.scss';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import circleImage from '../../../images/tick_circle.png';
import appStoreImage from '../../../images/google-link.png';
import androidStoreImage from '../../../images/android-link.png';
import { Button } from '@mui/material';

class Success extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.scrollToBottom();
  }
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };
  render() {
    const { img, status, status1, btnText, color, onClick, requestCallId ,requestAppointmentId} =
      this.props;

    return (
      <>
        {requestAppointmentId == false ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3} xl={3}></Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div className='mobile-responsive'>
                  <div className='suc-container-new'>
                    <div
                      style={{ float: 'left', clear: 'both' }}
                      ref={(el) => {
                        this.messagesEnd = el;
                      }}
                    ></div>
                    <div className='suc-imgContainer-new'>
                      {/* <CheckCircleOutline className='suc-img-new' /> */}
                      <img src={circleImage} alt='not found' />
                    </div>
                    <h3 className='suc-h1-new'>{status1}</h3>
                    <p className='suc-p1-new mobile'>
                      To keep track of your appointment’s progress and receive
                      your reports later on, download Zebra Doctor mobile app
                    </p>

                    <p className='suc-p1-new'>
                      To keep track of your appointment’s
                    </p>
                    <p className='suc-p1-new process'>
                      progress and receive your reports later&nbsp;on,
                    </p>

                    <p className='suc-p1-new download'>
                      download Zebra Doctor mobile app
                    </p>

                    <div className='sign-up-app mobile-responsive'>
                      Note: Sign up on the app with the same email with which
                      you booked your appointment
                    </div>

                    <div className='sign-up-app'>
                      Note: Sign up on the app with the same email
                    </div>
                    <div className='sign-up-app appointment'>
                      with which you booked your appointment
                    </div>

                    <div className='get-app'>Get the app</div>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.medicalstackpatientend'
                      target='_blank'
                    >
                      <img src={appStoreImage} alt='not found' />
                    </a>
                    <a
                      href='https://apps.apple.com/pk/app/zebra-doctor/id1463154670'
                      target='_blank'
                    >
                      <img src={androidStoreImage} alt='not found' />
                    </a>
                    <div className='button-appointment-request'>
                      <Button
                        variant='outlined'
                        size='large'
                        // className='suc-btn-new'
                        onClick={onClick}
                      >
                        {btnText}
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3} lg={3} xl={3}></Grid>
            </Grid>
          </Box>
        ) : (
          ''
        )}

        {/*  */}
        {requestCallId == false ? (
          <div className='suc-container-new'>
            <div
              style={{ float: 'left', clear: 'both' }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            ></div>
            <div className='suc-imgContainer-new'>
              <CheckCircleOutline className='suc-img-new' />
            </div>
            <h3 className='suc-h1-new'>{status1}</h3>
            <p className='suc-p1-new' style={{ color: color }}>
              {status}
            </p>
            <button className='suc-btn-new' onClick={onClick}>
              {btnText}
            </button>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default Success;
