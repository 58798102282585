import {appointments_Actions} from '../constants/Appointments'
import {appointments_Status} from '../constants/Appointments'
 const appointmentsSubmission_initialState = {
    post_appointments_status: appointments_Status.submitAppointment.NEW,
    appointment:{}
  };

   const post_appointments_Reducer= function(state = appointmentsSubmission_initialState, action) {
   console.log(action.type);
    switch(action.type) {
      case appointments_Actions.submitAppointment.NEW:
        console.log("I am from Reduce NEW..");
        return { ...state, post_appointments_status: appointments_Status.submitAppointment.NEW};
      case appointments_Actions.submitAppointment.POST:
        console.log("I am from Reduce Loading..");
        return { ...state, post_appointments_status: appointments_Status.submitAppointment.LOADING};
      case appointments_Actions.submitAppointment.SUCCESS:
         console.log("I am from Reducer get..",action.payload);
        return {...state, post_appointments_status: appointments_Status.submitAppointment.SUCCESS, appointment: action.payload.appointment}
      case appointments_Actions.submitAppointment.FAILURE:
         console.log("I am from Reduce Failure..");
        return {...state, post_appointments_status: appointments_Status.submitAppointment.FAILURE, error: action.payload.error}
        
      default:
      console.log("default is firing")

        return {...state};

    }
  };
  export default post_appointments_Reducer;
