// import { Checkbox, FormControlLabel } from "@material-ui/core";
// import Skeleton from "@material-ui/lab/Skeleton";

import { Checkbox, FormControlLabel, Box } from "@mui/material";
import Skeleton from "@mui/lab/Skeleton";

import Axios from "axios";
import React, { Component } from "react";
import { ROOT_URL } from "../../../constants/config";
import vaccine from "../../../images/vaccine1.png";
import "./CheckBoxes.scss";
export default class CheckBoxesForTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Pcr: false,
      CovidRapidTest: false,
      other: false,
      AntiBody: false,
      CovidVaccinne: false,
      VaccinationLink: "",
      VaccinationLinkLoading: false,
      adoptedForm: true,
      PopularReason: "",
      SameDayRT_PCR: false,
    };
  }
  handelPcrChange = () => {
    this.setState(
      {
        Pcr: !this.state.Pcr,
      },
      () => {
        if (this.props.other) {
          this.props.handelOtherValue();
        }
        this.props.handelPCRTestValue();
      }
    );
  };

  handlePopularReasoning = (e,item) => {
    console.log("handlePopularReasonin1g",e);
    this.setState(
      {
        PopularReason:this.state.PopularReason==e?"":e,
      },
      () => {
        // if (this.props.other) {
        //   this.props.handelOtherValue();
        // }
        this.props.handlePopularReasoning(e,item);
      }
    );
  };
  handelCovidRapidTestChange = () => {
    this.setState(
      {
        CovidRapidTest: !this.state.CovidRapidTest,
      },
      () => {
        if (this.props.other) {
          this.props.handelOtherValue();
        }
        this.props.handelCovidRapidTestValue();
      }
    );
  };

  handelSameDayRT_PCR = () => {
    this.setState(
      {
        SameDayRT_PCR: !this.state.SameDayRT_PCR,
      },
      () => {
        if (this.props.other) {
          this.props.handelOtherValue();
        }
        this.props.handelSameDayRT_PCR();
      }
    );
  };

  handelOtherChange = () => {
    this.setState(
      {
        other: !this.state.other,
      },
      () => {
        if (this.props.Pcr) {
          this.props.handelPCRTestValue();
        }
        if (this.props.CovidRapidTest) {
          this.props.handelCovidRapidTestValue();
        }
        if (this.props.AntiBody) {
          this.props.handelAntiBodyValue();
        }
        this.props.handelOtherValue();
      }
    );
  };
  handelAntibodyTestChange = () => {
    this.setState(
      {
        AntiBody: !this.state.AntiBody,
      },
      () => {
        if (this.props.other) {
          this.props.handelOtherValue();
        }
        this.props.handelAntiBodyValue();
      }
    );
  };

  handelCovidVaccinneChange = () => {
    this.setState(
      {
        CovidVaccinne: !this.state.CovidVaccinne,
      },
      () => {
        this.props.handelCovidVaccinnevalue();
      }
    );
  };
  handelVaccinationForm = () => {
    this.setState({
      VaccinationLinkLoading: true,
    });
    let Body = {
      practiceLocationID: this.props.practiceLocationID,
      practiceID: this.props.practice._id,
    };
    Axios.post(
      `${ROOT_URL}/api/practiceAdmin/generateLinkforVaccinationForm`,
      Body
    ).then((res) => {
      console.log("====================================");
      console.log("handelVaccinationForm", res.data);
      console.log("====================================");
      this.setState({
        adoptedForm: res.data.adoptedForm,
        VaccinationLink: res.data.vaccinationFormLink,
        VaccinationLinkLoading: false,
      });
    });
  };
  componentDidUpdate(prevprops) {
    if (prevprops.practiceLocationID !== this.props.practiceLocationID) {
      this.setState(
        {
          VaccinationLink: "",
        },
        () => {
          this.handelVaccinationForm();
        }
      );
    }
  }
  componentDidMount() {
    this.handelVaccinationForm();
  }
  render() {
    console.log("dsfdsfdsf", this.props.PopularReasons,this.props.PopularReason_app);

    return (
      <div>
        <div className="CheckBoxes">
          {this.state.VaccinationLinkLoading ? (
            <Skeleton style={{ height: 30, width: 150 }} animation="wave" />
          ) : this.state.adoptedForm ? (
            <div className="Vaccination">
              {/* <img src={vaccine} alt="" /> */}
              <a href={this.state.VaccinationLink} target="_black">
                Covid-19 Vaccination
              </a>
            </div>
          ) : null}
          <Box className="vacination_inner">
            {this.props.PopularReasons.map((item, index) => (
              <FormControlLabel
                // disabled={
                //   this.props.reasonForVisit.length !== 0 &&
                //     this.props.reasonForVisit !== ""
                //     ? true
                //     : false
                // }
                control={
                  <Checkbox
                    checked={this.state.PopularReason==item.reason?true:false}
                    onChange={(e)=>this.state.PopularReason!==""?this.handlePopularReasoning(this.state.PopularReason,{}):this.handlePopularReasoning(e.target.name,item)}
                    name={item.reason}
                  />
                }
                label={item.reason}
              />
            ))}

            {/* <FormControlLabel
              disabled={
                this.props.reasonForVisit.length !== 0 &&
                  this.props.reasonForVisit !== ""
                  ? true
                  : false
              }
              control={
                <Checkbox
                  checked={this.props.Pcr}
                  onChange={this.handelPcrChange}
                  name="Covid-19 PCR Test"
                />
              }
              label="COVID-19 RT-PCR Test - 48-72 Hrs"
            />
            <FormControlLabel
              disabled={
                this.props.reasonForVisit.length !== 0 &&
                  this.props.reasonForVisit !== ""
                  ? true
                  : false
              }
              control={
                <Checkbox
                  checked={this.props.CovidRapidTest}
                  onChange={this.handelCovidRapidTestChange}
                  name="COVID-19 Rapid Antigen Test - 1- 3 Hrs"
                />
              }
              label="COVID-19 Rapid Antigen Test - 1- 3 Hrs"
            />
            <FormControlLabel
              disabled={
                this.props.reasonForVisit.length !== 0 &&
                  this.props.reasonForVisit !== ""
                  ? true
                  : false
              }
              control={
                <Checkbox
                  checked={this.props.AntiBody}
                  onChange={this.handelAntibodyTestChange}
                  name="Covid-19 AntiBody Test"
                />
              }
              label="COVID-19 Antibody Test"
            /> */}
          </Box>

        </div>
      </div>
    );
  }
}
