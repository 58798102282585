import {
  practice_Actions,
  create_practice_request_Actions,
} from "../constants/Practices";
import store from "../store/index";
import { ROOT_URL, PracticeIconName } from "../constants/config";
import { practiceAccount_Actions } from "../constants/PracticeAccount";
export const PracticeServer = {
  createPractice: createPractice,
  getPracticeByName: getPracticeByName,
  updatePracticeByID: updatePracticeByID,
  updatePracticeDetailsByID: updatePracticeDetailsByID,
  LocationNumberAuth: LocationNumberAuth,
  createPracticeRequest: createPracticeRequest,
  DoctorAval: DoctorAval,
  // updatePracticeByID_ga:updatePracticeByID_ga,
  // updatePracticeByID_twilio:updatePracticeByID_twilio
};

export function updatePracticeDetailsByID(practice) {
  console.log("updatedPractice:", practice);
  if (practice.practiceLogoFile) {
    var formData = new FormData();
    if (typeof practice.practiceLogoFile === "object")
      formData.append("photo", practice.practiceLogoFile);

    const postRequest = fetch(ROOT_URL + "/uploadFile", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then(function (response) {
        return response.json().then((data) => {
          console.log("data from database:", data);
          if (data.status >= 400) {
            store.dispatch({
              type: practiceAccount_Actions.PracticeDetails.FAILURE,
              payload: { error: "Server response error please try again !" },
            });
          } else {
            console.log("file image data", data);

            practice.practiceLogo = data.location;

            delete practice.practiceLogoFile;
            updatePracticeDetails(practice);
          }
        });
      })
      .catch((error) => {
        console.log("PracticeDetails UPDATE ERROR", error);

        store.dispatch({
          type: practiceAccount_Actions.PracticeDetails.FAILURE,
          payload: { error: "network request failed! Try again" },
        });
      });
  } else {
    updatePracticeDetails(practice);
  }
  // updatePracticeDetails(practice)
  return {
    type: practiceAccount_Actions.PracticeDetails.UPDATE,
    payload: "none",
  };
}

function updatePracticeDetails(practice) {
  const id = practice.practiceId;
  delete practice.practiceId;

  const Request = fetch(
    ROOT_URL + "/api/admin/practices/updatePracticeDetailsById/" + id,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(practice),
    }
  )
    .then((response) => response.json())
    .then((responseJson, err) => {
      if (responseJson.PracticeDetailsUpdate === "failure") {
        console.log("PracticeDetails UPDATE FAILED", responseJson);
        var message = responseJson.err;
        if (message.errmsg) message = message.errmsg;
        store.dispatch({
          type: practiceAccount_Actions.PracticeDetails.FAILURE,
          payload: { error: message },
        });
        return;
      } else if (responseJson.PracticeDetailsUpdate === "success") {
        console.log("PracticeDetails UPDATED SUCCESSFULLy", responseJson);

        store.dispatch({
          type: practiceAccount_Actions.PracticeDetails.SUCCESS,
          payload: responseJson.practice,
        });

        return;
      }
    })
    .catch((error) => {
      console.log("PracticeDetails UPDATE ERROR", error);

      store.dispatch({
        type: practiceAccount_Actions.PracticeDetails.FAILURE,
        payload: { error: "network request failed! Try again" },
      });
    });
}
export function createPractice(practice) {
  var formData = new FormData();
  console.log(typeof practice.practiceLogo);
  if (typeof practice.practiceLogo === "object") {
    formData.append("photo", practice.practiceLogo);
    console.log(typeof practice.practiceLogo, practice.practiceLogo);
    const postRequest = fetch(ROOT_URL + "/uploadFile", {
      method: "POST",
      mode: "cors",
      body: formData,
    }).then(function (response) {
      response.json().then((data) => {
        console.log("data from database:", data);
        if (data.status >= 400) {
          store.dispatch({
            type: practice_Actions.createPractice.FAILURE,
            payload: data,
          });
          return;
        } else {
          //image posted on server now update practice detail
          if (data.updateStatus !== "empty") {
            practice.practiceLogo = ROOT_URL + "/" + data.filename;
          }
          CreatepracticeData(practice);
          return;
        }
      });
    });
  } else {
    practice.practiceLogo = ROOT_URL + "/" + PracticeIconName;
    CreatepracticeData(practice);
  }

  return { type: practice_Actions.createPractice.POST, payload: "none" };
}

export function CreatepracticeData(practice) {
  const getRequest = fetch(ROOT_URL + "/api/admin/practices/createPractice", {
    method: "Post",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    body: JSON.stringify(practice),
  }).then(function (response) {
    console.log("Response from database: ", response);
    if (response.state >= 400) {
      throw new Error("Bad response from server");
    }
    return response.json().then((data) => {
      if (data.MS_practiceCreationStatus == "failure") {
        store.dispatch({
          type: practice_Actions.createPractice.FAILURE,
          payload: "Server response error please try again !",
        });
        return;
      } else if (data.MS_practiceCreationStatus == "success") {
        store.dispatch({
          type: practice_Actions.createPractice.SUCCESS,
          payload: data,
        });
        return;
      } else if (data.MS_practiceCreationStatus == "DuplicateEmail") {
        store.dispatch({
          type: practice_Actions.createPractice.DUPLICATEEMAIL,
          payload: data,
        });
        return;
      } else if (data.MS_practiceCreationStatus == "DuplicateName") {
        store.dispatch({
          type: practice_Actions.createPractice.DUPLICATENAME,
          payload: data,
        });
        return;
      }
    });
  });
}

export function createPracticeRequest(practice) {
  const getRequest = fetch(
    ROOT_URL + "/api/admin/callrequests/createPracticeRequest",
    {
      method: "Post",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(practice),
    }
  ).then(function (response) {
    console.log("createPracticeRequestesponse from database: ", response);
    if (response.state >= 400) {
      throw new Error("Bad response from server");
    }
    return response.json().then((data) => {
      console.log("createPracticeRequest....", data);
      if (data.PracticeRequestCreationStatus == "failure") {
        store.dispatch({
          type: create_practice_request_Actions.createPracticeRequest.FAILURE,
          payload: "Server response error please try again !",
        });
        return;
      } else if (data.PracticeRequestCreationStatus == "success") {
        store.dispatch({
          type: create_practice_request_Actions.createPracticeRequest.SUCCESS,
          payload: data,
        });
        return;
      } else if (data.PracticeRequestCreationStatus == "Duplicate data") {
        store.dispatch({
          type:
            create_practice_request_Actions.createPracticeRequest
              .DUPLICATEPHONE,
          payload: data,
        });
        return;
      }
    });
  });
}

export function getPracticeByName(practiceName) {
  //  alert(practiceName)
  console.log("getting practice by practiceName ", practiceName);
  const request = fetch(
    ROOT_URL + "/api/admin/practices/getPracticeByName/" + practiceName,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      mode: "cors",
    }
  )
    .then((response) => {
      console.log("********", response);
      response.json().then((data) => {
        console.log("Practice data:......", data);
        if (data.MS_getPracticeByNameStatus == "failure") {
          store.dispatch({
            type: practice_Actions.getPracticeByName.FAILURE,
            payload: data,
          });
          return;
        } else {
          store.dispatch({
            type: practice_Actions.getPracticeByName.SUCCESS,
            payload: data,
          });
          return;
        }
      });
    })
    .catch((error) => {
      store.dispatch({
        type: practice_Actions.getPracticeByName.FAILURE,
        payload: error,
      });
    });
  return { type: practice_Actions.getPracticeByName.GET, payload: [] };
}

export function DoctorAval(data) {}

//update gtmCode
export function updatePracticeByID(practice, id, cookies) {
  console.log("getting practice by id ", practice, id);
  const request = fetch(
    ROOT_URL + "/api/admin/practices/updatePracticeById/" + id,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(practice),
      mode: "cors",
    }
  )
    .then((response) => {
      console.log("********", response);
      response.json().then((data) => {
        console.log("data:......" + data.status);
        console.log("getting practice by id data:......", data);
        if (data.status == "failure") {
          store.dispatch({
            type: practice_Actions.updatePracticeById.FAILURE,
            payload: data,
          });
          return;
        } else if (data.status == "success") {
          // cookies.set("practiceAccount", data.practice, {
          //   path: "/",
          // });
          store.dispatch({
            type: practice_Actions.updatePracticeById.SUCCESS,
            payload: data,
          });
          return;
        }
      });
    })
    .catch(() => {
      return { type: practice_Actions.updatePracticeById.FAILURE };
    });
  console.log(
    "here is loading status",
    practice_Actions.updatePracticeById.POST
  );
  return { type: practice_Actions.updatePracticeById.POST };
}

//here we will check the uniqueness of location number again every location
export function LocationNumberAuth(locationNum) {
  console.log("here is number", locationNum);
  var body = { locationNumber: locationNum };
  const request = fetch(ROOT_URL + "/api/admin/practices/locationNumAuth", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    mode: "cors",
  })
    .then((response) => {
      console.log("********", response);
      response.json().then((data) => {
        console.log("data:......", data);
        if (data.status == "faliure") {
          store.dispatch({
            type: practiceAccount_Actions.LocationNumAuth.FAILURE,
          });
          return;
        } else if (data.status == "success") {
          store.dispatch({
            type: practiceAccount_Actions.LocationNumAuth.SUCCESS,
          });
          return;
        }
      });
    })
    .catch(() => {
      return { type: practiceAccount_Actions.LocationNumAuth.FAILURE };
    });
  return { type: practiceAccount_Actions.LocationNumAuth.POST };
}
