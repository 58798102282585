import { appointments_Actions } from "../constants/Appointments";
import { appointments_Status } from "../constants/Appointments";
import { doctors_Actions } from "../constants/Doctors";
import { doctors_Status } from "../constants/Doctors";

const getDoctorsByPractice_initialState = {
  doctors_get_status: appointments_Status.getAllDoctorsByPractice.NEW,
  doctors: [],
  TotalCount: null,
};

export const doctorsByPractice_Get_Reducer = function (
  state = getDoctorsByPractice_initialState,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case appointments_Actions.getAllDoctorsByPractice.GET:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        doctors_get_status: appointments_Status.getAllDoctorsByPractice.LOADING,
      };
    case appointments_Actions.getAllDoctorsByPractice.SUCCESS:
      console.log("I am from Reducer get..", action.payload);

      return {
        ...state,
        doctors_get_status: appointments_Status.getAllDoctorsByPractice.SUCCESS,
        doctors: action.payload.doctorsList,
        TotalCount: action.payload?.PaginationData?.totalDocs,
      };
    case appointments_Actions.getAllDoctorsByPractice.FAILURE:
      console.log("I am from Reduce Failure..");
      return {
        ...state,
        doctors_get_status: appointments_Status.getAllDoctorsByPractice.FAILURE,
        error: action.payload.error,
      };

    default:
      console.log("default is firing");

      return { ...state };
  }
};

const doctorAvailabilty_initialState = {
  doctor_available_status: appointments_Status.doctorAvailability.NEW,
  time: [],
};

export const doctorAvailabilty_Reducer = function (
  state = doctorAvailabilty_initialState,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case appointments_Actions.doctorAvailability.NEW:
      console.log("I am from Reduce New..");
      return {
        ...state,
        doctor_available_status: appointments_Status.doctorAvailability.NEW,
      };
    case appointments_Actions.doctorAvailability.POST:
      console.log("I am from Reduce post..");
      return {
        ...state,
        doctor_available_status: appointments_Status.doctorAvailability.POST,
      };
    case appointments_Actions.doctorAvailability.DOCTOR_AVAILABLE:
      console.log("I am from Reduce DATE_AVAILABLE..");
      return {
        ...state,
        doctor_available_status:
          appointments_Status.doctorAvailability.DOCTOR_AVAILABLE,
        time: action.payload.appointmentList,
      };
    case appointments_Actions.doctorAvailability.FAILURE:
      console.log("I am from Reduce FAILURE..");
      return {
        ...state,
        doctor_available_status: appointments_Status.doctorAvailability.FAILURE,
      };

    default:
      console.log("default is firing");

      return { ...state };
  }
};

const doctorAppointment_initialState = {
  doctor_appointment_status: doctors_Status.getDoctorAppointments.NEW,
  doctor_appointments: [],
};

export const doctorAppointment_Reducer = function (
  state = doctorAppointment_initialState,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case doctors_Actions.getDoctorAppointments.GET:
      console.log("I am from Reduce New..");
      return {
        ...state,
        doctor_appointment_status: doctors_Status.getDoctorAppointments.LOADING,
      };
    case doctors_Actions.getDoctorAppointments.SUCCESS:
      console.log("I am from Reduce post..");
      return {
        ...state,
        doctor_appointment_status: doctors_Status.getDoctorAppointments.SUCCESS,
        doctor_appointments: action.payload.appointmentList,
      };
    case doctors_Actions.getDoctorAppointments.FAILURE:
      console.log("I am from Reduce FAILURE..");
      return {
        ...state,
        doctor_appointment_status: doctors_Status.getDoctorAppointments.FAILURE,
        doctor_appointments: [],
      };

    default:
      console.log("default is firing");

      return {
        ...state,
        doctor_appointment_status: doctors_Status.getDoctorAppointments.NEW,
      };
  }
};

const doctorDeactivation_initialState = {
  doctor_deactivation_status: doctors_Status.deactivateDoctor.NEW,
};

export const doctorDeactivation_Reducer = function (
  state = doctorDeactivation_initialState,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case doctors_Actions.deactivateDoctor.UPDATE:
      console.log("I am from Reduce New..");
      return {
        ...state,
        doctor_deactivation_status: doctors_Status.deactivateDoctor.LOADING,
      };
    case doctors_Actions.deactivateDoctor.SUCCESS:
      console.log("I am from Reduce post..");
      return {
        ...state,
        doctor_deactivation_status: doctors_Status.deactivateDoctor.SUCCESS,
      };
    case doctors_Actions.deactivateDoctor.FAILURE:
      console.log("I am from Reduce FAILURE..");
      return {
        ...state,
        doctor_deactivation_status: doctors_Status.deactivateDoctor.FAILURE,
      };

    default:
      console.log("default is firing");

      return {
        ...state,
        doctor_deactivation_status: doctors_Status.deactivateDoctor.NEW,
      };
  }
};
