import { appointments_Actions } from "../constants/Appointments";
import { appointments_Status } from "../constants/Appointments";
const getAppointments_initialStateStatus = {
  appointment_update_status: appointments_Status.AppointmentStatusUpdate.NEW,
  status: 1,
};

const appointmentStatus_Reducer = function (
  state = getAppointments_initialStateStatus,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case appointments_Actions.updateAppointmentStatus.POST:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        appointment_update_status:
          appointments_Status.AppointmentStatusUpdate.LOADING,
      };
    case appointments_Actions.updateAppointmentStatus.SUCCESS:
      console.log("I am from Reducer SUCCESS..", action.payload);
      return {
        ...state,
        appointment_update_status:
          appointments_Status.AppointmentStatusUpdate.SUCCESS,
      };
    case appointments_Actions.updateAppointmentStatus.FAILURE:
      console.log("I am from Reduce Failure..");
      return {
        ...state,
        appointment_update_status:
          appointments_Status.AppointmentStatusUpdate.FAILURE,
        error: action.payload.error,
      };

    default:
      console.log("default is firing");

      return {
        ...state,
        appointment_update_status:
          appointments_Status.AppointmentStatusUpdate.NEW,
      };
  }
};
export default appointmentStatus_Reducer;
