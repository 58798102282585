import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Success from "../../../images/Group-1599.png";

import "./SuccessModal.scss";
import { propsToClassKey } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.Toggle}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.Toggle}>
          {/* <div className={classes.paper}> */}
          <div className="SuccessModal">
            <img src={Success} alt="" />
            <h1>Success!</h1>
            <p>{props.bodyText}</p>
            <Button
              onClick={() => {
                props.hadnelSuccessModal(false);
                // props.Alldoctror();

                window.location.reload();
              }}
              className="SuccessModal-BTN"
            >
              Okay
            </Button>
          </div>
          {/* <h2 id="transition-modal-title">Transition modal</h2>
            <p id="transition-modal-description">
              react-transition-group animates me.
            </p> */}
          {/* </div> */}
        </Fade>
      </Modal>
    </div>
  );
}
