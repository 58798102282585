import React, { Component } from "react";
import "../../../css/components/_Reason_new.scss";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import ListSubheader from "@material-ui/core/ListSubheader";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default class EligibleCategory extends Component {
  render() {
    return (
      <section style={{ marginTop: 20 }} className="reason-new">
        <section className="r-sec4-new">
          <h3 style={{ fontSize: 18 }} className="Heading">
            Eligible Category
          </h3>
          <a
            target="_blank"
            href="https://www1.nyc.gov/site/doh/covid/covid-19-vaccine-eligibility.page"
          >
            Learn more
          </a>
        </section>
        <div>
          <FormControl
            variant="outlined"
            className="EligibleSelect"
            style={{ marginTop: 20 }}
          >
            <InputLabel
              style={{ background: "white" }}
              id="demo-simple-select-outlined-label"
            >
              Choose the category
            </InputLabel>

            <Select
              value={this.props.EligibleCategoryvalue}
              onChange={(e) => this.props.EligibleCategory(e)}
              placeholder="Choose the category you best belong to"
            >
              <ListSubheader>Phase 1</ListSubheader>
              <MenuItem value="Health care workers">
                Health care workers
              </MenuItem>
              <MenuItem value="Long-term care facility residents">
                Long-term care facility residents
              </MenuItem>
              <ListSubheader>Phase 2 </ListSubheader>
              <MenuItem value="Frontline essential workers">
                Frontline essential workers
              </MenuItem>
              <MenuItem value="People 75 years and older">
                People 75 years and older
              </MenuItem>
              <ListSubheader>Phase 3 </ListSubheader>
              <MenuItem value="People ages 65 to 74 years">
                People ages 65 to 74 years
              </MenuItem>
              <MenuItem value="People ages 16 to 64 years with high-risk medical conditions(not previously recommended for vaccination)">
                People ages 16 to <br /> 64 years with high-risk <br /> medical
                conditions(not previously <br /> recommended for vaccination)
              </MenuItem>
              <ListSubheader>Phase 4 </ListSubheader>
              <MenuItem value="People 16 years and older(not previously recommended for vaccination)">
                People 16 years and older(not <br /> previously recommended{" "}
                <br /> for vaccination)
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </section>
    );
  }
}
