import React, { Component } from "react";
import "../../../css/components/_Reason_new.scss";
//import Autocomplete from "@material-ui/lab/Autocomplete";
import Autocomplete from "@mui/lab/Autocomplete";
//import { TextField } from "@material-ui/core";
import { TextField } from "@mui/material";
class Reason extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewPatient: true,
      reasonForVisit: "Consultation / Sick Visit",
      TypingValue: "",
      SelectValue: "",
      ReasongData: [
        "Abdominal Aortic Aneurysm (AAA) Screening ",
        "Abdominal Pain ",
        "Abnormal Mammogram ",
        "Abscess",
        "Absence of Menstruation / Amenorrhea",
        "Acid Reflux / Heartburn",
        "Acne",
        "Allergic Cough",
        "Allergy Consultation",
        "Ambulatory Care Consultation",
        "Anaphylaxis / Allergy",
        "Anemia",
        "Ankle Pain",
        "Ankle Problems",
        "Annual Check Up ",
        " Annual Pap Smear / GYN Exam",
        "Annual Physical",
        "Anxiety",
        " Arm Pain Arm Problem(s)",
        " Arthritis ",
        " Asthma",
        "Atherosclerosis",
        "Back Pain",
        "Back Problems ",
        "Bad breath/ Halitosis ",
        "Black Stool / Melena",
        "Blisters",
        "Blood in Stool",
        "Blood Pressure Testing",
        "Blood Work",
        "Body Ache / Pain",
        "Bowel and Bladder Management",
        "Breast Cancer High Risk Screening",
        "Breast Cancer Screening",
        "Breast Cyst",
        "Breast Pain",
        "Breathing Problems",
        "Bronchitis",
        "Brown / Dark Spot",
        "Bruise / Contusion",
        "Bunion",
        "Buttock Pain",
        "Cardiovascular Screening Visit",
        "Carpal Tunnel Syndrome",
        "Celiac Disease",
        "Cellulitis",
        "Cerumen Impaction",
        " Chest Pain",
        "Chest X-ray Abnormalities",
        "Cholesterol / Lipids Checkup",
        "Cholesterol Management",
        "Chronic Cough",
        "Chronic Illness",
        "Chronic Obstructive Pulmonary Disease (COPD)",
        "Chronic Pain",
        "Cold",
        "Cold Sores / Herpes Labialis",
        "Colon Cancer",
        "Colon Cancer Screening",
        "Colorectal Cancer Screening",
        "Concussion",
        "Constipation",
        "Coronary Artery Disease",
        "Cough",

        "Crohn's Disease",
        "Daytime Sleepiness",
        "Dehydration",
        "Depression",
        "Diabetes Blood Sugar Management",
        "Diabetes Consultation",
        "Diabetes Follow Up",
        "Diabetic Foot",
        "Diarrhea",
        "Dizziness",
        "Ear Infection",
        "Ear Wax Cleaning",
        "Eating Disorder",
        "ECG / EKG",
        "Echocardiogram",
        "Eczema",
        "Edema",
        "Elbow Pain",
        "Elbow Problems",
        "Electroc ardiogram",
        "Fainting / Syncope",
        "Fertility Awareness / Natural Family Planning",
        "Flu",
        "Flu Shot",
        "Food Allergy",
        "Food Intolerance",
        "Foot Infection",
        "Foot Pain",
        "Foot Problems",
        "Frequent Urination",
        "Fungal Infection",
        "Gall Bladder Problem",
        "Gallstones",
        "Gastritis",
        "Gastroenterology Consultation",
        "Gastroesophageal Reflux Disease (G ERD)",
        "General Consultation",
        "General Follow Up",
        "Gluten Sensitivity",
        "Glycosylated Hemoglobin Test",
        "Gout",
        "Groin Pain",
        "Hair Loss",
        "Hand Problems",

        "Hand, Foot, and Mouth Disease",
        "Head Lice",
        "Headache",
        "Hearing Loss",
        "Hearing Problems / Ringing in Ear",
        "Heart Attack / Cardiac Arrest",
        "Heart Failure",
        "Heart Rhythm Problem/ Palpitations / Arrhythmia",
        "Heavy or Irregular Vaginal Bleeding",
        "Heel Pain",
        "Hemorrhoids",
        "Hepatitis",
        "Hepatitis A Vaccine",
        " Hepatitis B",
        " Hepatitis B Vaccine",
        "Hepatitis C",
        " Hernia",
        " Hiatal Hernia",
        " High Blood Pressure / Hypertension",
        " High Cholesterol / Lipid Problem",
        " Hip Pain",
        "Hip Problems",
        " HIV / AIDS",
        " HIV Pre-Exposure Prophylaxis (PrEP)",
        "Hoarseness / Voice Disorder",
        "Hormone Replacement Therapy Consultation",
        "Hospital Discharge/Follow Up",

        "Hyperactive Disorder (ADD/ADHD)",
        "Hyperlipidemia",
        "Hyperthyroidism / Overactive Thyroid",

        "Hypothyroidism / Underactive Thyroid",

        "Illness",

        "Imaging Follow up",

        "Immobility",

        "Incontinence",

        "Increased Salivation",
        "Infection Follow Up",

        "Injection",

        "Injury / Laceration",

        "Injury/Minor Laceration",

        "Irritable Bowel Syndrome",

        "Itching",

        "Joint Injection",

        "Joint Pain",

        "Joint Problem",
        "Joint Stiffness",

        "Knee Pain",

        "Knee Problems",

        "Leg Cramps",

        "Leg Pain",

        "LGBT Care",

        "Ligament Sprain",

        "Liver Function Test",

        "Lower Back Pain / Lumbago",

        "Lower Extremity Pain",

        "Lower Extremity Swelling",

        "Lung Cancer Screening",

        "Lyme Disease",

        "Measles / Rubeola",

        "Medicare Annual Wellness Visit",

        "Menopause",

        "Menopause Follow Up",

        "Menstrual Problems",

        "Migraine",

        "Muscle Pain",

        "Muscle Strain",

        "Nail Problems",

        "Nausea and vomiting",

        "Nebulizer Treatment",

        "Neck Pain",

        "Neck Problems",

        "Nerve Conduction Studies (NCS)",

        "New Patient Visit",

        "Nipple Discharge",

        "Non-bacterial Lung Infection",

        "Nutrition Consultation",
        "Nutrition Issues",
        "Obesity / Weight Loss Consultation",
        "Osteoarthritis",
        "Osteoporosis",
        "Other",
        "Ovarian Cyst(s)",
        // "PCR Test",
        "Pain in Extremities",
        "Panic Attack",
        "Patient History",
        // "PCR Test",
        "Pelvic Pain",
        "Plantar Fasciitis",
        "Pneumonia",
        "Post-Menopausal Bleeding",
        "Postpartum Depression",
        "Post-Partum Visit",
        "Post-Surgery Checkup",
        "Preconception Counseling",
        "Pre Employment Drug Screening",
        "Pregnancy Follow Up",
        "Pregnancy Test",
        "Prescription / Refill",
        "Pre Sports Evaluation",
        "Pre Sports Evaluation",
        "Pre Surgery Checkup / Presurgical. Clearance ",

        "Pre Travel Checkup",

        "Pre Travel Consultation",

        "Pre Travel Follow Up",

        "Preventive Medicine Consultation",

        "Primary Care Consultation",

        "Prostate Cancer Screening",

        "Psoriasis",

        "Pulmonary Function Testing",

        "Pulmonology Consultation",

        "Quitting Smoking / Smoking Cessation",

        "Rash",

        "Restless Legs Syndrome",

        "Rheumatoid Arthritis",

        "Sciatica",

        "Screening for Disease / Preventive Medicine ",
        "Second Opinion",

        "Seizures / Epilepsy",

        "Senior Visit",

        "Sexual Dysfunction / Painful Sex / Dyspareunia",

        "Sexually Transmitted Disease (STD)",

        "Sexually Transmitted Infection",

        "Shingles",

        "Shortness of Breath / Difficulty in Breathing",

        "Shoulder Pain",

        "Shoulder Problem",

        "Sickle Cell Disease",

        "Sinus Problems / Sinusitis",

        "Sixth Disease / Roseola",

        "Skin Biopsy / Removal of Skin Lesion",

        "Skin Discoloration",

        "Skin Problem",

        "Sleep Problems",

        "Sore Throat",

        "Spirometry",

        "Sports Concussion",

        "Sports Injury",

        "Sports Physical",

        "Stiffness",

        "Stomach Pain",

        "Stomach Ulcer",

        "Stress",

        "Stroke",

        "Stroke Check-up / Prevention",

        "Suture Removal",

        "Swelling",

        "Swelling in Legs",

        "Swelling in Neck",

        "Tetanus Shot",

        "Throbbing",

        "Thyroid Consultation",

        "Tingling / Numbness / Weakness",

        "Tiredness / Fatigue",

        "Transgender Medical Follow Up",

        "Travel Medicine Consultation",

        "Tuberculosis Screening",

        "Type 1 Diabetes",

        "Type 2 Diabetes",

        "Ultrasound",

        "Upper Respiratory Infection",
        "Urgent Care Follow Up",

        "Urinary Tract Infection (UTI)",

        "Vaccine(s)",

        "Vaginal Discharge / Infection",

        "Varicose Vein(s)",

        "Vertigo",

        "Vitamin B12 Deficiency",

        "Vitamin D Deficiency",

        "Wart(s)",

        "Weight Loss Consultation",

        "Weight Management ",

        "Wellness Care",

        "Whiplash Injury",

        "Wound Care",

        "Wrist Pain",

        "Wrist Problems",

        "X-ray",

        "Yearly Wellness Visit (For Medicare Patients)",

        "Others",
      ],
    };
    this.handleIsNewPatientChange = this.handleIsNewPatientChange.bind(this);
    this.handleReasonForVisitChange = this.handleReasonForVisitChange.bind(
      this
    );
  }

  handleIsNewPatientChange() {
    this.setState({ isNewPatient: !this.state.isNewPatient });
    this.props.onNewPatientChange(!this.state.isNewPatient);
  }
  handleReasonForVisitChange(value) {
    console.log("dsValuefsdfsd", value);
    this.setState({ reasonForVisit: value === null ? "" : value }, () => {
      this.props.onReasonForVisitChange(this.state.reasonForVisit);
    });
    // if (value !== "" || Object.keys(value).length !== 0) {
    // }
  }


  render() {
    console.log("newInputValue11", this.props.reasonForVisits);

    return (
      <section className="reason-new">
        <section className="r-sec1-new">
          <span
            aria-hidden={
              this.props.Pcr === true ||
                this.props.AntiBody === true ||
                this.props.CovidRapidTest === true ||
                this.props.CovidVaccinne === true ||
                this.props.CovidPCrRapidte30minChange == true ||
                this.props.SameDayRT_PCR == true
                ? true
                : false
            }
            className="rh1-new"
          >
            If none of above apply, select / add a reason below:
          </span>

          <div className="r-inpContainer-new">
            <Autocomplete
              freeSolo
              aria-label="Reason"
              aria-required={true}
              id="combo-box-demo"
              disablePortal

              disabled={
                Object.keys(this.props.reasonForVisits).length!==0
                  ? true
                  : false
              }
              // value={
              //  this.state.SelectValue === ""
              //     ? this.props.Reasonforvisti
              //     : this.state.SelectValue
              // }
              // this.state.ReasongData
              options={this.props.ReasonsList}
              getOptionLabel={(option) => option?.reason}
              onChange={(event, newValue) => {
                console.log("dsValuefsdfsd1", newValue);
                this.setState({
                  SelectValue: newValue == null ? "" : newValue,
                }, () => {
                  this.handleReasonForVisitChange(this.state.SelectValue);

                });
              }}
              inputValue={
                this.state.TypingValue === ""
                  ? Object.keys(this.props.reasonForVisits).length!==0
                    ? this.props.reasonForVisits.reason:
                    ""
                  : this.state.TypingValue
              }
              // inputValue={
              //   this.state.TypingValue === ""
              //     ? this.props.reasonForVisits !== ""
              //       ? this.props.reasonForVisits
              //       : this.props.Reasonforvisti
              //     : this.state.TypingValue
              // }
              onInputChange={(event, newInputValue) => {
                console.log("dsValuefsdfsd11", newInputValue);

                this.setState(
                  {
                    TypingValue: newInputValue == "undefined" ? "" : newInputValue,
                    // TypingValue: newInputValue == "undefined" ? "" : newInputValue,
                  },
                  () => {
                    this.handleReasonForVisitChange( {reason: newInputValue,reasonid: null});
                  }
                );
              }}
              style={{ width: "100%", padding: 0, margin: 0 }}
              renderInput={(params) => (
                <TextField
                  placeholder="Search or type a reason, condition, symptoms"
                  style={{ width: "100%", padding: 0, margin: 0 }}
                  {...params}
                  label="Reason*"
                  variant="outlined"
                />
              )}
            />
          </div>
        </section>
      </section>
    );
  }
}

export default Reason;
