import React from "react";
import robot from "../images/robot.png";
import "../../css/components/_Error404.scss";
const Error404 = () => {
  return (
    <React.Fragment>
      <main className="er-main">
        <div className="er-div1">
          <p className="er-p1">
            Zebra Doctor <span className="er-sp1">Medical Stack</span>
          </p>
          {/* <p className="er-p2">Error 404</p> */}
          <p className="er-p3">
            Oops! the URL you are looking for doesn't exist on our server.
            <br /> That's all we know.
          </p>
          {/* <button className="er-btn1">BACK TO HOME</button> */}
        </div>
        <div className="er-image-container">
          <img src={robot} alt="not found" height="100%" width="100%" />
        </div>
      </main>
    </React.Fragment>
  );
};

export default Error404;
