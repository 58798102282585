import React from "react";
import "../../../css/components/_Loader.scss";
const Loader = ({
  size,
  color,
  fontSize,
  display = "block",
  text = "Loading ...",
}) => {
  return (
    <div className="loader-container">
      <span
        className="loader"
        style={{ height: size, width: size, borderTopColor: color }}
      />
      <p className="loader-p1" style={{ fontSize: fontSize, display: display }}>
        {/* {undefined !== text && text.length ? text  : "Loading ..."} */}
        {text}
      </p>
    </div>
  );
};

export default Loader;
