// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
//import { FormControlLabel, Radio } from "@material-ui/core";
import {
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  FormGroup,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
//import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import DatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
// import Dateofbirth from "./DOBPicker";
import TextField from "@mui/material/TextField";
import React, { Component } from "react";
import Script from "react-load-script";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { withRouter } from "react-router-dom";
// import "moment/locale/de";
// import "dz-daterangepicker-material/dist/index.css";
// import DatePicker from "react-datepicker";
import moment from "moment";
// import "../Veterinary-Care-CenterForm/node_modules/react-datepicker/dist/react-datepicker.css";
import validator from "validator";
import GenderSection from "./GenderSection/GenderSection";
import "../../../css/components/_PersonalInformation_new.scss";
import "./Personalinformation.scss";
import ErrorIcon from "@material-ui/icons/Error";
class PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      patientDOB: new Date(),
      patientEmail: "",
      patientContact: "",
      firstNameError: "",
      lastNameError: "",
      patientDOBError: "",
      patientEmailError: "",
      patientContactError: "",
      patientContactLenght: "",
      PhoneNumFlag: "",
      DateError: null,
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePatientDOBChange = this.handlePatientDOBChange.bind(this);
    this.hadnlePatientEmailChange = this.hadnlePatientEmailChange.bind(this);
    this.handlePatientContactChange =
      this.handlePatientContactChange.bind(this);
    this.subYears = this.subYears.bind(this);
  }

  // handleFirstNameChange(event) {
  //   if (validator.isAlpha(event.target.value)) {
  //     this.setState({ firstName: event.target.value, firstNameError: "" });
  //     this.props.onFirstNameChange(event.target.value);
  //   } else if (validator.isEmpty(event.target.value)) {
  //     this.setState({
  //       firstName: event.target.value,
  //       firstNameError: "Error! Use only Alphabets",
  //     });
  //     this.props.onFirstNameChange(event.target.value);
  //   } else {
  //     this.setState({
  //       firstNameError: "",
  //       // firstNameError: "Use only Alphabets",
  //     });
  //   }
  // }
  handleFirstNameChange = (event) => {
    var regex = /^[a-zA-Z.\'\-\‘’ ]*$/;
    if (regex.test(event.target.value)) {
      this.setState({ firstName: event.target.value, firstNameError: "" });
      this.props.onFirstNameChange(event.target.value);
      console.log("dataVAKUDat",true);
    } else {
      console.log("dataVAKUDat",false);
      this.setState({
        firstNameError: "",
        firstNameError: "Error! Use only alphabets, space, apostrophe, hyphen",
      });
    }
  };
  // handleLastNameChange(event) {
  //   if (validator.isAlpha(event.target.value)) {
  //     this.props.onLastNameChange(event.target.value);
  //     this.setState({ lastName: event.target.value, lastNameError: "" });
  //   } else if (validator.isEmpty(event.target.value)) {
  //     this.setState({
  //       lastName: event.target.value,
  //       lastNameError: "Error! Use only Alphabets",
  //     });
  //     this.props.onLastNameChange(event.target.value);
  //   } else {
  //     this.setState({ lastNameError: "" });
  //   }
  // }
  handleLastNameChange = (event) => {
    var regex = /^[a-zA-Z.\'\-\‘’ ]*$/;
    if (regex.test(event.target.value)) {
      console.log("dataVAKUDat",true);
      this.setState({ lastName: event.target.value, lastNameError: "" });
      this.props.onLastNameChange(event.target.value);
    } else {
      console.log("dataVAKUDat",false);
      this.setState({
        lastNameError: "Error! Use only alphabets, space, apostrophe, hyphen",
        lastName: "",
      });
      // this.props.onLastNameChange(event.target.value);
    }
  };
  handlePatientDOBChange(date) {
    console.log("date", date);
    var dobValidation = new Date();
    dobValidation.setFullYear(dobValidation.getFullYear() - 100);

    this.setState({ patientDOB: date });
    this.props.onPatientDOBChange(date);
    this.props.checlkAge(this._calculateAge(date));
    console.log("====================================");
    console.log("DSfdsfdsf", this._calculateAge(date));
    console.log("====================================");
  }
  _calculateAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  handlePatientContactChange(e, data, eventr, formatedvalue) {
    console.log("====================================");
    console.log("handlePatientContactChange", e, data);
    console.log("====================================");
    // event.preventDefault();
    let event = e.toString().length;
    const reg = /^(1)-(\d{3})-(\d{3})-(\d{4})$/;
    // const reg = /^(\d{3})-(\d{3})-(\d{4})$/;
    console.log("value>>", formatedvalue);
    const validateNum = reg.test(e);

    // var validNumber = true;
    if (!e) {
      this.setState({ patientContactError: "" });
      // validNumber = false;
    } else {
      this.setState({ patientContactError: "" });
    }

    this.setState({
      patientContact: formatedvalue,
      patientContactLenght: event,
      PhoneNumFlag: data.name,
    });
    this.props.onPatientContactChange(
      formatedvalue,
      validateNum,
      event,
      data.name
    );
  }
  hadnlePatientEmailChange(event) {
    event.preventDefault();
    // const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,100}$/;
    const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,100}$/;
    // const reg = /^[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*@[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*\\.([a-zA-Z0-9]{2,4})$/;

    const validateEmail = reg.test(event.target.value);

    var validEmailFlag = true;
    if (!validateEmail) {
      this.setState({
        patientEmailError: "Error! Enter Valid Email",
      });
      validEmailFlag = false;
    } else {
      this.setState({ patientEmailError: "" });
    }

    this.setState({ patientEmail: event.target.value });
    this.props.onPatientEmailChange(event.target.value, validEmailFlag);
  }
  subYears(date, years) {
    var newDate = new Date();
    newDate = date;
    newDate.setFullYear(date.getFullYear() - years);
    return newDate;
  }
  _onFocus = (e) => {
    e.currentTarget.type = "date";
  };
  _onBlur = (e) => {
    e.currentTarget.type = "text";
    e.currentTarget.placeholder = "Enter a Date";
  };
  handelExistingPatient = (c) => {
    this.props.onNewPatientChange(c);
  };
  componentDidUpdate(preevprops) {
    // if (preevprops.lastName !== this.props.lastName) {
    //   this.setState({
    //     lastName: this.props.lastName,
    //   });
    // }
  }

  render() {
    console.log("patientContact>>", this.props.lastName,this.props.firstName);
    const usestyles = (theme) => ({
      radio: {
        background: "red",
        "&$checked": {
          color: "#4B8DF8",
        },
      },
      checked: {
        color: "pink",
      },
    });
    const classes = usestyles();

    return (
      <section className="personal-new">
        <section className="p-sec1-new">
          {/* <h3 className="p-h1-new">Personal Information</h3> */}

          <div className="Existing">
            <FormControl component="fieldset">
              <FormLabel id="Existing_Patient" component="legend">
                <h2
                  style={{
                    fontFamily: "Lato",
                    color: "#00234b",
                    marginBottom: 15,
                  }}
                >
                  Are you a returning patient ?
                </h2>
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  // classes={{
                  //   root: classes.formControlLabelRoot,
                  //   label: classes.formControlLabel,
                  // }}
                  aria-describedby="Existing_Patient"
                  control={
                    <Radio
                      className="Existing_Patinet_Radio"
                      checked={this.props.isNewPatient}
                      onChange={() => this.handelExistingPatient(true)}
                      name="Existing Patient"
                      // classes={{ root: classes.radio, checked: classes.checked }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  // classes={{
                  //   root: classes.formControlLabelRoot,
                  //   label: classes.formControlLabel,
                  // }}
                  aria-describedby="Existing_Patient"
                  control={
                    <Radio
                      className="New_Patinet_Radio"
                      checked={this.props.isNewPatient === false ? true : false}
                      onChange={() => this.handelExistingPatient(false)}
                      name="Existing Patient"
                      // classes={{ root: classes.radio, checked: classes.checked }}
                    />
                  }
                  label="No"
                />
              </FormGroup>
            </FormControl>
          </div>
          <h2
            id="PersonalInfoTitle"
            style={{
              fontFamily: "Lato",
              color: "#00234b",
              marginTop: 36,
              // marginBottom: 15,
            }}
          >
            Personal Information
          </h2>
          <div className="p-div1-new">
            <div>
              {/* <div className="p-inp-div p-inp-div1"> */}
              <div className="p-inpContainer-new p-inpContainerone-new">
                <TextField
                  aria-label="First Name"
                  label="First Name"
                  style={{ width: "100%" }}
                  aria-required={true}
                  type="text"
                  id="FirstName"
                  className="p-inp3-new p-inp-new"
                  error={this.state.firstNameError !== "" ? true : false}
                  // placeholder="First Name"
                  value={this.props.firstName}
                  onChange={this.handleFirstNameChange}
                  helperText={this.state.firstNameError}
                  InputProps={{
                    endAdornment:
                      this.state.firstNameError !== "" ? (
                        <ErrorIcon style={{ color: "#D32F2F" }} />
                      ) : null,
                  }}
                  required
                />
                {/* <label
                  style={{ display: "none" }}
                  htmlFor="FirstName"
                  className="p-label1-new p-label-new"
                >
                  First Name <span className="p-span1-new">*</span>
                </label> */}
              </div>

              {/* <p className="p-p1-new p-p-new">{this.state.firstNameError}</p> */}
            </div>
            <div>
              <div
                // style={{ paddingLeft: 15 }}
                className="p-inpContainer-new p-inpContainer2-new"
              >
                <TextField
                  style={{ width: "100%" }}
                  aria-label="Last Name"
                  aria-required={true}
                  type="text"
                  label="Last Name"
                  className="p-inp3-new p-inp-new"
                  value={this.props.lastName}
                  error={this.state.lastNameError !== "" ? true : false}
                  onChange={this.handleLastNameChange}
                  required
                  helperText={this.state.lastNameError}
                  InputProps={{
                    endAdornment:
                      this.state.lastNameError !== "" ? (
                        <ErrorIcon style={{ color: "#D32F2F" }} />
                      ) : null,
                  }}
                  id="LastName"
                />
                {/* <label
                  style={{ display: "none" }}
                  htmlFor="LastName"
                  className="p-label1-new p-label-new"
                >
                  Last Name <span className="p-span1-new">*</span>
                </label> */}
              </div>
              {/* <p className="p-p2-new p-p-new">{this.state.lastNameError}</p> */}
            </div>
            <div>
              <div
                style={{ border: "none" }}
                className="p-inpContainer-new p-inpContainer3-new"
              >
                {/* {this.props.patientDOB === null ||
                this.props.patientDOB === "" ? (
                  <label
                    style={{
                      background: "white",
                      position: "absolute",
                      fontSize: 14,
                      top: 20,
                      zIndex: 0,
                      left: 20,
                      color: "black",
                    }}
                    // className="p-label1-new p-label-new"
                  >
                    Date of Birth
                  </label>
                ) : (
                  ""
                )} */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <DatePicker
                      maxDate={moment()}
                      disableFuture
                      label="Date of Birth"
                      InputProps={{
                        "aria-label": "Enter Date of Birth",
                      }}
                      openTo="year"
                      id="PerDOB"
                      views={["year", "month", "day"]}
                      value={this.props.patientDOB}
                      onError={(e) =>
                        this.setState({ DateError: e }, () => {
                          this.props.handlePatientDOBErrorChange(
                            this.state.DateError
                          );
                        })
                      }
                      onChange={this.handlePatientDOBChange}
                      renderInput={(params) => (
                        <TextField
                          InputProps={{
                            "aria-label": "Enter Date of Birth",
                          }}
                          {...params}
                        />
                      )}
                    />
                    <label
                      htmlFor="PerDOB"
                      style={{
                        display: "none",
                      }}
                      // className="p-label1-new p-label-new"
                    >
                      Date of Birth
                    </label>
                  </Stack>
                </LocalizationProvider>
                <p style={{ color: "red", marginTop: 4 }}>
                  {this.state.DateError == "invalidDate"
                    ? "Enter a valid DOB"
                    : ""}
                </p>
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    className="p-inp3-new p-inp-new"
                    maxDate={new Date()}
                    openTo="year"
                    format="MM/DD/YYYY"
                    locale={"us"}
                    // placeholder="D O B"
                    style={{ fontWeight: "bold" }}
                    views={["year", "month", "date"]}
                    value={this.props.patientDOB}
                    onChange={this.handlePatientDOBChange}
                  />
                </MuiPickersUtilsProvider> */}
              </div>
              <p className="p-p3-new p-p-new">{this.state.patientDOBError}</p>
            </div>
            <div>
              <div
                // style={{ paddingLeft: 15 }}
                className="p-inpContainer-new p-inpContainer4-new"
              >
                <TextField
                  style={{ width: "100%" }}
                  label="Email Address"
                  aria-label="Email Address"
                  aria-required={true}
                  disabled={
                    this.props.match?.params?.Email === this.props.patientEmail
                      ? true
                      : false
                  }
                  type="text"
                  id="EmailAddress"
                  className="p-inp3-new p-inp-new"
                  // placeholder="Email Address"
                  error={this.state.patientEmailError !== "" ? true : false}
                  value={this.props.patientEmail}
                  helperText={this.state.patientEmailError}
                  onChange={this.hadnlePatientEmailChange}
                  InputProps={{
                    endAdornment:
                      this.state.patientEmailError !== "" ? (
                        <ErrorIcon style={{ color: "#D32F2F" }} />
                      ) : null,
                  }}
                  required
                />
                {/* {this.props.match?.params?.Email ==
                this.props.patientEmail ? null : (
                  // <label
                  //   style={{ display: "none" }}
                  //   htmlFor="EmailAddress"
                  //   className="p-label1-new p-label-new"
                  // >
                  //   Email Address <span className="p-span1-new">*</span>
                  // </label>
                )} */}
              </div>
              {/* <p className="p-p4-new p-p-new">{this.state.patientEmailError}</p> */}
            </div>
            <div>
              <div
                // style={{ paddingLeft: 15 }}
                className="International_Phone_Nmb_filed"
              >
                {/* <MaskedInput
                  mask={[
                    // "1",
                    // "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  
                  className="p-inp3-new p-inp-new"
                  value={this.props.patientContact}
                  onChange={this.handlePatientContactChange}
                  required
                /> */}
                <label style={{ display: "none" }} htmlFor="0123">
                  Enter Phone Number
                </label>
                <PhoneInput
                  // style={{ width: "100%" }}
                  aria-label="Phone"
                  aria-required={true}
                  specialLabel="Phone *"
                  style={{ height: "100%", width: "100%" }}
                  placeholder="Phone *"
                  value={this.props.patientContact}
                  country={"us"}
                  id="0123"
                  inputProps={{
                    name: "Phone",
                    id: "0123",
                  }}
                  autoformat={true}
                  onChange={(e, data, v, fomatedvalye) =>
                    this.handlePatientContactChange(e, data, v, fomatedvalye)
                  }
                />
              </div>
              <p className="p-p1-new p-p-new">
                {this.state.patientContactError}
              </p>
            </div>
            <div style={{ marginTop: "-0.5vh" }}>
              <div
                // style={{ paddingLeft: 15 }}
                className="p-inpContainer-new p-inpContainer6-new"
              >
                {/* <Script
                  url="https://maps.googleapis.com/maps/api/js?key=AIzaSyA68NvwYuCjfgT7OfGhh2l6RXfbR3lft84&libraries=places"
                  onLoad={this.props.handleScriptLoad}
                /> */}
                <TextField
                  // style={{ }}
                  aria-label="Address"
                  type="text"
                  placeholder=""
                  label="Address*"
                  id="autocomplete"
                  value={this.props.billingAddress}
                  className="p-inp3-new p-inp-new"
                  onChange={this.props.handleLocationAddressChange}
                  maxLength="60"
                  style={
                    this.props.duplicateAddressFlag
                      ? { borderColor: "red", color: "red",width: "100%"  }
                      : { borderColor: "", color: "black" ,width: "100%" }
                  }
                />
                {/* <label
                  style={{ display: "none" }}
                  htmlFor="autocomplete"
                  className="p-label1-new p-label-new"
                >
                  Address <span className="p-span1-new"></span>
                </label> */}
              </div>
            </div>
            <div>
              <div
                // style={{ paddingLeft: 15 }}
                className="International_Phone_Nmb_filed"
              >
                <GenderSection
                  handelGetGenderInformation={
                    this.props.handelGetGenderInformation
                  }
                  handelGetGender={this.props.handelGetGender}
                  GenderInformation={this.props.GenderInformation}
                  GenderValue={this.props.GenderValue}
                />
              </div>
              {/* <p className="p-p1-new p-p-new">
                {this.state.patientContactError}
              </p> */}
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default withRouter(PersonalInformation);
