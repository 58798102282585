import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
// import "../Veterinary-Care-CenterForm/node_modules/react-datepicker/dist/react-datepicker.css";
import validator from "validator";
import "../../../css/components/_PersonalInformation_new.scss";
import TextField from "@mui/material/TextField";
import ErrorIcon from "@material-ui/icons/Error";
class RequestCall_PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      patientContact: "",
      patientContactLenght: "",
      patientEmail: "",
      firstNameError: "",
      lastNameError: "",
      patientContactError: "",
      patientEmailError: "",
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePatientContactChange = this.handlePatientContactChange.bind(
      this
    );
    this.hadnlePatientEmailChange = this.hadnlePatientEmailChange.bind(this);
  }

  // handleFirstNameChange(event) {
  //   if (validator.isAlpha(event.target.value)) {
  //     this.setState({ firstName: event.target.value, firstNameError: "" });
  //     this.props.onFirstNameChange(event.target.value);
  //   } else if (validator.isEmpty(event.target.value)) {
  //     this.setState({
  //       firstName: event.target.value,
  //       firstNameError: "Use only Alphabets",
  //     });
  //     this.props.onFirstNameChange(event.target.value);
  //   } else {
  //     // console.log(`Don't Enter the Number`);
  //     this.setState({
  //       firstNameError: "Use only Alphabets",
  //     });
  //   }
  // }
  handleFirstNameChange = (event) => {
    var regex = /^[a-zA-Z.\'\-\‘’ ]*$/;
    if (regex.test(event.target.value)) {
      console.log("dataVAKUDat",true);
      this.setState({ firstName: event.target.value, firstNameError: "" });
      this.props.onFirstNameChange(event.target.value);
    } else {
      console.log("dataVAKUDat",false);
      this.setState({
        firstNameError: "Error! Use only alphabets, space, apostrophe, hyphen",
        firstName: "",
      });
      // this.props.onLastNameChange(event.target.value);
    }
  };
  // handleLastNameChange(event) {
  //   if (validator.isAlpha(event.target.value)) {
  //     this.setState({ lastName: event.target.value, lastNameError: "" });
  //     this.props.onLastNameChange(event.target.value);
  //   } else if (validator.isEmpty(event.target.value)) {
  //     this.setState({
  //       lastName: event.target.value,
  //       lastNameError: "Use only Alphabets",
  //     });
  //     this.props.onLastNameChange(event.target.value);
  //   } else {
  //     console.log(`Don't Enter the Number`);
  //     this.setState({ lastNameError: "Use only Alphabets" });
  //   }
  // }
  handleLastNameChange = (event) => {
    var regex = /^[a-zA-Z.\'\-\‘’ ]*$/;
    if (regex.test(event.target.value)) {
      // console.log("dataVAKUDat",true);
      this.setState({ lastName: event.target.value, lastNameError: "" });
      this.props.onLastNameChange(event.target.value);
    } else {
      // console.log("dataVAKUDat",false);
      this.setState({
        lastNameError: "Error! Use only alphabets, space, apostrophe, hyphen",
        lastName: "",
      });
      // this.props.onLastNameChange(event.target.value);
    }
  };
  handlePatientContactChange(e, data, eventr, formatedvalue) {
    // event.preventDefault();
    // const reg = /^(\d{3})-(\d{3})-(\d{4})$/;
    const patientContactLenght = e.toString().length;
    // console.log("Regular Expression : ", reg);
    // console.log("Validate num : ", validateNum);
    if (patientContactLenght <= 10) {
      this.setState({ patientContactError: "" });
      // validNumber = false;
    } else {
      this.setState({ patientContactError: "" });
    }

    // var contactNumber = event.target.value;
    // var dashAdd =
    //   contactNumber.length == 1
    //     ? contactNumber + "-"
    //     : contactNumber.length == 5
    //     ? contactNumber + "-"
    //     : contactNumber.length == 9
    //     ? contactNumber + "-"
    //     : contactNumber.length == 10
    //     ? contactNumber.substring(0, contactNumber.length - 1)
    //     : contactNumber.length == 6
    //     ? contactNumber.substring(0, contactNumber.length - 1)
    //     : contactNumber.length == 2
    //     ? contactNumber.substring(0, contactNumber.length - 1)
    //     : contactNumber;

    this.setState({ patientContact: formatedvalue });
    this.props.onPatientContactChange(formatedvalue, patientContactLenght);
  }

  hadnlePatientEmailChange(event) {
    event.preventDefault();
    const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,100}$/;
    const validateEmail = reg.test(event.target.value);

    var validEmailFlag = true;
    if (!validateEmail) {
      this.setState({
        patientEmailError: "Enter Valid Email",
      });
      validEmailFlag = false;
    } else {
      this.setState({ patientEmailError: "" });
    }

    this.setState({ patientEmail: event.target.value });
    this.props.onPatientEmailChange(event.target.value, validEmailFlag);
  }

  render() {
    return (
      <section className="personal-new">
        <section className="p-sec1-new">
          <h2 className="p-h1-new">Personal Information</h2>

          <div className="p-div1-new">
            <div>
              <div className="p-inpContainer-new rqst-input p-inpContainer1-new">
                <TextField
                  type="text"
                  inputProps={{ "aria-label": "First Name" }}
                  label="First Name"
                  className="p-inp3-new p-inp-new"
                  value={this.props.firstName}
                  onChange={this.handleFirstNameChange}
                  required

                  aria-label="First Name"
                  aria-required={true}
                  error={this.state.firstNameError !== "" ? true : false}
                  helperText={this.state.firstNameError}
                  InputProps={{
                    endAdornment:
                      this.state.firstNameError !== "" ? (
                        <ErrorIcon style={{ color: "#D32F2F" }} />
                      ) : null,
                  }}
                  id="firstname"
                />

                {/* <label
                  style={{ display: "none" }}
                  className="p-label1-new p-label-new"
                >
                  First Name <span className="p-span1-new">*</span>
                </label> */}
              </div>

              {/* <p className="p-p1-new p-p-new">{this.state.firstNameError}</p> */}
            </div>
            <div>
              <div className="p-inpContainer-new rqst-input p-inpContainer2-new">
                <TextField
                  type="text"
                  className="p-inp3-new p-inp-new"
                  value={this.props.lastName}
                  inputProps={{ "aria-label": "Last Name" }}
                  onChange={this.handleLastNameChange}
                  required
                  label="Last Name"
                  
                  aria-label="Last Name"
                  aria-required={true}
                  error={this.state.lastNameError !== "" ? true : false}
                  helperText={this.state.lastNameError}
                  InputProps={{
                    endAdornment:
                      this.state.lastNameError !== "" ? (
                        <ErrorIcon style={{ color: "#D32F2F" }} />
                      ) : null,
                  }}
                  id="lastname"
                />
                {/* <label
                  style={{ display: "none" }}
                  className="p-label1-new p-label-new"
                >
                  Last Name <span className="p-span1-new">*</span>
                </label> */}
              </div>
              {/* <p className="p-p2-new p-p-new">{this.state.lastNameError}</p> */}
            </div>

            <div className="International_Phone_Nmb_filed">
              {/* <div className="p-inpContainer-new rqst-input p-inpContainer5-new"> */}
              {/* <input
                  type="text"
                 
                  maxLength="14"
                  className="p-inp3-new p-inp-new"
                  value={this.props.patientContact}
                  onChange={this.handlePatientContactChange}
                  required
                /> */}
              {/* <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                 
                  className="p-inp3-new p-inp-new"
                  value={this.props.patientContact}
                  onChange={this.handlePatientContactChange}
                  required
                />
                <label className="p-label1-new p-label-new">
                  Contact No <span className="p-span1-new">*</span>
                </label> */}
              <PhoneInput
                // style={{ height: "100%", width: "100%" }}
                specialLabel="Phone *"
                placeholder="Phone *"
                value={this.props.patientContact}
                onChange={this.handlePatientContactChange}
                country={"us"}
                autoFormat={true}
              />
              {/* </div> */}
              <p className="p-p1-new p-p-new">
                {this.state.patientContactError}
              </p>
            </div>
            <div>
              <div className="p-inpContainer-new rqst-input p-inpContainer4-new">
                <TextField
                  type="text"
                  className="p-inp3-new p-inp-new"
                  // placeholder="Email Address"
                  value={this.props.patientEmail}
                  inputProps={{ "aria-label": "Email Address" }}
                  onChange={this.hadnlePatientEmailChange}
                  required
                  label="Email Address"
                />
                <label
                  style={{ display: "none" }}
                  className="p-label1-new p-label-new"
                >
                  Email Address <span className="p-span1-new">*</span>
                </label>
              </div>
              <p className="p-p4-new p-p-new">{this.state.patientEmailError}</p>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default RequestCall_PersonalInformation;
