export const appointments_Actions = {
  getAllInsurances: {
    GET: "APPOINTMENTS_INSURANCES_GET",
    FAILURE: "APPOINTMENTS_INSURANCES_FAILURE",
    SUCCESS: "APPOINTMENTS_INSURANCES_SUCCESS",
  },
  getAllAppointments: {
    GET: "APPOINTMENTS_ALL_GET",
    FAILURE: "APPOINTMENTS_ALL_FAILURE",
    SUCCESS: "APPOINTMENTS_ALL_SUCCESS",
  },
  submitAppointment: {
    NEW: "APPOINTMENTS_ADD_NEW",
    POST: "APPOINTMENTS_ADD_POST",
    SUCCESS: "APPOINTMENTS_ADD_SUCCESS",
    FAILURE: "APPOINTMENTS_ADD_FAILURE",
    UPDATE: "APPOINTMENTS_ADD_UPDATE",
  },
  getAllDoctorsByPractice: {
    GET: "DOCTORSBYPRACTICE_ALL_GET",
    FAILURE: "DOCTORSBYPRACTICE_ALL_FAILURE",
    SUCCESS: "DOCTORSBYPRACTICE_ALL_SUCCESS",
  },
  updateAppointmentStatus: {
    POST: "APPOINTMENT_STATUS_POST",
    SUCCESS: "APPOINTMENT_STATUS_SUCCESS",
    FAILURE: "APPOINTMENT_STATUS_FAILURE",
  },
  getAllAppointmentsByPractice: {
    GET: "APPOINTMENTSBYPRACTICE_ALL_GET",
    FAILURE: "APPOINTMENTSBYPRACTICE_ALL_FAILURE",
    SUCCESS: "APPOINTMENTSBYPRACTICE_ALL_SUCCESS",
    UPDATE: "APPOINTMENTSBYPRACTICE_ALL_UPDATE",
  },
  //..............................
  updateDoctorStatus: {
    POST: "APPOINTMENT_DOCTOR_POST",
    SUCCESS: "APPOINTMENT_DOCTOR_SUCCESS",
    FAILURE: "APPOINTMENT_DOCTOR_FAILURE",
  },
  getDoctorsByPracticeID: {
    GET: "DOCTORSBYPRACTICEID_ALL_GET",
    FAILURE: "DOCTORSBYPRACTICEID_ALL_FAILURE",
    SUCCESS: "DOCTORSBYPRACTICEID_ALL_SUCCESS",
    UPDATE: "DOCTORSBYPRACTICEID_ALL_UPDATE",
  },
  //..............................
  doctorAvailability: {
    NEW: "DOCTOR_AVAILABILTY_NEW",
    POST: "DOCTOR_AVAILABILTY_POST",
    FAILURE: "DOCTOR_AVAILABILTY_FAILURE",
    DOCTOR_AVAILABLE: "DOCTOR_AVAILABILTY_DOCTOR_AVAILABLE",
    DATE_NOT_AVAILABLE: "DOCTOR_AVAILABILTY_DATE_NOT_AVAILABLE",
    TIME_NOT_AVAILABLE: "DOCTOR_AVAILABILTY_TIME_NOT_AVAILABLE",
  },
};

export const appointments_Status = {
  getAllInsurances: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING",
  },
  getAllAppointments: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING",
  },
  getAllAppointmentsByPractice: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING",
  },
  //........................
  getAllDoctorsByPracticeID: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING",
  },
  //........................
  submitAppointment: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING",
  },
  getAllDoctorsByPractice: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING",
  },
  AppointmentStatusUpdate: {
    NEW: "AppointmentStatusUpdate_NEW",
    SUCCESS: "AppointmentStatusUpdate_SUCCESS",
    FAILURE: "AppointmentStatusUpdate_FAILURE",
    LOADING: "AppointmentStatusUpdate_LOADING",
  },
  //....................
  doctorUpdate: {
    NEW: "DoctorUpdate_NEW",
    SUCCESS: "DoctorUpdate_SUCCESS",
    FAILURE: "DoctorUpdate_FAILURE",
    LOADING: "DoctorUpdate_LOADING",
  },
  //....................
  doctorAvailability: {
    NEW: "DOCTOR_AVAILABILTY_NEW",
    POST: "DOCTOR_AVAILABILTY_POST",
    FAILURE: "DOCTOR_AVAILABILTY_FAILURE",
    DOCTOR_AVAILABLE: "DOCTOR_AVAILABILTY_DOCTOR_AVAILABLE",
    DATE_NOT_AVAILABLE: "DOCTOR_AVAILABILTY_DATE_NOT_AVAILABLE",
    TIME_NOT_AVAILABLE: "DOCTOR_AVAILABILTY_TIME_NOT_AVAILABLE",
  },
};

export const callRequest_Status = {
  createCallRequest: {
    NEW: "createCallRequest_NEW",
    SUCCESS: "createCallRequest_SUCCESS",
    FAILURE: "createCallRequest_FAILURE",
    LOADING: "createCallRequest_LOADING",
  },
  getCallRequest: {
    NEW: "getCallRequest_NEW",
    SUCCESS: "getCallRequest_SUCCESS",
    FAILURE: "getCallRequest_FAILURE",
    LOADING: "getCallRequest_LOADING",
    UPDATE: "getCallRequest_UPDATE",
  },
};
export const callRequest_Actions = {
  createCallRequest: {
    POST: "createCallRequest_POST",
    SUCCESS: "CallRequest_SUCCESS",
    FAILURE: "CallRequest_FAILURE",
    NEW: "CallRequest_NEW",
  },
  getCallRequest: {
    GET: "GETCallRequest_POST",
    SUCCESS: "GETCallRequest_SUCCESS",
    FAILURE: "GETCallRequest_FAILURE",
    NEW: "GETCallRequest_NEW",
    UPDATE: "GETCallRequest_UPDATE",
  },
};

export const covidRequest_Status = {
  createCovidRequest: {
    NEW: "createCovidRequest_NEW",
    SUCCESS: "createCovidRequest_SUCCESS",
    FAILURE: "createCovidRequest_FAILURE",
    LOADING: "createCovidRequest_LOADING",
  },
  getCovidRequest: {
    NEW: "getCovidRequest_NEW",
    SUCCESS: "getCovidRequest_SUCCESS",
    FAILURE: "getCovidRequest_FAILURE",
    LOADING: "getCovidRequest_LOADING",
    UPDATE: "getCovidRequest_UPDATE",
  },
};
export const covidRequest_Actions = {
  createCovidRequest: {
    POST: "createCovidRequest_POST",
    SUCCESS: "CovidRequest_SUCCESS",
    FAILURE: "CovidRequest_FAILURE",
    NEW: "CovidRequest_NEW",
  },
  getCovidRequest: {
    GET: "GETCovidRequest_POST",
    SUCCESS: "GETCovidRequest_SUCCESS",
    FAILURE: "GETCovidRequest_FAILURE",
    NEW: "GETCovidRequest_NEW",
    UPDATE: "GETCovidRequest_UPDATE",
  },
};
