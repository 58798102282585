import React, { Component } from "react";
import mart1 from "../../images/Group158877.png";
import "../../css/components/_Header_new.scss";
export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practiceName: "Untitled",
    };
  }
  componentWillReceiveProps(nextProps) {}
  render() {
    console.log("PracriceDetails", this.props.practice);

    return (
      <div className="h-container-new">
        <header role="banner" className="header-new">
          <img
            style={{ borderRadius: "100%" }}
            src={
              this?.props?.practice?.practiceLogo
                ? this?.props?.practice?.practiceLogo
                : mart1
            }
            alt="Practice Logo"
            className="h-img1-new"
          />
          <div
            style={{ fontSize: 24, fontFamily: "Lato", fontWeight: 500 }}
            className="h-h1-new"
          >
            {this.props?.practice?.practiceName}{" "}
          </div>
          <p className="h-p1-new">
            Powered by: <span className="h-sp1-new">Zebra Doctor</span>{" "}
            <span className="h-sp2-new"> Medical Stack</span>
          </p>
        </header>
      </div>
    );
  }
}

export default Header;
