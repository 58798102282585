import { appointments_Actions } from "../constants/Appointments";
import { appointments_Status } from "../constants/Appointments";
const getDoctor_initialStateStatus = {
  docotor_update_status: appointments_Status.doctorUpdate.NEW,
  doctor_name: "First available provider",
};

const doctorStatus_Reducer = function (
  state = getDoctor_initialStateStatus,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case appointments_Actions.updateDoctorStatus.POST:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        docotor_update_status: appointments_Status.doctorUpdate.LOADING,
      };
    case appointments_Actions.updateDoctorStatus.SUCCESS:
      console.log("I am from Reducer SUCCESS..", action.payload);
      return {
        ...state,
        docotor_update_status: appointments_Status.doctorUpdate.SUCCESS,
      };
    case appointments_Actions.updateDoctorStatus.FAILURE:
      console.log("I am from Reduce Failure..");
      return {
        ...state,
        docotor_update_status: appointments_Status.doctorUpdate.FAILURE,
        error: action.payload.error,
      };

    default:
      console.log("default is firing");

      return {
        ...state,
        docotor_update_status: appointments_Status.doctorUpdate.NEW,
      };
  }
};
export default doctorStatus_Reducer;
