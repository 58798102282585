export const doctors_Actions= {
		getAllDoctorsByPractice :{
			GET : "DOCTORSBYPRACTICE_ALL_GET",
		FAILURE: "DOCTORSBYPRACTICE_ALL_FAILURE",
		SUCCESS:"DOCTORSBYPRACTICE_ALL_SUCCESS"
		},
		createDoctor:
	{
		POST : "PRACTICE_DOCTORS_POST",
		FAILURE: "PRACTICE_DOCTORS_FAILURE",
		SUCCESS:"PRACTICE_DOCTORS_SUCCESS"
	},
	updateDoctor:
	{
		UPDATE : "PRACTICE_DOCTORS_UPDATE",
		FAILURE: "PRACTICE_DOCTORS_UPDATE_FAILURE",
		SUCCESS:"PRACTICE_DOCTORS_UPDATE_SUCCESS"
	},
	getDoctorAppointments:{
		GET: "getDoctorAppointments_GRT",
		FAILURE: "getDoctorAppointments_FAILURE",
		SUCCESS:"getDoctorAppointments_SUCCESS"
	},
	deactivateDoctor:{
		UPDATE:"deactivateDoctor_UPDATE",
		FAILURE: "deactivateDoctor_FAILURE",
		SUCCESS:"deactivateDoctor_SUCCESS"
	}
}


export const doctors_Status ={
		getAllDoctorsByPractice :{
		NEW: "NEW",
		SUCCESS:"SUCCESS",
		FAILURE:"FAILURE",
		LOADING:"LOADING"
		},
		createDoctor:
	{
		NEW: "NEW",
		SUCCESS:"SUCCESS",
		FAILURE:"FAILURE",
		LOADING:"LOADING"
	},
	updateDoctor:
	{
		NEW: "NEW_updateDoctor",
		SUCCESS:"SUCCESS_updateDoctor",
		FAILURE:"FAILURE_updateDoctor",
		LOADING:"LOADING_updateDoctor"
	},
	deactivateDoctor:
	{
		NEW: "NEW_deactivateDoctor",
		SUCCESS:"SUCCESS_deactivateDoctor",
		FAILURE:"FAILURE_deactivateDoctor",
		LOADING:"LOADING_deactivateDoctor"
	},
	getDoctorAppointments:{
		NEW: "NEW_getDoctorAppointments",
		SUCCESS:"SUCCESS_getDoctorAppointments",
		FAILURE:"FAILURE_getDoctorAppointmentsdateDoctor",
		LOADING:"LOADING_getDoctorAppointments"
	}
}
