import React, { Component } from "react";
import { connect } from "react-redux";
import { practice_Status } from "../../constants/Practices";
import { PracticeServer } from "../../servers/PracticeServer";
//import { CircularProgress } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
// import Quest from "./Covid-test/Questionaire";
import withRouter from "react-router-dom/withRouter";
import Axios from "axios";
import { ROOT_URL, PraciceStatusID } from "../../constants/config";
import store from "../../store";
import Appointment from "./BookAnAppointment/Appointment";
import Footer from "./Footer";
import Header from "./Header";
import Navigation from "./Navigation";
import Notfound from "../utils/Error404";
import RequestCall_Main from "./RequestCall/RequestCall_Main";
//import loadable from "@loadable/component";

// Keep following non lazy for now
/*
const Appointment = loadable(() => import("./BookAnAppointment/Appointment"));
const Footer = loadable(() => import("./Footer"));
const Loader = loadable(() => import("../PracticeSetup/common/Loader"));
const Header = loadable(() => import("./Header"));
const Navigation = loadable(() => import("./Navigation"));
const RequestCall_Main = loadable(() =>
  import("./RequestCall/RequestCall_Main")
);*/

const mapStateToProps = (state) => {
  return {
    practice_get_status: state.practiceByName_Get_Reducer.practice_get_status,
    practice: state.practiceByName_Get_Reducer.practice,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPracticeByName: (practiceName) => {
      dispatch(PracticeServer.getPracticeByName(practiceName));
    },
    // getPracticeIdAndURL: (body) => {
    //   dispatch(PracticeServer.getPracticeIdAndURL(body));
    // },
  };
};

export class BookAnAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: 0,
      PdfFile: null,
      BackGround: "",
      PatientName: "",
      ReasonsList: [],
      PopularReasons: [],
      isCovidRapidTest: null,
      isAntiBodyTest: null,
      isCovidPCRTest: null,
      TestsLoading: false,
      PracticeAccount: {},
      PracticeStatusId: null,
    };
  }

  switchScreenHandler = (id) => {
    this.setState({ screen: id });
  };
  componentDidMount() {
    if (this.props?.match?.params?.practiceName) {
      this.props.getPracticeByName(this.props?.match?.params?.practiceName);
    } else if (this.props.AddManual) {
      this.props.getPracticeByName(this.props.PracticeName);
    }
    if (this.props.shortUrl === true) {
      if (this.props.withoutQuery) {
        // console.log("dataSHORT",this.props.location.pathname.split("/")[2]);
        this.getpraticeData_byLocaitonFormUrl()
      } else {

        this.getpraticeData_byUrl();
      }
    } else {
      if (this.props.Location) {
        this.hadnellocation();
      }
    }

    if (this.props.Testlocation) {
      this.handlelocationTest();
    }
  }
  handlelocationTest = () => {
    console.log("====================================");
    console.log("dsf32", this.props?.match?.params?.Testlocation);
    console.log("====================================");
    store.dispatch({
      type: "PRACTICEBYNAME_ALL_GET",
      payload: {},
    });

    Axios.get(
      `${ROOT_URL}/api/practiceAdmin/getpracticeInformationbypracLocIDNEW/${this.props?.match?.params?.Testlocation}`
      // {
      //   token: this.props?.match?.params?.LocationID,
      // }
    ).then((res) => {
      console.log("====================================");
      console.log(res.data);
      console.log("====================================");
      if (res.data.MS_getpracticeInformationbypracLocID_Status !== "failure") {
        console.log("====================================");
        console.log(
          "LocationID",
          res.data.MS_getpracticeInformationbypracLocID_Status
        );
        console.log("====================================");
        this.setState({
          PracticeAccount: res.data.practiceDetails,
        });
        store.dispatch({
          type: "PRACTICEBYNAME_ALL_SUCCESS",
          payload: res.data.practiceDetails.practiceID,
        });
      } else {
        // this.state.screen = 2;
        this.setState({
          screen: 2,
        });
      }
    });
  };
  hadnellocation = () => {
    // console.log("====================================");
    // console.log("dsf32", this.props?.match?.params?.LocationID);
    // console.log("====================================");
    store.dispatch({
      type: "PRACTICEBYNAME_ALL_GET",
      payload: {},
    });

    Axios.get(
      `${ROOT_URL}/api/practiceAdmin/getpracticeInformationbypracLocID/${this.props?.match?.params?.LocationID}`
      // {
      //   token: this.props?.match?.params?.LocationID,
      // }
    ).then((res) => {
      // console.log("====================================");
      // console.log(res.data);
      // console.log("====================================");
      if (res.data.MS_getpracticeInformationbypracLocID_Status !== "failure") {
        // console.log("====================================");
        // console.log("LocationID", res.data);
        // console.log("====================================");
        this.setState({
          PracticeAccount: res.data.practiceDetails,
        });
        store.dispatch({
          type: "PRACTICEBYNAME_ALL_SUCCESS",
          payload: res.data.practiceDetails.practiceID,
        });
      } else {
        // this.state.screen = 2;
        this.setState({
          screen: 2,
        });
      }
    }).catch((e) => {
      this.setState({
        screen: 2,
      });
    });
  };
  getpraticeData_byUrl = () => {
    store.dispatch({
      type: "PRACTICEBYNAME_ALL_GET",
      payload: {},
    });
    const body = {
      pid: this.props.history.location.search.split("=")[1],
      componentName: "",
    };
    console.log("bodyURL", body);
    Axios.post(
      `${ROOT_URL}/api/practiceAdmin/getpracticebyshortID${this.props.location.search}`,
      body
    ).then((res) => {
      if (res.data.MS_getpracticeInformationbypracLocID_Status !== "failure") {
        this.setState({
          PracticeAccount: res.data.practiceDetails,
        });
        store.dispatch({
          type: "PRACTICEBYNAME_ALL_SUCCESS",
          payload: res.data.practiceDetails.practiceID,
        });
      } else {
        this.setState({
          screen: 2,
        });
      }
    }).catch((e) => {
      this.setState({
        screen: 2,
      });
    });;
  };
  getpraticeData_byLocaitonFormUrl = () => {
    store.dispatch({
      type: "PRACTICEBYNAME_ALL_GET",
      payload: {},
    });
    const body = {
      formUrl: this.props.location.pathname.split("/")[2],
      componentName: "",
    };
    // console.log("bodyURL", body);
    Axios.post(
      `${ROOT_URL}/api/practiceAdmin/getFormInfo/${this.props.location.pathname.split("/")[2]}`,
      body
    ).then((res) => {
      if (res.data.MS_getpracticeInformationbypracLocID_Status !== "failure") {
        this.setState({
          PracticeAccount: res.data.practiceDetails,
        });
        store.dispatch({
          type: "PRACTICEBYNAME_ALL_SUCCESS",
          payload: res.data.practiceDetails.practiceID,
        });
      } else {
        this.setState({
          screen: 2,
        });
      }
    }).catch((e) => {
      this.setState({
        screen: 2,
      });
    });
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.practice_get_status ===
      practice_Status.getPracticeByName.SUCCESS
    ) {
      this.state.screen = 1;
    }
    if (
      nextProps.practice_get_status ===
      practice_Status.getPracticeByName.LOADING
    ) {
      this.state.screen = 0;
    }
    if (
      nextProps.practice_get_status ===
      practice_Status.getPracticeByName.FAILURE
    ) {
      this.state.screen = 2;
    }
  }
  componentDidUpdate(prevprops) {
    if (prevprops.practice !== this.props.practice) {
      // this.getTestReasons();
      this.getAppointmentReasonType()
      this.setState({
        PracticeStatusId: this?.props.practice.practiceStatus,
      });
    }
  }
  getScreen(screen) {
    switch (screen) {
      case 0:
        return (
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{ color: this.props.AddManual ? "#3F3D56" : "#1489FF" }}
            />
          </div>
        );
      case 1:
        //return this.props.state ? (
        // <Quest
        //   handelChange={this.handelChange}
        //   practice={this.props.practice}
        //   PdfFile={this.state.PdfFile}
        //   handelPdfgenerator={this.handelPdfgenerator}
        //   state={this.props.state}
        // />
        return (
          <Appointment
            PracticeAccount={this.state.PracticeAccount}
            practiceLocationID={
              this.props.history.location.search.split("=")[1]
            }
            TestsLoading={this.state.TestsLoading}
            ReasonsList={this.state.ReasonsList}
            PopularReasons={this.state.PopularReasons}
            isCovidRapidTest={this.state.isCovidRapidTest}
            isAntiBodyTest={this.state.isAntiBodyTest}
            isCovidPCRTest={this.state.isCovidPCRTest}
            switchScreenHandler={this.switchScreenHandler}
            AddManual={this.props.AddManual}
            doctorDetailPopupHandler={this.doctorDetailPopupHandler}
            Embedded={this.props.Embedded}
          />
        );
      // ) : (
      //   <Appointment
      //     PracticeAccount={this.state.PracticeAccount}
      //     TestsLoading={this.state.TestsLoading}
      //     ReasonsList={this.state.ReasonsList}
      //     isCovidRapidTest={this.state.isCovidRapidTest}
      //     isAntiBodyTest={this.state.isAntiBodyTest}
      //     isCovidPCRTest={this.state.isCovidPCRTest}
      //     switchScreenHandler={this.switchScreenHandler}
      //     AddManual={this.props.AddManual}
      //     doctorDetailPopupHandler={this.doctorDetailPopupHandler}
      //   />
      // );
      case 2:
        return (
          <Notfound />
          // <div style={{
          //   height: "50vh",
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   fontWeight: 600
          // }}>
          //   <p>Failed to get Practice Details</p>
          // </div>
        );
      case 3:
        return (
          <RequestCall_Main
            switchScreenHandler={this.switchScreenHandler}
            practice={this.props.practice}
            PracticeAccount={this.state.PracticeAccount}
          />
        );
      case 4:
        return <Notfound />;
      default:
        break;
    }
  }
  handelChange = (v) => {
    this.setState({
      PatientName: v,
    });
  };
  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  // getTestReasons = () => {
  //   this.setState({
  //     TestsLoading: true,
  //   });

  //   Axios.get(
  //     `${ROOT_URL}/api/admin/appointmentSetting/getAllAppointmentSettingDatabypracticeLocationID/${this.state.PracticeAccount._id
  //     }`
  //   )
  //     .then((res) => {
  //       this.setState({
  //         // ReasonsList: res.data.AppointmentSettingFormList.reasonForVisit,
  //         isCovidRapidTest:
  //           res.data.AppointmentSettingFormList.isCovidRapidTest,
  //         isAntiBodyTest: res.data.AppointmentSettingFormList.isAntiBodyTest,
  //         isCovidPCRTest: res.data.AppointmentSettingFormList.isCovidPCRTest,
  //         TestsLoading: false,
  //       });
  //     })
  //     .catch((e) => { });
  // };
  getAppointmentReasonType = () => {
    this.setState({
      TestsLoading: true,
    });
 
    const body = {
      locationID: this.state.PracticeAccount._id
    }
    // alert(JSON.stringify("3"))
    Axios.post(
      `${ROOT_URL}/api/admin/v1/AppointmentForm/appointmentSetting/getAppointmentSetting`, body
      // `${ROOT_URL}/api/admin/AppointmentForm/appointmentSetting/getAppointmentSetting`, body
    )
      .then((res) => {
        // alert(JSON.stringify(res.data.PopularReasons.ReasonsArray))
        // alert(JSON.stringify(res.data.Reasons))
        // let arr=[]
        // let arr1=[]
        // arr= res.data.AthenaReasons
        // arr1= res.data.PracticeReasons
        // const result =arr.concat(arr1)
        // console.log('resultresult',result);
        this.setState({
          ReasonsList: res.data.Reasons?res.data.Reasons:[],
          PopularReasons: res.data?.PopularReasons?.ReasonsArray ? res.data.PopularReasons.ReasonsArray : []
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  render() {
    // console.log("====================================");
    // console.log(
    //   "dsf3243",
    //   this.props.practice,
    //   this.props?.match?.params?.LocationID,
    //   this.props.Location
    // );
    // console.log("====================================");

    const urlSearchParams = this.props.location.search;
    console.log(
      "urlSearchParams",
      this?.props.practice,
      this.state.PracticeStatusId
    );
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundColor: this.props.Embedded ? "white" : "",
          paddingBottom: this.props.Embedded ? 30 : "",
        }}
      >
        {/* <div> */}
        {this.state.PracticeStatusId == PraciceStatusID.canceled ||
          this.state.PracticeStatusId == PraciceStatusID.unpaid ||
          this.props.AddManual ||
          this.props.Embedded? null : (
          <Header
            practiceorPracticeaccount={this.props?.match?.params?.LocationID}
            PracticeAccount={this.state.PracticeAccount}
            BackGround={this.state.BackGround}
            state={this.props.state}
            practice={this.props?.practice}
          />
        )}
        {this.state.PracticeStatusId == PraciceStatusID.canceled ||
          this.state.PracticeStatusId == PraciceStatusID.unpaid ||
          this.props.AddManual ||
          this.props.Embedded  ||this.state.screen==2? null : this.props.state ? null : (
            <Navigation
              practiceorPracticeaccount={this.props?.match?.params?.LocationID}
              PracticeAccount={this.state.PracticeAccount}
              practice={this.props.practice}
              switchScreenHandler={this.switchScreenHandler}
              screen={this.state.screen}
            />
          )}
        {/* </div> */}
        {this.getScreen(
          this.state.PracticeStatusId == PraciceStatusID.canceled ||
            this.state.PracticeStatusId == PraciceStatusID.unpaid
            ? 4
            : this.state.screen
        )}
        {this.state.PracticeStatusId == PraciceStatusID.canceled ||
          this.state.PracticeStatusId == PraciceStatusID.unpaid ||
          this.props.AddManual ||
          this.props.Embedded ? null : (
          <div>
            <Footer
              practiceorPracticeaccount={this.props?.match?.params?.LocationID}
              PracticeAccount={this.state.PracticeAccount}
              BackGround={this.state.BackGround}
              practice={this.props.practice}
              switchScreenHandler={this.switchScreenHandler}
              screen={this.state.screen}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookAnAppointment)
);
