// export const ROOT_URL = "http://10.10.36.120:3000";
// export const ROOT_URL = "http://localhost:3302";
// export const ROOT_URL = "http://10.10.36.96:3303";
// export const ROOT_URL = 'https://zebra.doctor';
// export const WEB_URL  = 'https://zebra.doctor';
// export const ROOT_URL = "http://10.10.36.224:3302";
// export const WEB_URL  = 'http://192.168.1.104:3000';
export const ROOT_URL = "https://terraformapi.zebra.doctor";
// export const ROOT_URL = "http://10.10.36.218:3302";
// export const ROOT_URL = "https://demoapi.zebra.doctor";
export const WEB_URL  = 'https://appointment.zebra.doctor';
export const PracticeIconName = '';
export const CovidFormStatus = { PPC: 2, QR: 1 };

export const MessagesList = {
  Booked: 'Your appointment has been marked as Booked',

  Pending: 'Your appointment has been marked as Pending',

  Cancelled: 'Your appointment has been marked as Cancelled',

  Cleared: 'Your appointment has been marked as Cleared',

  Invalid: 'Your appointment has been marked as Invalid',
};
export const FormsLinks = {
  FormLibraryForm: 'https://form.zebra.doctor',
};
export const PracticeAval = { PractiveAvalSlots: 10 };

export const PraciceStatusID = {
  pending: '5f6d8231f09462464425b5a4', //pending
  active: '5f6d8231f09462464425b5a3', //active
  FreeTrial: '6232edd5af08b837bf757162', //FreeTrial
  canceled: '62397e260ec8a066c8720004', //canceled
  pastDue: '623c0517eea9cb764e7f58ba', //pastDue
  unpaid: '623c0517eea9cb764e7f58bb', //unpaid
};
