import React, { Component } from "react";
//import DatePicker from "react-datepicker";

import "../../../css/components/React-DatePicker.css";
import moment from "moment";
import { appointments_Status } from "../../../constants/Appointments";
import "../../../css/components/_BookAppointment_new.scss";
import Axios from "axios";
import { ROOT_URL } from "../../../constants/config";
//import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import DatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { NativeSelect, MenuItem, OutlinedInput } from "@mui/material";
import Alert from "@reach/alert";
import momenttime from 'moment-timezone';

// import ScrollLock, { TouchScrollable } from "react-scrolllock";
class BookAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentDate: "",
      appointmentTime: this.props.appointmentTime,
      timeSlots: [],
      selectedSlots: this.props.selectedSlots,
      nextDate: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      loaderForTime: false,
      label: false,
      slots: [],
      AppointmentDay: "",
      ApplyLoading: false,
      AllPracticeLocation: [],
      DateError: null,
      KeyCount: 0,
      reasonForVisits: '',
      reasonForVisitsArray:'',
      selectDoctorError: ""
    };
    this.handleAppointmentTimeChange =
      this.handleAppointmentTimeChange.bind(this);
    this.handleAppointmentDateChange =
      this.handleAppointmentDateChange.bind(this);
    this.onDatePickerref = this.onDatePickerref.bind(this);
    this.addMonths = this.addMonths.bind(this);

    this.pickerRef = React.createRef();
  }
  handleAppointmentTimeChange = (event) => {
    console.log("handleAppointmentTimeChange", event.target.value);
    this.setState({ appointmentTime: event.target.value });
    this.props.onAppointmentTimeChange(event.target.value);
  };
  handleAppointmentDateChange = (date, v) => {
    var oneMonthAhead = moment();
    oneMonthAhead.add(3, "M");
    oneMonthAhead.add(1, "d");
    // oneMonthAhead.setMonth(oneMonthAhead.getMonth() + 3);
    const dataget = momenttime.tz(date, this.props.doctorData?.EHRDepartmentTimeZoneName)
    console.log("handleAppointmentDateChange", this.props.doctorData.EHRProviderId, this.state.reasonForVisits );

    this.setState(
      {
        appointmentDate: date,
        selectedSlots: [],
        appointmentTime: "Appointment Time *",
        AppointmentDay: moment(date).format("dddd"),
        KeyCount: this.state.KeyCount + 1,
      },
      () => {
        if (this.props.doctorID == null) {
          this.setState({
            selectDoctorError: "Select a provider before you select date"
          })
          return
        } else {
          this.setState({
            selectDoctorError: ""
          })
        }
        if (date?._isValid) {
          let appointmentDate = moment(this.state.appointmentDate).format(
            "YYYY-MM-DD"
          );
          let Onmonth = moment(oneMonthAhead).format("YYYY-MM-DD");
          let SelectedDate = moment(new Date(), "YYYY-MM-DD")
            .startOf("day")
            .diff(appointmentDate);
          let Monthahead = moment(
            this.state.appointmentDate,
            "YYYY-MM-DD"
          ).diff(Onmonth);

          console.log(
            "__SelectedDate",
            Monthahead,
            SelectedDate,
            oneMonthAhead
          );

          this.setState({
            ApplyLoading: true,
          });

          let date = moment(this.state.appointmentDate).format("YYYY-MM-DD");

          if (SelectedDate <= 0 && Monthahead < 0) {
            var now = new Date().getTime();
            var CurrentTIme = moment(now).format("hh:mm A");
            let SlectedDate = date;
            let CurrentDAte = moment().format("YYYY-MM-DD");
            console.log("dsf>>>", SlectedDate, CurrentDAte);
            console.log("dsf>>>", moment(now).format("hh:mm A"));
            if (SlectedDate == CurrentDAte) {
              var now = new Date().getTime();
              var CurrentTIme = moment(now).format("hh:mm A");
              let SlectedDate = date;
              let CurrentDAte = moment().format("YYYY-MM-DD");
              console.log("dsf>>>", SlectedDate, CurrentDAte);
              console.log("dsf>>>", moment(now).format("hh:mm A"));

              Axios.post(`${ROOT_URL}/api/AppointmentForm/AppointmentSlotsOfAthenaIntegratedDoctors`
                // Axios.post(`${ROOT_URL}/api/AppointmentForm/GetTimeSlotsofGivenDoctorsByAllReasonsProvided`
                , {
                  doctorID: this.props.doctorID,
                  reasonforvisit: this.props.doctorData.EHRProviderId!==""? null:[this.state.reasonForVisits.reason],
                  ReasonId:this.props.doctorData.reasonforvisit,
                  PracticeId:  this.props.PracticeAccount.practiceID?.PracticeEHRID!=="" ? this.props.doctorData.reasonforvisit!==null?this.props.PracticeAccount.practiceID?.PracticeEHRID : "":"",
                  AppointmentDate: moment(date).format("YYYY-MM-DD"),
                  ZebraAppointmentDate: this.state.appointmentDate,
                  practiceLocationID: this.props.practiceLocationID
                  // AthenaStartDate:this.state.reasonForVisits.reasonid!==null?moment(date).format("MM/DD/YYYY"):null,

                  // AppointmentDate:dataget.format(),
                  // AppointmentDate:this.state.appointmentDate,
                  // AppointmentDate: `${date}T19:00:00.000Z`,
                })
                .then((res) => {
                  console.log(res.data);

                  if (res.data.MS_GetTimeSlots == "Success") {
                    let arraySlots = res.data?.ZebraAppointmentSlots ? res.data.ZebraAppointmentSlots :
                      res.data?.AthenaAppointmentSlots ? res.data?.AthenaAppointmentSlots : []
                    // let arraySlots=res.data.ZebraAppointmentSlots.length===0?
                    // res.data?.AthenaAppointmentSlots ?res.data?.AthenaAppointmentSlots:[]:res.data.ZebraAppointmentSlots
                    if (SlectedDate == CurrentDAte) {


                      let updatedSlots = [];
                      let updatedSlots1 = [];
                      var now = moment().tz(arraySlots.length == 0 ? "" : arraySlots[0].timezone ? arraySlots[0].timezone : this.props.doctorData?.EHRDepartmentTimeZoneName);

                      var FormattedDate = now.toDate();
                      var ItalinaDate = JSON.stringify(new Date(FormattedDate));
                      var TimeOnly = ItalinaDate.substr(12, 5);

                      var predicate = (x) => {
                        console.log("gettingDatax", x.start.substr(12, 5), TimeOnly);
                        return x.start.substr(12, 5) > TimeOnly;
                      };
                      updatedSlots = arraySlots.filter(predicate);
                      updatedSlots1 = arraySlots.filter((item) => {
                        console.log("gettingDatax222", moment(item.start).format("HH:mm"), moment(FormattedDate).format("HH:mm"), now);
                        if (momenttime(item.start).tz(item.timezone ? item.timezone : this.props.doctorData?.EHRDepartmentTimeZoneName).format("HH:mm") > momenttime(FormattedDate).tz(item.timezone ? item.timezone : this.props.doctorData?.EHRDepartmentTimeZoneName).format("HH:mm")) {
                          // if(moment(item.start).format("HH:mm") > moment(FormattedDate).format("HH:mm")){
                          console.log("gettingDatax11", item);
                          return item
                        }
                      });
                      // updatedSlots1.map((item)=>{
                      //   const data=momenttime(item.start)
                      //   data.tz('America/New_York').format('hh:mm A')
                      //   console.log("updatedSlotsData1ite",data);
                      // })


                      console.log("updatedSlotsDatatoday", updatedSlots, updatedSlots1);

                      this.setState({
                        slots: updatedSlots1,
                        ApplyLoading: false,
                      });
                    } else {
                      this.setState({
                        slots: arraySlots,
                        ApplyLoading: false,
                      });
                    }

                    // this.setState({
                    //   slots: res.data.data,
                    //   ApplyLoading: false,
                    // });
                  } else {
                    this.setState({
                      slots: [],
                      ApplyLoading: false,
                    });
                  }
                })
                .catch((e) => {
                  if (e) {
                    this.setState({
                      slots: [],
                      ApplyLoading: false,
                    });
                  }
                });

            } else {
              let startTimeStandard = new Date(this.state.appointmentDate).setHours(0, 0, 0, 0);
              let startTimeStandard1 = new Date(this.state.appointmentDate).setHours(0, 0, 0, 0);
              let minusday = moment(startTimeStandard1).subtract(1, "days")
              console.log("ssssssssaaaaa", `${moment(minusday).format('YYYY-MM-DD')}T19:00:00:000Z`);
              console.log("startTimeStandard", new Date(startTimeStandard), moment(startTimeStandard).format("YYYY-MM-DD"), momenttime.tz(startTimeStandard, "America/New_York").format());
              var now = new Date().getTime();
              var CurrentTIme = moment(now).format("hh:mm A");
              let SlectedDate = date;
              let CurrentDAte = moment().format("YYYY-MM-DD");
              console.log("dsf>>>", SlectedDate, CurrentDAte);
              console.log("dsf>>>", moment(now).format("hh:mm A"));

              Axios.post(`${ROOT_URL}/api/AppointmentForm/AppointmentSlotsOfAthenaIntegratedDoctors`, {
                doctorID: this.props.doctorID,
                reasonforvisit: this.props.doctorData.EHRProviderId !== '' ? null : [this.state.reasonForVisits.reason],
                ReasonId: this.props.doctorData.reasonforvisit,
                // ReasonId: this.props.doctorData.EHRProviderId !== "" ? this.state.reasonForVisits.reasonid : this.props.doctorData.EHRProviderId == "" ? null : null,
                PracticeId:  this.props.PracticeAccount.practiceID?.PracticeEHRID!=="" ? this.props.doctorData.reasonforvisit!==null?this.props.PracticeAccount.practiceID?.PracticeEHRID : "":"",
                // AppointmentDate:momenttime.tz(startTimeStandard,"America/New_York").format(),
                AppointmentDate: moment(date).format("YYYY-MM-DD"),
                ZebraAppointmentDate: new Date(startTimeStandard),
                practiceLocationID: this.props.practiceLocationID
                // AthenaStartDate:this.state.reasonForVisits.reasonid!==null?moment(date).format("MM/DD/YYYY"):null,

                // AppointmentDate:new Date(startTimeStandard),
              })
                .then((res) => {
                  console.log(res.data);

                  if (res.data.MS_GetTimeSlots == "Success") {
                    let arraySlots = res.data?.ZebraAppointmentSlots ? res.data.ZebraAppointmentSlots :
                      res.data?.AthenaAppointmentSlots ? res.data?.AthenaAppointmentSlots : []
                    // console.log("updatedSlotsData", arraySlots,res.data.AthenaAppointmentSlots,res.data.ZebraAppointmentSlots.lenght===0,res.data.ZebraAppointmentSlots);

                    if (SlectedDate == CurrentDAte) {

                      let updatedSlots = [];
                      let updatedSlots1 = [];
                      var now = moment().tz(arraySlots.length == 0 ? "" : arraySlots[0].timezone ? arraySlots[0].timezone : this.props.doctorData?.EHRDepartmentTimeZoneName);
                      // var now = moment();
                      var FormattedDate = now.toDate();
                      var ItalinaDate = JSON.stringify(new Date(FormattedDate));
                      var TimeOnly = ItalinaDate.substr(12, 5);
                      var predicate = (x) => {
                        console.log("gettingDatax", x.start.substr(12, 5), TimeOnly);
                        return x.start.substr(12, 5) > TimeOnly;
                      };
                      updatedSlots = arraySlots.filter(predicate);
                      updatedSlots1 = arraySlots.filter((item) => {
                        console.log("gettingDatax222", moment(item.start).format("HH:mm"), moment(FormattedDate).format("HH:mm"));
                        if (momenttime(item.start).tz(item.timezone ? item.timezone : this.props.doctorData?.EHRDepartmentTimeZoneName).format("HH:mm") > momenttime(FormattedDate).tz(item.timezone ? item.timezone : this.props.doctorData?.EHRDepartmentTimeZoneName).format("HH:mm")) {

                          // if(momenttime(item.start).tz(item.timezone).format("HH:mm") > momenttime(FormattedDate).tz(item.timezone).format("HH:mm")){
                          // if(moment(item.start).format("HH:mm") > moment(FormattedDate).format("HH:mm")){
                          console.log("gettingDatax11", item);
                          return item
                        }
                      });
                      //   if(moment(item.start).format("HH:mm") > moment(FormattedDate).format("HH:mm")){
                      //     console.log("gettingDatax11",item);
                      //     return item
                      //   }
                      // });

                      this.setState({
                        slots: updatedSlots1,
                        ApplyLoading: false,
                      });
                    } else {
                      console.log("updatedSlotsData", arraySlots);
                      arraySlots.map((item) => {
                        const data = moment(item.start).format("hh:mm A")
                        console.log("monentnewyork", data);
                      })
                      this.setState({
                        slots: arraySlots,
                        ApplyLoading: false,
                      });
                    }

                    // this.setState({
                    //   slots: res.data.data,
                    //   ApplyLoading: false,
                    // });
                  } else {
                    this.setState({
                      slots: [],
                      ApplyLoading: false,
                    });
                  }
                })
                .catch((e) => {
                  if (e) {
                    this.setState({
                      slots: [],
                      ApplyLoading: false,
                    });
                  }
                });

            }

          } else {
            this.setState({
              timeSlots: [],
              slots: [],
              ApplyLoading: false,
            });
          }
        } else {
          this.setState({
            timeSlots: [],
            slots: [],
          });
        }
        this.props.onAppointmentDateChange(this.state.appointmentDate);
      }
    );
  };
  // handleAppointmentDateChange = (date, v) => {
  //   var oneMonthAhead = moment();
  //   oneMonthAhead.add(3, "M");
  //   oneMonthAhead.add(1, "d");
  //   // oneMonthAhead.setMonth(oneMonthAhead.getMonth() + 3);

  //   this.setState(
  //     {
  //       appointmentDate: date,
  //       selectedSlots: [],
  //       appointmentTime: "Appointment Time *",
  //       AppointmentDay: moment(date).format("dddd"),
  //       KeyCount: this.state.KeyCount + 1,
  //     },
  //     () => {
  //       if (date?._isValid) {
  //         let appointmentDate = moment(this.state.appointmentDate).format(
  //           "YYYY-MM-DD"
  //         );
  //         let Onmonth = moment(oneMonthAhead).format("YYYY-MM-DD");
  //         let SelectedDate = moment(new Date(), "YYYY-MM-DD")
  //           .startOf("day")
  //           .diff(appointmentDate);
  //         let Monthahead = moment(
  //           this.state.appointmentDate,
  //           "YYYY-MM-DD"
  //         ).diff(Onmonth);

  //         console.log(
  //           "__SelectedDate",
  //           Monthahead,
  //           SelectedDate,
  //           oneMonthAhead
  //         );

  //         this.setState({
  //           ApplyLoading: true,
  //         });

  //         let date = moment(this.state.appointmentDate).format("YYYY-MM-DD");

  //         if (SelectedDate <= 0 && Monthahead < 0) {
  //           if (this.props.doctorPreferenceCheck) {
  //             var now = new Date().getTime();
  //             var CurrentTIme = moment(now).format("hh:mm A");
  //             let SlectedDate = date;
  //             let CurrentDAte = moment().format("YYYY-MM-DD");
  //             console.log("dsf>>>", SlectedDate, CurrentDAte);
  //             console.log("dsf>>>", moment(now).format("hh:mm A"));

  //             Axios.post(`${ROOT_URL}/api/client/DoctorAvialabilty/getByDay`, {
  //               doctorID: this.props.doctorID,
  //               appointmentDay: this.state.AppointmentDay,
  //               date: `${date}T19:00:00.000Z`,
  //             })
  //               .then((res) => {
  //                 console.log(res.data);

  //                 if (res.data.MS_doctorAvailabilityByDayStatus == "success") {
  //                   if (SlectedDate == CurrentDAte) {
  //                     let updatedSlots = [];
  //                     var now = moment();
  //                     var FormattedDate = now.toDate();
  //                     var ItalinaDate = JSON.stringify(new Date(FormattedDate));
  //                     var TimeOnly = ItalinaDate.substr(12, 5);
  //                     var predicate = (x) => {
  //                       return x.substr(12, 5) > TimeOnly;
  //                     };
  //                     updatedSlots = res.data.data.filter(predicate);
  //                     console.log("23>", updatedSlots);

  //                     this.setState({
  //                       slots: updatedSlots,
  //                       ApplyLoading: false,
  //                     });
  //                   } else {
  //                     this.setState({
  //                       slots: res.data.data,
  //                       ApplyLoading: false,
  //                     });
  //                   }

  //                   // this.setState({
  //                   //   slots: res.data.data,
  //                   //   ApplyLoading: false,
  //                   // });
  //                 } else {
  //                   this.setState({
  //                     slots: [],
  //                     ApplyLoading: false,
  //                   });
  //                 }
  //               })
  //               .catch((e) => {
  //                 if (e) {
  //                   this.setState({
  //                     slots: [],
  //                     ApplyLoading: false,
  //                   });
  //                 }
  //               });
  //           } else {
  //             console.log(
  //               ">>>asas",
  //               date,
  //               this.state.AppointmentDay,
  //               this.props.practiceLocationID
  //             );
  //             var now = new Date().getTime();
  //             var CurrentTIme = moment(now).format("hh:mm A");
  //             let SlectedDate = date;
  //             let CurrentDAte = moment().format("YYYY-MM-DD");
  //             console.log("dsf>>>", SlectedDate, CurrentDAte);
  //             console.log("dsf>>>", moment(now).format("hh:mm A"));
  //             let Body = {
  //               practiceLocationID: this.props.practiceLocationID,
  //               day: this.state.AppointmentDay,
  //               date: `${date}T19:00:00.000Z`,
  //               reasonForVisit: this.props.reasonForVisits.split(" ,"),
  //             };
  //             console.log("__body", Body);
  //             Axios.post(
  //               `${ROOT_URL}/api/client/DoctorAvialabilty/FirstAvailableAppointmentAvailabilties`,
  //               Body
  //             )
  //               .then((res) => {
  //                 console.log(
  //                   "dsfsdf34r",
  //                   res.data,
  //                   SlectedDate == CurrentDAte
  //                 );

  //                 if (SlectedDate == CurrentDAte) {
  //                   let updatedSlots = [];
  //                   var now = moment();
  //                   var FormattedDate = now.toDate();
  //                   var ItalinaDate = JSON.stringify(new Date(FormattedDate));
  //                   var TimeOnly = ItalinaDate.substr(12, 5);
  //                   var predicate = (x) => {
  //                     return x.substr(12, 5) > TimeOnly;
  //                   };
  //                   updatedSlots = res.data.data.filter(predicate);
  //                   console.log("23>", updatedSlots);

  //                   this.setState({
  //                     timeSlots: updatedSlots,
  //                     ApplyLoading: false,
  //                   });
  //                 } else {
  //                   this.setState({
  //                     timeSlots: res.data.data,
  //                     ApplyLoading: false,
  //                   });
  //                 }
  //               })
  //               .catch((e) => {
  //                 console.log("errorsMeasage", e);
  //                 this.setState({
  //                   timeSlots: [],
  //                   ApplyLoading: false,
  //                 });
  //               });
  //           }
  //         } else {
  //           this.setState({
  //             timeSlots: [],
  //             slots: [],
  //             ApplyLoading: false,
  //           });
  //         }
  //       } else {
  //         this.setState({
  //           timeSlots: [],
  //           slots: [],
  //         });
  //       }
  //       this.props.onAppointmentDateChange(this.state.appointmentDate);
  //     }
  //   );
  // };
  componentWillMount() {
    console.log("here are props", this.props.selectedSlots);
    let nextDate = this.getNextDayDate();
    this.setState({
      nextDay: nextDate,
    });
  }

  Testing = () => {
    Axios.post(
      `${ROOT_URL}/api/client/DoctorAvialabilty/FirstAvailableAppointmentAvailabilties`,
      {
        practiceLocationID: this.props.practice.locationID,
        day: "Friday",
        date: `2020-12-18T19:00:00.000Z`,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
      }
    )
      .then((res) => {
        this.setState({
          timeSlots: res.data.data,
          ApplyLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          timeSlots: [],
          ApplyLoading: false,
        });
      });
  };
  getNextDayDate = () => {
    var date = new Date();
    console.log(date);
    var nextDay = new Date(date);
    return nextDay;
  };
  addMonths(date, months) {
    var newDate = new Date();
    newDate = date;
    newDate.setMonth(date.getMonth() + months);
    return newDate;
  }

  onDatePickerref(el) {
    console.log("ell:", el);
  }

  componentWillReceiveProps(nextProps) {
    console.log("here are nextProps", nextProps);
    this.handleAppointmentSlots();
    var array = [];
    if (
      nextProps.doctor_available_status ==
      appointments_Status.doctorAvailability.POST
    ) {
      this.setState({
        loaderForTime: true,
      });
    } else {
      if (
        nextProps.doctorID == null ||
        nextProps.appointmentTimeSlots.length == 0
      ) {
        this.setState({
          selectedSlots: [],
          appointmentTime: nextProps.appointmentTime,
          loaderForTime: false,
        });
      } else {
        nextProps.appointmentTimeSlots.map((data) => {
          array.push(data.appointmentTime);
        });
        this.setState({
          selectedSlots: array,
          loaderForTime: false,
        });
      }
    }
  }
  handleAppointmentSlots = () => {
    var id = this.props.doctorID;
  };
  isWeekday = (date) => {
    const day = date.getDay();
    var id = this.props.doctorID;

    if (id == "5d161106302d3e68b24c8810" || id == "5d4ad859f93edb39dd11cd9c")
      return day !== 0 && day !== 6 && day !== 5;
    else if (id == "5d161131302d3e68b24c8811") {
      return day === 4;
    } else {
      return day !== 0 && day !== 5 && day !== 6;
    }
  };
  componentDidUpdate(prevprops) {
    // console.log("this.props.appointmentDate", this.props.appointmentDate);
    if (
      prevprops.reasonForVisits !== this.props.reasonForVisits ||
      prevprops.appointmentDate !== this.props.appointmentDate ||
      prevprops.reasonForVisitsArray != this.props.reasonForVisitsArray||
      prevprops.doctorData!==this.props.doctorData
    ) {
      // console.log("componentDidUpdate", prevprops.reasonForVisits !== this.props.reasonForVisits ||
      //   prevprops.appointmentDate !== this.props.appointmentDate);
      console.log("this.props.appointmentDate", this.props.appointmentDate);
      if(this.props.appointmentDate===null){
        this.setState({
          // timeSlots: [],
          slots: [],
        });
      }
      this.setState({
        // timeSlots: [],
        // slots: [],
        reasonForVisits:Object.keys(this.props.reasonForVisitsArray).length!==0?this.props.reasonForVisitsArray: this.props.reasonForVisits,
        // reasonForVisitsArray: this.props.reasonForVisitsArray,
        appointmentTime: "",
        // asdsad
      });
    }
    

  }
  render() {
    console.log("__DateErrorSLot", this.state.slots);

    const DatepickerInput = ({ ...props }) => (
      <input type="text" {...props} readOnly />
    );
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("___tz", tz);
    return (
      <section className="book-app-new">
        <section className="b-sec1-new">
          <h2 style={{ fontFamily: "Lato", color: "#00234b" }}>
            Select Appointment Date and Time
          </h2>
          <div className="b-div1-new">
            <div className="b-div2-new">
              <div style={{ border: "none" }} className="b-inpContainer-new">
                {/* <DatePicker
                  className="b-inp3-new"
                  minDate={this.state.nextDay}
                  maxDate={this.addMonths(new Date(), 3)}
                  selected={this.props.appointmentDate}
                  onChange={this.handleAppointmentDateChange}
                  withPortal
                  placeholderText="Appointment Date *"
                  customInput={<DatepickerInput />}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  fixedHeight
                /> */}

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    {/* <TouchScxrollable> */}
                    <DatePicker
                      // aria-label="Select Appointment Date"
                      aria-required={true}
                      InputProps={{
                        "aria-label": "Appointment Date",
                      }}
                      // minDate={this.state.nextDay}
                      maxDate={moment(this.addMonths(new Date(), 3))}
                      minDate={moment(this.state.nextDay)}
                      className="b-inp3-new"
                      label="Appointment Date *"
                      openTo="day"
                      id="DateError"
                      views={["day"]}
                      onError={(e) => this.setState({ DateError: e })}
                      value={this.props.appointmentDate}
                      onChange={(date, v, e) =>
                        this.handleAppointmentDateChange(date, v, e)
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                    {/* </TouchScrollable> */}
                  </Stack>
                </LocalizationProvider>
                <Alert
                  key={this.state.KeyCount}
                  style={{ color: "red", marginTop: 4 }}
                >
                  {this.state.DateError == "invalidDate"
                    ? "Enter a valid date"
                    : this.state.DateError == "disablePast"
                      ? "Appointment Date cannot be from past"
                      : ""}
                  {this.state.selectDoctorError}
                </Alert>
              </div>
            </div>

            <div className="b-div1-new">
              <div
                style={{ border: "none" }}
                className="margin b-inpContainer-new"
              >
                <NativeSelect
                  // aria-label="Appointment Time"
                  // aria-required={true}
                  // d="bookapp"
                  style={{ width: "100%" }}
                  // aria-label="Select Time"
                  input={
                    <OutlinedInput
                      style={{ width: "100%" }}
                      aria-label="Select Time"
                      id="outlined-age-simple2"
                    />
                  }
                  inputProps={{
                    "aria-label": "Select Appointment Time",
                  }}
                  variant="outlined"
                  className="b-inp4-new"
                  onChange={this.handleAppointmentTimeChange}
                  // onClick={this.handleAppointmentSlots}
                  // value={}
                  value={this.state.appointmentTime || ""}
                >
                  {!this.state.ApplyLoading ? (
                    <>
                      <option value="Appointment Time *">
                        Appointment Time *
                      </option>
                      {/* <option value="Appointment Time *">
                      No appointment available
                    </option> */}
                    </>
                  ) : (
                    <option value="Appointment Time *">Loading....</option>
                  )}

                  {this.props.doctorID == null ? (
                    this.state.timeSlots.length !== 0 ? (
                      this.state.timeSlots.map((v, i) => {
                        {
                          /* var valueSlot = moment(v, "h:mma");
                        const event1 = new Date(valueSlot);
                        const event2 = new Date();
                        event2.setTime(event1.getTime()); */
                        }

                        return (
                          <option value={v}>
                            {`${moment(v.start).format(
                              "hh:mm A"
                            )}    (${tz})`}
                          </option>
                        );
                      })
                    ) : (
                      <option value="Appointment Time *">
                        No time slot available for selected date and Reason
                      </option>
                    )
                  ) : this.state.slots.length !== 0 ? (
                    !this.state.ApplyLoading ? (
                      this.state.slots.map((timeslot, index) => {
                        return (
                          <React.Fragment>
                            <option value={JSON.stringify({
                              start: timeslot.start,
                              end: timeslot.end,
                              appointmentTypeID: timeslot.AppointmentTypeId,
                              AppointmentId: timeslot.AppointmentId,
                              timezone: timeslot.timezone
                            })}>
                              {this.props.doctorData?.EHRDepartmentTimeZoneName?
                              `${momenttime(timeslot.start).tz(this.props.doctorData?.EHRDepartmentTimeZoneName ? this.props.doctorData?.EHRDepartmentTimeZoneName : timeslot.timezone).format(
                                "hh:mm A"
                              )} - ${momenttime(timeslot.end).tz(this.props.doctorData?.EHRDepartmentTimeZoneName ? this.props.doctorData?.EHRDepartmentTimeZoneName : timeslot.timezone).format(
                                "hh:mm A"
                              )}  (${this.props.doctorData?.EHRDepartmentTimeZoneName ? this.props.doctorData?.EHRDepartmentTimeZoneName : timeslot.timezone}) `
                              :
                              `${momenttime(timeslot.start).tz( timeslot.timezone?timeslot.timezone:tz).format(
                                "hh:mm A"
                              )} - ${momenttime(timeslot.end).tz(timeslot.timezone?timeslot.timezone:tz).format(
                                "hh:mm A"
                              )}  (${timeslot.timezone?timeslot.timezone:tz}) `
                              }
                              {/* {`${momenttime.tz(timeslot.start,this.props.doctorData?.EHRDepartmentTimeZoneName).format(
                                "hh:mm A"
                              )} - ${momenttime.tz(timeslot.end,this.props.doctorData?.EHRDepartmentTimeZoneName).format(
                                "hh:mm A"
                              )}  (${this.props.doctorData?.EHRDepartmentTimeZoneName?this.props.doctorData?.EHRDepartmentTimeZoneName:tz}) `} */}
                            </option>
                            {/* <option value={timeslot}>
                              {" "}
                              {`${moment(JSON.parse(timeslot.start)).format(
                                "hh:mm A"
                              )}   (${tz})`}
                            </option> */}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <option value="Appointment Time *">Loading....</option>
                    )
                  ) : (
                    <option value="Appointment Time *">
                      No time slot available for selected date and Reason
                    </option>
                  )}
                </NativeSelect>
                {/* <p style={{color:"red"}}>No time slot available for selected date. Please try another date or reason for visit</p> */}
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default BookAppointment;
