import insurances_Get_Reducer from "./Appointments";
import post_appointments_Reducer from "./AppointmentsNew";
import appointments_Get_Reducer from "./getAppointments";
import {
  doctorsByPractice_Get_Reducer,
  doctorAppointment_Reducer,
  doctorAvailabilty_Reducer,
  doctorDeactivation_Reducer,
} from "./getDocotrs";
import practiceByName_Get_Reducer from "./getPracticeByName";
import practiceInsurances_Get_Reducer from "./PracticeInsurance";

import appointmentsByPractice_Get_Reducer from "./AppointmentsByPractice";
import allDoctorsByPractice_Get_Reducer from "./DoctorsByPracticeID";
import { combineReducers } from "redux";
import appointmentStatus_Reducer from "./AppointmentStatus";
import doctorStatus_Reducer from "./DoctorStatus";

import {
  callRequest_Create_Reducer,
  callRequest_GET_Reducer,
  covidRequest_GET_Reducer,
} from "./CallRequests";

const appReducer = combineReducers({
 
  insurances_Get_Reducer,
  post_appointments_Reducer,
  appointments_Get_Reducer,
  doctorsByPractice_Get_Reducer,
  practiceByName_Get_Reducer,
  practiceInsurances_Get_Reducer,
  
  appointmentsByPractice_Get_Reducer,
  covidRequest_GET_Reducer,
  //........................
  allDoctorsByPractice_Get_Reducer,
  //........................
 
  appointmentStatus_Reducer,
  doctorStatus_Reducer,
  
  doctorAvailabilty_Reducer,
  
  doctorAppointment_Reducer,
  doctorDeactivation_Reducer,
  callRequest_Create_Reducer,
  callRequest_GET_Reducer,
 
});
const rootReducer = (state, action) => {
  if (action.type === "PRACTICEACCOUNT_LOGOUT") {
    console.log("loging user out");
    state = undefined;
  }
  // if (action.type === "TIKCETSBYPRACTICE_ALL_SUCCESS") {
  //   state = undefined;
  // }

  return appReducer(state, action);
};
export default rootReducer;
