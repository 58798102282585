import React, { Component } from "react";
import "../../css/components/_Footer_new.scss";
import mart2 from "./images/mart2.png";
import moment from 'moment';
;

// import Google_Map_New from "./BookAnAppointment/Google_Map_New"
// const Google_Map_New = loadable(() =>
//   import("./BookAnAppointment/Google_Map_New")
// );
export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practiceName: "Untitled",
      practice: {},
    };
  }

  componentWillReceiveProps(nextProps) {}
  render() {
    console.log("Footer", this.props.practice);
    return (
      <div>
        <footer
          role="contentinfo"
          style={
            {
              // display: this.props.BackGround == "white" ? "none" : "",
            }
          }
          className="footer-new"
        >
          <div className="f-div4-new">
            <div className="f-div5-new">
              <div className="f-div1-new">
                <img
                  style={{ borderRadius: "100%" }}
                  src={
                    this?.props?.practice?.practiceLogo
                      ? this?.props?.practice?.practiceLogo
                      : mart2
                  }
                  alt="Practice Logo"
                  className="f-img1-new"
                />
                <h2 className="f-h1-new">
                  {this.props.practice.practiceName}{" "}
                </h2>
              </div>
              {this.props.practice.footerHtmlContent?
              <div className="f-desc-new" dangerouslySetInnerHTML={{ __html:this.props.practice.footerHtmlContent }}>
              </div>
              :
              <>
              <p className="f-desc-new">{this.props.practice.practiceDetail}</p>
              <br />
              {this?.props?.practice?.practiceDisclaimer == "" ? null : (
                <b className="f-disclaimer-p1-new">Disclaimer</b>
              )}
              <p className="f-dis-p1-new">
                {this.props.practice.practiceDisclaimer}
              </p>
              </> }

            </div>
            <div className="f-div6-new">
            </div>
          </div>

          <div className="f-div3-new">
            <p className="f-p2-new">
              <i className="material-icons f-icon1-new f-icon-new">room</i>{" "}
              {this.props.practice.locationAddress
                ? this.props.practice.locationAddress
                : this.props.PracticeAccount?.locationAddressObj
                    ?.formatted_address}{" "}
            </p>
            <p
              role="presentation"
              aria-label="Phone Number"
              className="f-p2-new f-p2-new2"
            >
              <i className="material-icons f-icon2-new f-icon-new">call</i>

              {this.props?.practiceorPracticeaccount == undefined
                ? this.props?.practice?.phoneNumber
                : this.props?.PracticeAccount?.locationPhone}
              {/* {this.props?.practiceorPracticeaccount == undefined &&
              this.props?.PracticeAccount?.locationPhone
                ? this.props?.practice?.locationPhone
                : this.props?.PracticeAccount?.locationPhone == ""
                ? this.props?.practice?.twilioContacts.length == 0
                  ? null
                  : this.props?.practice?.twilioContacts[0]
                : this.props?.PracticeAccount?.locationPhone} */}
              {/* {this.props.practice.locationPhone
                ? this.props.practice.locationPhone
                  ? this.props.practice.locationPhone
                  : this.props.practice.phoneNumber
                : this.props.practice.twilioContacts[0]} */}
            </p>
          </div>
          <div className="f-last-div">
            <p className="f-p3-new">
              Powered by Zebra Doctor © {moment().format("YYYY")} All rights reserved
            </p>
            <p className="f-p4-new">
              Powered by: <span className="f-sp1-new">Zebra Doctor</span>{" "}
              <span className="f-sp2-new"> Medical Stack</span>
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
