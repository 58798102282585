import React, { Component } from "react";
import "./GenderSection.scss";
import {
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
} from "@mui/material";
import { Info, Add } from "@mui/icons-material";
export default class GenderSection extends Component {
  constructor() {
    super();
    this.state = {
      MoreGenderInfo: false,
      Gender: "",
      GenderInformation: "",
    };
  }
  handelAddMoreGenderField = () => {
    this.setState({
      MoreGenderInfo: !this.state.MoreGenderInfo,
    });
  };
  handelGetGender = (v) => {
    console.log("====================================");
    console.log(v);
    console.log("====================================");
    this.setState(
      {
        Gender: v,
      },
      () => {
        this.props.handelGetGender(this.state.Gender);
      }
    );
  };
  handelGetGenderInformation = (v) => {
    this.setState({ GenderInformation: v.target.value }, () => {
      this.props.handelGetGenderInformation(this.state.GenderInformation);
    });
  };
  // componentDidMount() {
  //   console.log("__Testeting", this.props);

  //   this.setState(
  //     {
  //       Gender: this.props.GenderValue,
  //       GenderInformation: this.props.GenderInformation,
  //     },
  //     () => {
  //       if (this.props.GenderInformation != "") {
  //         this.setState({
  //           MoreGenderInfo: true,
  //         });
  //       }
  //     }
  //   );
  // }
  // componentDidUpdate(prevProps) {
  //   if (prevProps !== this.props) {
  //     console.log("__Testeting", this.props, prevProps);
  //     this.setState({
  //       Gender: this.props.GenderValue,
  //       GenderInformation: this.props.GenderInformation,
  //     });
  //   }
  // }
  render() {
    console.log("====================================");
    console.log("__Gender", this.props);
    console.log("====================================");
    return (
      <div className="Gender_wrapper">
        <div className="Gender_Main_Heading_Container">
          <h2 className="Gender_Main_Heading">Sex Assigned at Birth<span style={{color:"red"}}>*</span></h2>
          <Tooltip title="We require this information because we use this information in communicating with your insurance provider. Please make sure that the sex you select here is same as on your insurance file. If you would like to tell us more about your gender identity, please click on ‘Add Gender Information’ link button. Our entire team is committed to making sure every patient feels safe, welcome and respected">
            <Info className="Gender_Main_Info_Icon" />
          </Tooltip>
        </div>
        <div className="Gender_Checkboxes">
          <RadioGroup
            aria-required={true}
            row
            className="Gender_Checkboxes_Container"
            aria-label=""
            name="row-radio-buttons-group-3"
          >
            <FormControlLabel
              value="Male"
              className="Gender_Checkboxes_item"
              onChange={() => {
                this.handelGetGender("Male");
              }}
              checked={this.state.Gender == "Male" ||this.props.GenderValue=="Male"? true : false}
              control={<Radio />}
              label="Male"
            />
            <FormControlLabel
              value="Female"
              className="Gender_Checkboxes_item"
              onChange={() => {
                this.handelGetGender("Female");
              }}
              checked={this.state.Gender == "Female" ||this.props.GenderValue=="Female"? true : false}
              control={<Radio />}
              label="Female"
            />
            {/* <FormControlLabel
              value="Non-Binary"
              className="Gender_Checkboxes_item"
              onChange={() => {
                this.handelGetGender("Non-Binary");
              }}
              checked={this.props.Gender == "Non-Binary" ? true : false}
              control={<Radio />}
              label="Non-Binary"
            /> */}
          </RadioGroup>
          <Button
            onClick={this.handelAddMoreGenderField}
            startIcon={<Add />}
            className="Gender_Checkboxes_Button"
          >
            Add Gender Information
          </Button>
          {this.state.MoreGenderInfo ? (
            <div className="Gender_Info">
              <TextField
                onChange={this.handelGetGenderInformation}
                value={this.state.GenderInformation}
                className="Gender_Info_Field"
                fullWidth
                placeholder="Gender Information (Optional)"
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
