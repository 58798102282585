export const practice_Actions = {
  createPractice: {
    POST: "PRACTICE_ADD_POST",
    SUCCESS: "PRACTICE_ADD_SUCCESS",
    FAILURE: "PRACTICE_ADD_FAILURE",
    CHOOSEPASSWORD: "PRACTICE_ADD_CHOOSEPASSWORD",
    DUPLICATEEMAIL: "DUPLICATEEMAIL",
    DUPLICATENAME: "DUPLICATENAME",
  },
  getPracticeByName: {
    GET: "PRACTICEBYNAME_ALL_GET",
    FAILURE: "PRACTICEBYNAME_ALL_FAILURE",
    SUCCESS: "PRACTICEBYNAME_ALL_SUCCESS",
  },

  updatePracticeById: {
    NEW: "UPDATE_PRACTICE_BYID_NEW",
    POST: "UPDATE_PRACTICE_BYID_POST",
    FAILURE: "UPDATE_PRACTICE_BYID_FAILURE",
    SUCCESS: "UPDATE_PRACTICE_BYID_SUCCESS",
  },
  doctorAvailability: {
    NEW: "DOCTOR_AVAILABILTY_NEW",
    POST: "DOCTOR_AVAILABILTY_POST",
    FAILURE: "DOCTOR_AVAILABILTY_FAILURE",
    SUCCESS: "DOCTOR_AVAILABILTY_SUCCESS",
  },
};

export const practice_Status = {
  createPractice: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING",
    CHOOSEPASSWORD: "CHOOSEPASSWORD",
    DUPLICATEEMAIL: "DUPLICATEEMAIL",
    DUPLICATENAME: "DUPLICATENAME",
  },
  getPracticeByName: {
    NEW: "getPracticeByName_NEW",
    SUCCESS: "getPracticeByName_SUCCESS",
    FAILURE: "getPracticeByName_FAILURE",
    LOADING: "getPracticeByName_LOADING",
  },

  updatePracticeById: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",

    FAILURE: "FAILURE",
    LOADING: "LOADING",
  },
};

export const create_practice_request_Actions = {
  createPracticeRequest: {
    POST: "PRACTICE_REQUEST_ADD_POST",
    SUCCESS: "PRACTICE_REQUEST_ADD_SUCCESS",
    FAILURE: "PRACTICE_REQUEST_ADD_FAILURE",
    DUPLICATEPHONE: "DUPLICATEPHONE",
  },
};

export const create_practice_request_Status = {
  createPracticeRequest: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING",
    DUPLICATEPHONE: "DUPLICATEPHONE",
  },
};
