import React, { Component, lazy, Suspense } from "react";
import { withCookies } from "react-cookie";
import { Route, Switch, withRouter } from "react-router-dom";
// import GoogleAnalyticsManager from "./components/googleAnalyticsManager/GoogleAnalyticsManager";
import NewForm from "./components/NewAppointmentForm/BookAnAppointment";
import Loader from "./components/PracticeSetup/common/Loader";
import ReSchedule_Form from "./components/ReSchedule_Form/ReSchedule_Form";
import "./style.scss";
/*const NewForm = loadable(() =>
  import("./components/NewAppointmentForm/BookAnAppointment")
);*/
const MedSpaForm = lazy(() => import("./components/MedSpaForm")); // Keep it loadable till we actually get a medspa provider onboard

class App extends Component {
  componentWillMount() { }

  render() {
    //console.log(this.props);
    return (
      <div>
        {/* <GoogleAnalyticsManager GATrackingCode="UA-139627195-1" /> */}
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <Loader color="#3F3D56" />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/medical/BookAnAppointment/Test/:Testlocation"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <NewForm Testlocation={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/medical/BookAnAppointment/:practiceName"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <NewForm Testlocation={true} />
              </Suspense>
            )}
          />

          <Route
            exact
            path="/medical/BookAnAppointment/practice/site/:LocationID"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <NewForm Location={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/embedded/BookAnAppointment/practice/site/:LocationID"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <NewForm Location={true} Embedded={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/medical:id?"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <NewForm shortUrl={true} Location={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/embedded/medical:id?"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <NewForm shortUrl={true} Location={true} Embedded={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/medical/:id?"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <NewForm shortUrl={true} withoutQuery={true} Location={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/embedded/medical/:id?"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <NewForm shortUrl={true} Location={true} withoutQuery={true} Embedded={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/medspa/practice/site/:LocationID"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <MedSpaForm Location={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/medspa/BookAnAppointment/:practiceName"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <MedSpaForm Location={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/embedded/medspa/practice/site/:LocationID"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <MedSpaForm Location={true} Embedded={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/medspa:id?"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <MedSpaForm shortUrl={true} Location={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/embedded/medspa:id?"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <MedSpaForm shortUrl={true} Location={true} withoutQuery={true}Embedded={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/medspa/:id?"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <MedSpaForm shortUrl={true} withoutQuery={true} Location={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/embedded/medspa/:id?"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <MedSpaForm shortUrl={true} withoutQuery={true} Location={true} Embedded={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/reSchedule:id?"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <ReSchedule_Form />
              </Suspense>
            )}
          />
          {/* 
          <Route
            exact
            path="/medical/BookAnAppointment/:practiceName/:Fname/:LName/:DOB/:Email"
            render={() => (
              <Suspense
                fallback={
                  <div style={{ height: "100vh" }}>
                    <Loader color="#3F3D56" />
                  </div>
                }
              >
                <NewForm />
              </Suspense>
            )}
          /> */}
        </Switch>
      </div>
    );
  }
}

export default withRouter(withCookies(App));
