import {appointments_Actions} from '../constants/Appointments'
import {appointments_Status} from '../constants/Appointments'
 const appointments_initialStoreState = {
    insurances_get_status: appointments_Status.getAllInsurances.NEW,
    insurances:[]
  };

   const insurances_Get_Reducer= function(state = appointments_initialStoreState, action) {
   console.log(action.type);
    switch(action.type) {
      case appointments_Actions.getAllInsurances.GET:
        console.log("I am from Reduce Loading..");
        return { ...state, insurances_get_status: appointments_Status.getAllInsurances.LOADING};
      case appointments_Actions.getAllInsurances.SUCCESS:
         console.log("I am from Reducer get..",action.payload);
        return {...state, insurances_get_status: appointments_Status.getAllInsurances.SUCCESS, insurances: action.payload.insuranceList}
      case appointments_Actions.getAllInsurances.FAILURE:
         console.log("I am from Reduce Failure..");
        return {...state, insurances_get_status: appointments_Status.getAllInsurances.FAILURE, error: action.payload.error}
        
      default:
      console.log("default is firing")

        return {...state};

    }
  };
  export default insurances_Get_Reducer;
