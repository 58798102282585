//import { MenuItem, Select } from "@material-ui/core";
import { MenuItem, Select } from "@mui/material";
import Axios from "axios";
import React, { Component } from "react";
import { ROOT_URL } from "../../../../constants/config";
import "./style.scss";
export default class index extends Component {
  constructor() {
    super();
    this.state = {
      AllPracticeLocation: [],
      LocationId: "",
    };
  }

  componentDidMount() {
    this.getAllPracticeLocations();
  }
  getAllPracticeLocations = () => {
    // let practiceID = this.props.cookies.get("practiceID");
    // let Token = this.props.cookies.get("token");
    // let objetct = {
    //   token: Token,
    //   practiceID: practiceID,
    //   practiceAccountID: this.props.cookies.get("practiceAccount")._id,
    // };
    let Body = {
      practiceID: this.props.practice._id,
    };
    Axios.post(
      `${ROOT_URL}/api/admin/practices/practiceLocationData`,
      Body
      // {
      //   headers: {
      //     Authorization: JSON.stringify(objetct),
      //   },
      // }
    ).then((res) => {
      console.log("====================================");
      console.log(res.data);
      console.log("====================================");
      this.setState({
        AllPracticeLocation: res.data.practiceLocations,
      });
    });
  };
  hadelLocationId = (e) => {
    this.setState(
      {
        LocationId: e.target.value._id,
      },
      () => {
        this.props.handelpracticeLocationID(
          this.state.LocationId,
          e.target.value.locationAddress
        );
      }
    );
  };
  render() {
    return (
      <div className="Location">
        <span>Choose which location do you want to book appointment at ? </span>
        <div className="field">
          <p>Practice Location</p>
          {/* <FormControl style={{ width: "100%" }}> */}
          <Select
            style={{ width: "100%" }}
            variant="outlined"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={this.props.locationAddress}
            onChange={(e) => this.hadelLocationId(e)}
          >
            {this.state.AllPracticeLocation.map((res, lk) => {
              return (
                <MenuItem key={lk} value={res}>
                  {res?.locationAddress}
                </MenuItem>
              );
            })}
          </Select>
          {/* </FormControl> */}
        </div>
      </div>
    );
  }
}
