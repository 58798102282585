import {appointments_Actions} from '../constants/Appointments'
import {appointments_Status} from '../constants/Appointments'
 const getAppointments_StoreinitialState = {
    appointments_get_status: appointments_Status.getAllAppointments.NEW,
    appointments:[]
  };

   const appointments_Get_Reducer= function(state = getAppointments_StoreinitialState, action) {
   console.log(action.type);
    switch(action.type) {
      case appointments_Actions.getAllAppointments.GET:
        console.log("I am from Reduce Loading..");
        return { ...state, appointments_get_status: appointments_Status.getAllAppointments.LOADING};
      case appointments_Actions.getAllAppointments.SUCCESS:
         console.log("I am from Reducer get..",action.payload);
        return {...state, appointments_get_status: appointments_Status.getAllAppointments.SUCCESS, appointments: action.payload.appointmentList}
      case appointments_Actions.getAllAppointments.FAILURE:
         console.log("I am from Reduce Failure..");
        return {...state, appointments_get_status: appointments_Status.getAllAppointments.FAILURE, error: action.payload.error}
        
      default:
      console.log("default is firing")

        return {...state};

    }
  };
  export default appointments_Get_Reducer;
