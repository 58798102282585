import React, { Component } from "react";
import {
  callRequest_Status,
  callRequest_Actions,
} from "../../../constants/Appointments";
import { connect } from "react-redux";
import Success from "../BookAnAppointment/Success.jsx";
import errorImage from "../images/Error.png";
import successImage from "../images/Succes1.png";
import { AppointmetnsServer } from "../../../servers/AppointmentsServer";
import Loader from "../../PracticeSetup/common/Loader";
import RequestCall_PersonalInformation from "./RequestCall_PersonalInformation.jsx";
import "../../../css/components/_Appointment_new.scss";
import { Button, CircularProgress } from "@mui/material";
const mapStateToProps = (state) => {
  return {
    callRequest_create_status:
      state.callRequest_Create_Reducer.callRequest_create_status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createCallRequest: (data) => {
      dispatch(AppointmetnsServer.createCallRequest(data));
    },
    resetCallRequest: () => {
      dispatch({ type: callRequest_Actions.createCallRequest.NEW });
    },
  };
};

export class RequestCall_Main extends Component {
  constructor(props) {
    super(props);
    console.log("props>>", props);
    this.state = {
      firstName: "",
      lastName: "",
      patientContact: "",
      patientContactLenght: "",
      validNumFlag: false,
      patientEmail: "",
      validEmailFlag: true,
    };
    this.handlePatientContactChange = this.handlePatientContactChange.bind(
      this
    );
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleAppointmentSubmission = this.handleAppointmentSubmission.bind(
      this
    );

    this.handlePatientEmailChange = this.handlePatientEmailChange.bind(this);
  }
  handlePatientContactChange(contact, isNumberValid, patientContactLenght) {
    this.setState({
      patientContact: contact,
      validNumFlag: isNumberValid,
      patientContactLenght: patientContactLenght,
    });
  }
  handleFirstNameChange(fName) {
    this.setState({ firstName: fName });
  }
  handleLastNameChange(lName) {
    this.setState({ lastName: lName });
  }
  handlePatientEmailChange(email, isEmailValid) {
    this.setState({ patientEmail: email, validEmailFlag: isEmailValid });
  }
  componentWillMount() {
    this.props.resetCallRequest();
  }
  handleAppointmentSubmission(e) {
    e.preventDefault();
    //  alert(this.props.practice._id)
    var callRequest = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.patientContact,
      practiceID: this.props.practice._id,
      patientEmail: this.state.patientEmail,
      practiceLocationID:this.props.PracticeAccount?._id
    };
    this.props.createCallRequest(callRequest);
    this.setState({
      firstName: "",
      lastName: "",
      patientContact: "",
      patientEmail: "",
      validNumFlag: false,
      validEmailFlag: true,
    });
    // var selectedOption = appointment.reasonForVisit;
    // if (
    //   this.props.practice.gtmCode != null ||
    //   this.props.practice.gtmCode != undefined
    // ) {
    //   window.dataLayer.push({
    //     event: "selectionMade",
    //     selectedElement: selectedOption
    //   });
    // }

    // this.clearState();
    // this.props.submitAppointment(appointment);
    // }
  }
  resetCallRequest = () => {
    this.props.resetCallRequest();
    this.props.switchScreenHandler(1);
  };
  componentWillReceiveProps(nextProps) {}
  // clearState() {
  //   this.setState({
  //     firstName: "",
  //     lastName: "",
  //     patientContact: ""
  //   });
  // }
  // componentWillReceiveProps(nextProps) {
  //   if(nextProps.appointmentTimeSlots.length > 0)
  //   this.setState({
  //     appointmentTimeSlots: nextProps.appointmentTimeSlots
  //   });

  //   if (
  //     nextProps.insurances.length === 0 &&
  //     nextProps.practice._id != undefined
  //   ) {
  //     console.log("get all insurances from database");
  //     nextProps.getAllInsurances(nextProps.practice._id);
  //   }

  //   console.log("New props are sent to this component.");
  //   var flag = true;

  //   if (
  //     nextProps.practice_get_status ===
  //     practice_Status.getPracticeByName.SUCCESS
  //   ) {
  //     console.log("Practice with this parameter is found.");
  //     this.setState({ screenStatus: "NEW" });
  //   } else if (
  //     nextProps.practice_get_status ===
  //     practice_Status.getPracticeByName.FAILURE
  //   ) {
  //     console.log("Practice with this parameter not found.");
  //     flag = false;
  //     this.setState({ screenStatus: "PRACTICE_NOT_FOUND" });
  //   }

  //   if (
  //     nextProps.post_appointment_status ===
  //     appointments_Status.submitAppointment.SUCCESS
  //   ) {
  //     console.log(
  //       "Appointment Submitted successfully, 200 response from database."
  //     );
  //     this.setState({ screenStatus: "APPOINTMENT_SCUCCESSFUL" });
  //   } else if (
  //     nextProps.post_appointment_status ===
  //     appointments_Status.submitAppointment.FAILURE
  //   ) {
  //     console.log(
  //       "Appointment Submission failure, 500 response from database."
  //     );
  //     this.setState({ screenStatus: "APPOINTMENT_FAILURE" });
  //   } else if (
  //     nextProps.post_appointment_status ===
  //     appointments_Status.submitAppointment.LOADING
  //   ) {
  //     console.log(
  //       "Appointment Submission failure, 500 response from database."
  //     );
  //     this.setState({ screenStatus: "APPOINTMENT_LOADING" });
  //   } else if (flag) {
  //     console.log("Patient can schedule new appointment now.");
  //     this.setState({ screenStatus: "NEW" });
  //   }

  //   if (nextProps.insurances.length != 0) {
  //     //alert("true props")
  //     this.setState({
  //       HealthInsuranceID: nextProps.insurances[0].insuranceID._id
  //     });
  //   }

  //   if(this.props.doctors.length >0){
  //     this.state.doctors=this.props.doctors.filter(doctor => {
  //       if (doctor.doctorStatus) {
  //          return doctor;
  //        }
  //    })
  //   }

  // }
  // componentWillMount() {

  //   if (this.props.match.params.practiceName != "") {
  //     this.props.getPracticeByName(this.props.match.params.practiceName);
  //   }

  // }

  // componentDidMount() {}
  // checkAvailabilty = (time, date) => {
  //   console.log("here is check availabilty", time, date);
  // };

  getScreen(status) {
    switch (status) {
      case callRequest_Status.createCallRequest.NEW:
        return (
          <form action="" className="app-form-new">
            <h1
              style={{ fontSize: 32, color: "#00234b", wordBreak: "break-all" }}
              className="app-h1-new"
            >
              {" "}
              {this.props.type || this.props.type == "false"
                ? "Covid19 Rapid Test"
                : "Request a Call Back"}{" "}
            </h1>
            <p className="app-p1-new">
              {" "}
              {this.props.type || this.props.type == "false"
                ? "Get your test results in as little as 15 minutes."
                : `By submitting this form, you are agreeing to receive text messages
              from ${this.props.practice.practiceName}`}{" "}
            </p>
            <RequestCall_PersonalInformation
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              patientContact={this.state.patientContact}
              patientContactLenght={this.state.patientContactLenght}
              onFirstNameChange={this.handleFirstNameChange}
              onLastNameChange={this.handleLastNameChange}
              onPatientContactChange={this.handlePatientContactChange}
              patientEmail={this.state.patientEmail}
              onPatientEmailChange={this.handlePatientEmailChange}
            />
            {this.props.type || this.props.type == "false" ? (
              <>
                <h1 className="app-p2-new">Quick Details:</h1>

                <p className="app-p2-new">
                  - Get your test results in as little as 15 minutes.
                </p>
                <p className="app-p2-new">
                  - Self-Pay $98 for the Test-Only (Does not include doctor
                  consultation if required*)
                </p>
                <p className="app-p2-new">
                  - Consultation is covered by most insurance providers.
                </p>
              </>
            ) : (
              ""
            )}

            {/* <p style={{ marginTop: 13 }} className="app-p2-new">
              By submitting this form, you are agreeing to receive text messages
              from {this.props.practice.practiceName}
            </p> */}
            <Button
              className="app-btn-new"
              style={{ cursor: "pointer" }}
              disabled={
                this.state.firstName == "" ||
                this.state.lastName == "" ||
                this.state.patientEmail == "" ||
                this.state.validEmailFlag == false ||
                this.state.validNumFlag == false
                  ? true
                  : false
              }
              onClick={this.handleAppointmentSubmission}
            >
              {this.props.type || this.props.type == "false"
                ? "Request Appointment"
                : " Request a Call back"}
            </Button>
            <p
              className="app-p3-new"
              style={{
                display:
                  this.state.firstName == "" ||
                  this.state.lastName == "" ||
                  this.state.patientEmail == "" ||
                  this.state.validEmailFlag == false ||
                  this.state.validNumFlag == false
                    ? "block"
                    : "none",
              }}
            >
              <i className="material-icons app-icon1">error_outline</i>
              Please fill out all the details to request a call back. All fields
              are required
            </p>
          </form>
        );
      case callRequest_Status.createCallRequest.LOADING:
        return (
          <div
            style={{
              height: "50vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#143062" }} />
          </div>
        );
      case "PRACTICE_NOT_FOUND":
        return <div>Page not found!</div>;
      case callRequest_Status.createCallRequest.SUCCESS:
        return (
          <Success
            color="black"
            img={successImage}
            status={
              this.props.type || this.props.type == "false"
                ? "You shall be updated about this appointment."
                : "Our representative shall call you in a while."
            }
            status1={
              this.props.type || this.props.type == "false"
                ? "Appointment Received"
                : "Request Submitted"
            }
            btnText="Request an Appointment"
            onClick={this.resetCallRequest}
            requestCallId={false}
          />
        );
      case callRequest_Status.createCallRequest.FAILURE:
        return (
          <Success
            color="#E92829"
            img={errorImage}
            status="Ooh, Sorry!"
            status1="Something went wrong! Please try again."
            btnText="Try Again"
            onClick={this.props.resetCallRequest}
          />
        );

      default:
      //alert("here")
    }
  }
  render() {
    console.log(" thispropspractice",this.props.PracticeAccount);
    return (
      <div role="main">
        <div className="appointment-new">
          {this.getScreen(this.props.callRequest_create_status)}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestCall_Main);

//export default connect(mapStateToProps, mapDispatchToProps)(RequestCall_Main);
// mapStateToProps,
// mapDispatchToProps
