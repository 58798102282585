import { practice_Actions } from "../constants/Practices";
import { practice_Status } from "../constants/Practices";
const getPracticeByName_initialState = {
  practice_get_status: practice_Status.getPracticeByName.NEW,
  practice: {},
};

const practiceByName_Get_Reducer = function (
  state = getPracticeByName_initialState,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case practice_Actions.getPracticeByName.GET:
      console.log("I am from Reduce Loading..");
      return {
        ...state,
        practice_get_status: practice_Status.getPracticeByName.LOADING,
      };
    case practice_Actions.getPracticeByName.SUCCESS:
      console.log("I am from Reducer get..", action.payload);
      return {
        ...state,
        practice_get_status: practice_Status.getPracticeByName.SUCCESS,
        practice: action.payload.practice
          ? action.payload.practice
          : action.payload,
      };
    case practice_Actions.getPracticeByName.FAILURE:
      console.log("I am from Reduce Failure..");
      return {
        ...state,
        practice_get_status: practice_Status.getPracticeByName.FAILURE,
        error: action.payload.error,
      };

    default:
      console.log("default is firing");

      return { ...state };
  }
};
export default practiceByName_Get_Reducer;
