export const practiceAccount_Actions = {
  updatePassword: {
    POST: "PRACTICEACCOUNT_UPDATEPASSWORD_POST",
    SUCCESS: "PRACTICEACCOUNT_UPDATEPASSWORD_SUCCESS",
    FAILURE: "PRACTICEACCOUNT_UPDATEPASSWORD_FAILURE"
  },
  PracticeDetails: {
    UPDATE: "PRACTICEACCOUNT_PRACTICEDETAILS_UPDATE",
    SUCCESS: "PRACTICEACCOUNT_PRACTICEDETAILS_UPDATE_SUCCESS",
    FAILURE: "PRACTICEACCOUNT_PRACTICEDETAILS_UPDATE_FAILURE",
  },
  PracticeSignIn: {
    POST: "PRACTICEACCOUNT_SIGNIN_POST",
    SUCCESS: "PRACTICEACCOUNT_SIGNIN_SUCCESS",
    NOT_AUTHORIZED: "PRACTICEACCOUNT_SIGNIN_NOT_AUTHROIZED",
    FAILURE: "PRACTICEACCOUNT_UPDATEPASSWORD_FAILURE"
  },
  PracticeSignOut: {
    LOGOUT: "PRACTICEACCOUNT_LOGOUT",
    POST: "PRACTICEACCOUNT_LOGOUT_POST",
    FAILURE: "PRACTICEACCOUNT_LOGOUT_FAILURE"
  },
  PracticeWebToken:{
	POST: "PRACTICEACCOUNT_WEBTOKEN_POST",
    SUCCESS: "PRACTICEACCOUNT_WEBTOKEN_SUCCESS",
    FAILURE: "PRACTICEACCOUNT_WEBTOKEN_FAILURE"
  },
  PracticeNotification: {
    NEW:"PRACTICEACCOUNT_NOTIFICATION_NEW",
    POST: "PRACTICEACCOUNT_NOTIFICATION_POST",
    SUCCESS: "PRACTICEACCOUNT_NOTIFICATION_SUCCESS",
    FAILURE: "PRACTICEACCOUNT_NOTIFICATION_FAILURE"
  },
  LocationNumAuth: {
    NEW:"LocationNumAuth_NEW",
    POST: "LocationNumAuth_POST",
    SUCCESS: "LocationNumAuth_SUCCESS",
    FAILURE: "LocationNumAuth_FAILURE"
  }
};

export const practiceAccount_Status = {
  updatePassword: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING"
  },
  PracticeDetailsUpdate: {
    NEW: "PracticeDetailsUpdate_NEW",
    SUCCESS: "PracticeDetailsUpdate_SUCCESS",
    FAILURE: "PracticeDetailsUpdate_FAILURE",
    LOADING: "PracticeDetailsUpdate_LOADING",

  },
  PracticeSignIn: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    NOT_AUTHORIZED: "NOT_AUTHORIZED",
    LOADING: "LOADING"
  },
  PracticeSignOut: {
    SUCCESS: "SUCCESS",
    POST:"POST",
    FAILURE:"FAILURE",
    LOADING: "LOADING"
  },
  PracticeWebToken:{
	NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING"
  },
  PracticeNotification: {
    NEW: "NEW",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOADING: "LOADING"
  },
  LocationNumAuth: {
    NEW:"LocationNumAuth_NEW",
    POST: "LocationNumAuth_POST",
    SUCCESS: "LocationNumAuth_SUCCESS",
    FAILURE: "LocationNumAuth_FAILURE"
  }
};
