import React, { Component } from "react";
//import { Rating } from "@material-ui/lab";
import { Rating } from "@mui/lab";
//import { withStyles } from "@material-ui/core/styles";
import { withStyles } from "@mui/styles";
import "../../../css/components/_DoctorDetials.scss";
//import { Modal, Fade } from "@material-ui/core";
import {
  Modal,
  Fade,
  IconButton,
  Button,
  Box,
  Typography,
} from "@mui/material";

const StyledRating = withStyles({
  iconFilled: {
    color: "#00EFAE",
  },
})(Rating);

var styles = {
  body: {
    "-webkit-overflow-scrolling": "touch",
  },
};

class DoctorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorReviews: [],
      moreReviews: false,
    };
  }
  componentWillMount() {
    for (var i in styles.body) {
      document.body.style[i] = styles.body[i];
    }
    if (this.props.selectedDoctor.doctorReviews) {
      this.state.doctorReviews = this.props.selectedDoctor.doctorReviews.filter(
        (review) => review.Review !== ""
      );
      if (this.state.doctorReviews.length > 2) {
        this.state.doctorReviews = this.state.doctorReviews.slice(0, 2);
        this.state.moreReviews = true;
      }
    }
  }
  componentWillUnmount() {
    for (var i in styles.body) {
      document.body.style[i] = null;
    }
  }
  render() {
    console.log("this.props.selectedDoctor", this.props.selectedDoctor);
    return (
      <Modal
        open={true}
        showCloseIcon={false}
        center
        className="tda_modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={true}>
          <div className="dd-container">
            <div className="dd-div1">
              <div className="dd-head">
                <p className="dd-head-text">Provider Details</p>
                <IconButton
                  // className="dd-btn1"
                  onClick={this.props.doctorDetailPopupHandler}
                >
                  <i className="material-icons dd-icon1">close</i>
                </IconButton>
              </div>
              <div className="dd-div2">
                <div className="dd-img-container">
                  <img
                    src={
                      this.props.selectedDoctor.doctorImage === ""
                        ? "https://zebradoctor-patient-public-bucket-development.s3.us-west-2.amazonaws.com/logoo.png"
                        : this.props.selectedDoctor.doctorImage
                    }
                    alt="not found"
                    className="dd-img1"
                  />
                </div>
                <Typography
                  variant="body1"
                  sx={{
                    color: "black !important",
                    fontSize: "18px",
                    fontFamily: "unset !important",
                    fontWeight: 500,
                  }}
                >
                  {this.props.selectedDoctor.doctorName}
                </Typography>
                <p className="dd-p2">
                  {this.props.selectedDoctor.doctorSpecialization}
                </p>
              </div>

              {this.props.selectedDoctor.doctorDescription !== "" && (
                <Box sx={{ ml: { md: 5 }, mt: 5 }}>
                  <dl>
                    <dt>
                      <Typography
                        variant="body"
                        sx={{
                          color: "black !important",
                          fontFamily: "unset !important",
                          fontWeight: 500,
                        }}
                      >
                        Description:
                      </Typography>
                    </dt>
                    <dd>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "black !important",
                          fontFamily: "unset !important",
                          ml: 8,
                        }}
                      >
                        {this.props.selectedDoctor.doctorDescription}
                      </Typography>
                    </dd>
                  </dl>
                </Box>
              )}
              {this.state.doctorReviews.length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Reviews
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "14px", color: "text.secondary" }}
                  >
                    Doctor reviews are sourced from other portals as well.
                  </Typography>
                </>
              )}
              <div className="dd-div3">
                {this.state.doctorReviews.map((res, index) => {
                  return (
                    <div className="dd-div4" key={index}>
                      <p className="dd-p3">"{res.Review}"</p>
                      <div style={{ marginTop: "10px", marginLeft: "15px" }}>
                        <StyledRating value={5} readOnly />
                      </div>
                    </div>
                  );
                })}
                {this.state.moreReviews ? (
                  <Button
                    onClick={() => {
                      var allReviews =
                        this.props.selectedDoctor.doctorReviews.filter(
                          (review) => review.Review !== ""
                        );
                      this.setState({
                        doctorReviews: allReviews,
                        moreReviews: false,
                      });
                    }}
                    className="dd-btn2"
                  >
                    More Reviews
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <Button
                onClick={() => {
                  this.props.selectDoctor(this.props.selectedDoctorIndex);
                  this.props.doctorDetailPopupHandler();
                }}
                className="dd-btn3"
              >
                SELECT DOCTOR
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default DoctorDetails;
